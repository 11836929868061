<template>
  <div class="enterprise_box">
    <div class="bg_box" :style="{ backgroundImage: 'url(' + $imgUrl + bgImg + ')' }"></div>
    <div class="contain">
      <div class="sfq_box">
        <ul class="sfq">
          <li
            v-for="(item, index) in listData"
            :key="index"
            @mouseenter="hover($event, 'mouseenter', index)"
            @mouseleave="cancelhover($event, 'mouseleave', index)"
            class="liItem"
          >
            <img v-if="hoverSort == index" :src="item.img" alt="" />
            <img v-else :src="item.img2" alt="" />
            <div class="mask_box">
              <div class="show">
                <div class="show_box">
                  <div>
                    <img :src="item.icon" alt="" />
                  </div>
                  <span>{{ item.title }}</span>
                </div>
              </div>
              <div class="hover-show">
                <h2 class="mask_header">高校院所</h2>
                <div class="mask_text">
                  <span>概念验证</span
                  ><span>技术可行性分析 商业可行性分析 商业策划 样机试制</span>
                </div>
                <div class="mask_text">
                  <span>中试熟化</span
                  ><span>产品开发迭代 工艺优化 小批量试产 可量产分析</span>
                </div>
                <div class="mask_text">
                  <span>成果转化</span
                  ><span
                    >转化方案咨询 市场需求对接 技术合同谈判 项目落地运营
                  </span>
                </div>
                <div class="mask_text">
                  <span>项目孵化</span
                  ><span>孵化载体 创业指导 政策咨询 产业资源对接</span>
                </div>
                <div class="mask_text">
                  <span>投融资服务</span
                  ><span>中试验证资金获取 天使投资、风险投资基金 </span>
                </div>
                <div class="mask_icon">
                  <img src="./img/组 1210.png" alt="" />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <ul class="mengban_box">
        <div class="mengban">
          <div v-for="(item, index) in iconList" :key="index">
            <img :src="item.img" alt="" />
            <span>{{ item.text }}</span>
          </div>
        </div>
      </ul>
      <div class="partner_case">
        <div class="left">
          <div class="mini_box">
            <p>合作案例</p>
            <p>复旦大学鞋底消毒机</p>
            <div class="mini">
              <div>
                <img src="./img/图片28.png" alt="" />
              </div>
              <div>
                上海复旦大学作为知名的院所，积极响应政府号召，主动承担社会责任，于4月起便陆续制定了防疫管理措施，做好全面解封迎接学员返校的工作。复旦大学针对校园内场景如：教室、宿舍、图书馆、食堂、快递寄收点等密闭场景，从口到手再到脚做了立体部署的防疫配置。
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <img src="./img/矩形 516.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { banner } from '@/api/index';

export default {
  data() {
    return {
      listData: [
        {
          img: require("./img/矩形 395.png"),
          img2: require("./img/微信图片_20221206172445.png"),
          icon: require("./img/微信图片_20221206171239.png"),
          title: "高校院所",
          text: [
            { id: 1, value: "项目信息录入" },
            { id: 1, value: "项目拆分、进度追踪" },
            { id: 1, value: "项目任务分配" },
            { id: 1, value: "信息化展示" },
            { id: 1, value: "任务执行记录" },
          ],
        },
        {
          img: require("./img/微信图片_20221206172155.png"),
          img2: require("./img/矩形 394.png"),
          icon: require("./img/组 1211.png"),
          title: "科创企业",
          text: [
            { id: 1, value: "信息对接服务" },
            { id: 1, value: "科技成果评估服务" },
            { id: 1, value: "技术熟化服务" },
          ],
        },
        {
          img: require("./img/微信图片_20221206172150.png"),
          img2: require("./img/矩形 510.png"),
          icon: require("./img/组 1212.png"),
          title: "其他项目",
          text: [
            { id: 1, value: "产业落地方案定制" },
            { id: 1, value: "企业资源精准导入" },
            { id: 1, value: "职业技术经理人跟踪服务" },
          ],
        },
      ],
      iconList: [
        { img: require("./img/组 1214.png"), text: "专属顾问咨询" },
        { img: require("./img/组 1215.png"), text: "定制服务方案" },
        { img: require("./img/组 1216.png"), text: "确定合作" },
        { img: require("./img/组 1217.png"), text: "资料梳理与撰写" },
        { img: require("./img/组 1218.png"), text: "服务状态跟踪" },
        { img: require("./img/组 1219.png"), text: "项目成功验收" },
      ],
      hoverSort: "",
      bgImg:''
    };
  },
  mounted() {
    this.hoverSort = 0;
    let list = document.querySelectorAll(".liItem");
    list[0].classList.add("hoverClass");
    banner().then((res) => {
        if (res.state === 200) {
          this.bgImg = res.data.find((item) => {
            return item.page == "解决方案";
          }).image;
        }
      });
  },
  methods: {
    hover(e, behavior, index) {
      if (index == this.hoverSort) return;
      // 更新hover对象
      this.hoverSort = index;
      let list = document.querySelectorAll(".liItem");
      for (let i = 0; i < list.length; i++) {
        list[i].classList.remove("hoverClass");
      }
      e.target.classList.add("hoverClass");
    },
    cancelhover(e, behavior, index) {
      // e.target.classList.remove('hoverClass')
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.enterprise_box {
  .bg_box {
    width: 100%;
    height: 400px;
    // background-image: url("./img/组 1255.png");
    background-size: 100% 100%;
  }
  .contain {
    .sfq_box {
      width: 1920px;
      height: 700px;
      background: linear-gradient(180deg, #f4fafa 0%, #ffffff 100%);
      position: relative;
      .sfq {
        position: absolute;
        top: 80px;
        left: 144px;
        width: calc(100% - 288px);
        height: 460px;
        display: flex;
        justify-content: space-between;
        .liItem {
          overflow: hidden;
          width: 390px;
          height: 460px;
          position: relative;
          cursor: pointer;
          color: #fff;
          // &:hover {
          //   width: 804px;
          //   .mask_box {
          //     .show {
          //       left: -390px;
          //     }
          //     .hover-show {
          //       left: 0px;
          //     }
          //   }
          // }
          img {
            width: 100%;
            height: 100%;
          }
          .mask_box {
            display: flex;
            overflow: hidden;
            .show {
              position: absolute;
              top: 0;
              left: 0;
              width: 390px;
              height: 460px;
              .show_box {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100px;
                height: 100px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                div {
                  width: 40px;
                  height: 40px;
                  margin-bottom: 16px;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                span {
                  text-align: center;
                  font-size: 24px;
                }
              }
            }
            .hover-show {
              width: 804px;
              height: 460px;
              position: absolute;
              top: 0;
              left: 804px;
              .mask_header {
                margin-left: 40px;
                margin-top: 40px;
                font-size: 32px;
                margin-bottom: 30px !important;
              }
              .mask_text {
                margin-top: 20px;
                margin-left: 40px;
                span {
                }
                span:nth-of-type(1) {
                  display: inline-block;
                  width: 113px;
                  font-weight: bold;
                }
                span:nth-of-type(2) {
                }
              }
              .mask_icon {
                margin-top: 80px;
                margin-left: 40px;
                width: 32px;
                height: 16px;
                cursor: pointer;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
        .hoverClass {
          transition: all 0.3s ease-out;
          width: 804px;
          .mask_box {
            position: absolute;
            color: #ffffff;
            text-align: left;
            .show {
              left: -390px;
            }
            .hover-show {
              left: 0px;
            }
          }
        }
        li:nth-of-type(1) {
          .mask_box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              180deg,
              #2b71ee 0%,
              rgba(1, 209, 246, 0.5) 100%
            );
            backdrop-filter: blur(3px);
            box-shadow: 0px 10px 10px 1px rgba(0, 43, 51, 0.1);
            color: #ffffff;
            text-align: left;
          }
        }
        li:nth-of-type(2) {
          .mask_box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(#303133, 0.4);
            backdrop-filter: blur(3px);
            box-shadow: 0px 10px 10px 1px rgba(0, 43, 51, 0.1);
            color: #ffffff;
            text-align: left;
          }
        }
        li:nth-of-type(3) {
          .mask_box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              180deg,
              rgba(#00d6cb, 0.5),
              0%,
              rgba(#00a2c0, 0.5) 100%
            );
            backdrop-filter: blur(12px);
            box-shadow: 0px 10px 10px 1px rgba(0, 43, 51, 0.1);
            color: #ffffff;
            text-align: left;
          }
        }
      }
    }
    .mengban_box {
      position: relative;
      width: 100%;
      height: 142px;
      background-color: #fafafb;
      .mengban {
        position: absolute;
        top: -80px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 1632px;
        height: 160px;
        background: linear-gradient(180deg, #f3fbfb 0%, #ffffff 100%);
        border: 1px solid #e4ecec;
        display: flex;
        justify-content: space-evenly;
        div {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          img {
            height: 64px;
            object-fit: contain;
          }
        }
      }
    }
    .partner_case {
      width: 1920px;
      height: 520px;
      display: flex;
      .left {
        background-color: #293d3c;
        flex: 1;
        position: relative;
        .mini_box {
          width: 804px;
          height: max-content;
          position: absolute;
          left: 144px;
          bottom: 100px;
          text-align: left;
          p:nth-of-type(1) {
            display: block;
            font-size: 32px;
            font-weight: bold;
            color: #ffffff;
          }
          p:nth-of-type(2) {
            margin-top: 15px;
            display: block;
            font-size: 24px;
            font-weight: normal;
            color: #ffffff;
            margin-bottom: 50px;
          }
          .mini {
            width: 724px;
            height: 120px;
            background: #fff;
            padding: 30px 40px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            div:nth-of-type(1) {
              width: 125px;
              height: 32px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            div:nth-of-type(2) {
              font-size: 16px;
            }
          }
        }
      }
      .right {
        width: 811px;
        height: 520px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>