<template>
  <div class="static">
    <!-- <div class="position">
      <span>公司布局地图</span>
      <div class="img_box">
        <img :src="$imgUrl + positionImg.text" alt="图片加载失败" />
        <div class="icon_item">
          <img src="../images/组 1309.png" alt="" />中试基地
        </div>
        <div class="icon_item">
          <img src="../images/组 1310.png" alt="" />服务区域
        </div>
      </div>
    </div> -->
    <div class="partner-container">
      <span>合作伙伴</span>
      <el-tabs v-model="activeCategory" :stretch="true">
        <el-tab-pane
          v-for="(item, index) in categories"
          :key="index"
          :label="item"
          :name="item"
        ></el-tab-pane>
      </el-tabs>
      <!-- <vue-seamless-scroll
        :data="iconList1"
        :class-option="defaultOption"
        class="icon_list1"
      >
        <ul>
          <li v-for="(item, index) in iconList1" :key="index">
            <a href="#" target="_blank"
              ><img :src="$imgUrl + item.image" alt="图片加载失败"
            /></a>
          </li>
        </ul>
      </vue-seamless-scroll> -->
      <!-- <vue-seamless-scroll
        :data="iconList2"
        :class-option="defaultOption1"
        class="icon_list2"
      >
        <ul>
          <li v-for="(item, index) in iconList2" :key="index">
            <a href="#" target="_blank"
              ><img :src="$imgUrl + item.image" alt="图片加载失败"
            /></a>
          </li>
        </ul>
      </vue-seamless-scroll> -->
      <div class="partner-wrap">
        <div
          v-for="(item, index) in activePartnerList"
          :key="index"
          class="partner-item"
        >
          <img :src="item.pic_url" alt="" class="item-icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import { partner } from "@/api/index";

export default {
  data() {
    return {
      partnerList: [],
      categories: [
        "政府机构",
        "高校",
        "商用服务机器人",
        "消费电子",
        "智慧交通",
        "自助服务终端",
        "汽车电子",
        "新赛道",
      ],
      activeCategory: "",
    };
  },
  components: {
    vueSeamlessScroll,
  },
  computed: {
    activePartnerList() {
      let arr = this.partnerList.filter((item) => {
        return item.category === this.activeCategory;
      });
      return arr;
    },
  },
  methods: {
    getData() {
      partner().then((res) => {
        if (res.state === 0) {
          // this.categories = res.data.map((item) => {
          //   return item.category;
          // });
          // this.categories = Array.from(new Set(this.categories));
          this.activeCategory = this.categories[0];
          this.partnerList = res.data;
        }
      });
    },
    iconToGo(index) {
      window.location.href = index;
    },
  },
  mounted() {
    this.getData();
  },
  // watch: {
  //   partners: {
  //     handler(newVal, oldVal) {
  //       this.iconList1 = newVal.slice(0, Math.floor(newVal.length / 2));
  //       this.iconList2 = newVal.slice(
  //         Math.floor(newVal.length / 2),
  //         newVal.length
  //       );
  //       console.log(this.iconList1, this.iconList2);
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  //   positionImg: {
  //     handler(newVal, oldVal) {
  //       console.log(newVal);
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },
  // computed: {
  //   defaultOption() {
  //     return {
  //       step: 1, // 数值越大速度滚动越快
  //       limitMoveNum: 14, // 开始无缝滚动的数据量 this.dataList.length
  //       hoverStop: false, // 是否开启鼠标悬停stop
  //       direction: 2, // 0向下 1向上 2向左 3向右
  //       openWatch: true, // 开启数据实时监控刷新dom
  //       singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
  //       singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
  //       waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
  //     };
  //   },
  //   defaultOption1() {
  //     return {
  //       step: 1, // 数值越大速度滚动越快
  //       limitMoveNum: 14, // 开始无缝滚动的数据量 this.dataList.length
  //       hoverStop: false, // 是否开启鼠标悬停stop
  //       direction: 3, // 0向下 1向上 2向左 3向右
  //       openWatch: true, // 开启数据实时监控刷新dom
  //       singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
  //       singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
  //       waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
  //     };
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.static {
  margin-top: 120px;
  .partner-wrap {
    width: 100%;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
    padding: 40px 0;
    margin: 48px auto 100px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    .partner-item {
      width: 160px;
      height: 80px;
      .item-icon {
        width: 160px;
        height: 80px;
      }
    }
  }
}
.service {
  padding: 0px 350px;
}
.position {
  padding: 0px 150px;
  padding-top: 120px;
  span {
    font-weight: bold;
    font-size: 48px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -28px;
      bottom: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      background-image: url("../images/椭圆\ 13.png");
      background-size: 100% 100%;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: -28px;
      bottom: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      background-image: url("../images/椭圆\ 13.png");
      background-size: 100% 100%;
    }
  }
  .img_box {
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 120px;
    width: 884px;
    height: 682px;
    position: relative;
    // box-shadow: 0px 0px 20px rgba(36, 36, 172, 0.123);
    img {
      width: 100%;
      height: 100%;
    }
    .icon_item {
      position: absolute;
      right: -215px;
      font-size: 16px;
      color: #000000;
      img {
        width: 14px;
        height: 14px;
        margin-right: 8px;
        margin-top: 4px;
      }
    }
    .icon_item:nth-of-type(1) {
      bottom: 70px;
    }
    .icon_item:nth-of-type(2) {
      bottom: 102px;
    }
  }
}
.partner-container {
  width: 100%;
  padding: 0 320px;
  box-sizing: border-box;
  // padding: 0px 150px;
  span {
    font-weight: bold;
    font-size: 48px;
    display: inline-block;
    position: relative;
    margin-bottom: 80px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -25px;
      bottom: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      background-image: url("../images/椭圆\ 13.png");
      background-size: 100% 100%;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: -25px;
      bottom: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      background-image: url("../images/椭圆\ 13.png");
      background-size: 100% 100%;
    }
  }
  .icon_list1 {
    width: 100%;
    height: 80px;
    margin-top: 80px;
    overflow: hidden;
    ul {
      width: 100%;
      height: 80px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      li {
        margin-left: 10px;
        margin-right: 10px;
        width: 160px;
        height: 80px;
        cursor: pointer;
        box-sizing: border-box;
        border: 1px solid #333333;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .icon_list2 {
    width: 100%;
    height: 80px;
    margin-top: 40px;
    margin-bottom: 90px;
    overflow: hidden;
    ul {
      width: 100%;
      height: 80px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      li {
        margin-left: 10px;
        margin-right: 10px;
        width: 160px;
        height: 80px;
        cursor: pointer;
        box-sizing: border-box;
        border: 1px solid #333333;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
/deep/.el-tabs__item.is-active {
  color: #00d6cb;
}
/deep/.el-tabs__active-bar {
  background-color: #00d6cb;
}
</style>
