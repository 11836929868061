<template>
  <div :class="headerStyle">
    <el-row class="PC">
      <el-col :span="4">
        <div class="index-logo">
          <div class="logo" @click="toIndex">
            <img
              v-if="headerStyle === 'transparent'"
              src="@/assets/img/homePage/logo_light.png"
              alt=""
            />
            <img
              v-if="headerStyle === 'white'"
              src="@/assets/img/homePage/logo_dark.png"
              alt=""
            />
          </div>
        </div>
      </el-col>
      <el-col :span="1"></el-col>
      <el-col :span="10">
        <el-menu
          :default-active="$route.path"
          :active-text-color="activeColor2"
          :router="true"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect1"
          ref="elMenu"
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-submenu index="/solution" class="el-submenu">
            <template slot="title">解决方案</template>
            <el-menu-item index="/colleges"> 高校院所</el-menu-item>
            <el-menu-item index="/enterprise">科创企业</el-menu-item>
          </el-submenu>
          <el-menu-item index="/serviceCase">服务案例</el-menu-item>
          <el-menu-item index="/companyDynamics">公司动态</el-menu-item>
          <el-submenu index="/aboutUs" class="el-submenu">
            <template slot="title">关于我们</template>
            <el-menu-item index="/aboutUs" class="el-menu-item"
              >公司简介</el-menu-item
            >
            <el-menu-item index="/joinUs" class="el-menu-item"
              >加入我们</el-menu-item
            >
            <el-menu-item index="/contactUs" class="el-menu-item"
              >联系我们</el-menu-item
            >
          </el-submenu>
        </el-menu>
      </el-col>
      <el-col :span="9" class="rightMenu">
        <el-menu
          :default-active="activeIndex1"
          :active-text-color="activeColor1"
          :router="true"
          class="el-menu"
          mode="horizontal"
          @select="handleSelect"
          ref="elMenu1"
        >
          <el-menu-item index="/demand" class="el-menu-item">
            <div>
              <img
                v-show="headerStyle === 'transparent'"
                src="@/assets/img/homePage/home-req-active.svg"
                class="home-menu-icon"
                alt="需求库"
              />
              <img
                v-show="headerStyle !== 'transparent'"
                src="@/assets/img/homePage/home-req-default.svg"
                class="home-menu-icon"
                alt="需求库"
              />
              需求库
            </div>
            <!-- <div v-if="headerStyle === 'transparent'" class="remark">
              快速征集解决方案
            </div> -->
          </el-menu-item>
          <el-menu-item index="/expert" class="el-menu-item">
            <div>
              <img
                v-show="headerStyle === 'transparent'"
                src="@/assets/img/homePage/home-expert-active.svg"
                class="home-menu-icon"
                alt="专家库"
              />
              <img
                v-show="headerStyle !== 'transparent'"
                src="@/assets/img/homePage/home-expert-default.svg"
                class="home-menu-icon"
                alt="专家库"
              />专家库
            </div>
            <!-- <div v-if="headerStyle === 'transparent'" class="remark">
              全网推广技术成果
            </div> -->
          </el-menu-item>
          <el-menu-item index="/technology" class="el-menu-item">
            <div>
              <img
                v-show="headerStyle === 'transparent'"
                src="@/assets/img/homePage/home-ta-active.svg"
                class="home-menu-icon"
                alt="成果库"
              />
              <img
                v-show="headerStyle !== 'transparent'"
                src="@/assets/img/homePage/home-ta-default.svg"
                class="home-menu-icon"
                alt="成果库"
              />成果库
            </div>
            <!-- <div v-if="headerStyle === 'transparent'" class="remark">
              技术交易合同登记
            </div> -->
          </el-menu-item>
        </el-menu>
        <el-dropdown type="primary" v-if="hasLog">
          <div class="my" @click="routerTo('/personal')">
            <img src="../content/images/组 377.png" alt="" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="signBox" v-else>
          <div
            :class="signIndex === 1 ? 'signIn' : 'signUp'"
            @click="handleLogin"
          >
            登录
          </div>
          <div
            :class="signIndex === 2 ? 'signIn' : 'signUp'"
            @click="routerTo('/my', 2)"
          >
            注册
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import store from "@/store";
export default {
  props: {
    headerStyle: {
      type: String,
    },
  },
  data() {
    return {
      activeIndex1: null,
      activeColor1: "#00D6CB",
      activeColor2: "#00D6CB",
      logged: false,
      signIndex: null,
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      this.$refs.elMenu.activeIndex = null;
      this.signIndex = null;
    },
    handleSelect1(key, keyPath) {
      this.$refs.elMenu1.activeIndex = null;
      this.signIndex = null;
    },
    toIndex() {
      this.headerStyle = "transparent";
      this.$router.push("/");
      this.signIndex = null;
    },
    handleLogin() {
      this.$emit("openLogin");
    },
    routerTo(link, index) {
      this.$router.push(link);
      if (this.signIndex === index) return;
      this.signIndex = index;
      this.$refs.elMenu.activeIndex = null;
      this.$refs.elMenu1.activeIndex = null;
    },
    logout() {
      store.commit("removeUserInfo");
      this.$router.push("/");
    },
  },
  watch: {
    // 取消导航栏的选中状态
    $route: {
      handler(val, oldval) {
        console.log(val); //新路由信息
        console.log(oldval); //老路由信息
        console.log(this.$refs.elMenu);
        if (val.name === "首页") {
          this.$refs.elMenu.activeIndex = null;
          this.$refs.elMenu1.activeIndex = null;
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  computed: {
    hasLog() {
      store.commit("getToken");
      this.logged = store.state.logged;
      return this.logged;
    },
  },
};
</script>

<style lang="scss" scoped>
.transparent {
  width: 100%;
  height: 72px;
  background: transparent;
  display: flex;
  position: absolute;
  z-index: 1000;
  top: 0;
  position: relative;
  position: fixed;
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  padding-right: 160px;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    opacity: 0.3;
    border: 1px solid #ffffff;
  }
  .PC {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    .index-logo {
      height: 72px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .logo {
        cursor: pointer;
        width: 150px;
        height: 50px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }

    .el-menu {
      height: 72px;
      background-color: transparent;
      box-sizing: border-box;
      border: none;
      .el-submenu {
        height: 72px;
        line-height: 72px;
        color: #fff;
        font-size: 16px;
        /deep/ .el-submenu__title {
          height: 72px;
          line-height: 72px;
          color: #fff;
          font-size: 16px;
        }
        /deep/ .el-submenu__title:hover {
          color: #00d6cb;
          background-color: transparent !important;
        }

        /deep/ .el-submenu__title {
          border-bottom: 3px solid #00d6cb;
        }
        .el-menu-item {
          height: 100px;
          line-height: 72px;
          color: #fff;
          font-size: 16px;
          border-bottom: 3px solid #00d6cb;
        }
      }
      .el-menu-item {
        height: 72px;
        padding: 10px 0 !important;
        box-sizing: border-box;
        width: 120px;
        color: #fff;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        div {
          width: 100%;
          height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #00d6cb;
          &:hover {
            color: #fff;
          }
          // &.remark {
          //   font-size: 12px;
          //   color: rgba(255, 255, 255, 0.6);
          // }
        }
      }
      .el-menu-item:hover {
        background-color: transparent !important;
      }
      .el-menu-item:active {
        background-color: transparent !important;
      }
      .el-menu-item:focus {
        background-color: transparent !important;
      }
    }
    .el-menu-item.is-active {
      background-color: transparent;
    }
    .signBox {
      color: #fff;
    }
  }

  .my {
    display: block;
    // width: 40px;
    // height: 40px;
    border-radius: 100%;
    margin: 10px 0;
    cursor: pointer;
  }
}

.white {
  width: 100%;
  height: 72px;
  background: #f6f6f6;
  display: flex;
  position: absolute;
  z-index: 1000;
  top: 0;
  position: relative;
  position: fixed;
  backdrop-filter: blur(10px);
  box-sizing: border-box;
  padding-right: 160px;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    opacity: 0.3;
    border: 1px solid #ffffff;
  }
  .PC {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    .index-logo {
      height: 72px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .logo {
        cursor: pointer;
        width: 150px;
        height: 50px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }

    .el-menu {
      height: 72px;
      background-color: transparent;
      box-sizing: border-box;
      border: none;
      .el-submenu {
        height: 72px;
        line-height: 72px;
        color: #303133;
        font-size: 16px;
        /deep/ .el-submenu__title {
          height: 72px;
          line-height: 72px;
          color: #303133;
          font-size: 16px;
        }
        /deep/ .el-submenu__title:hover {
          color: #00d6cb;
          background-color: transparent !important;
        }
        /deep/ .el-menu-item {
          height: 100px;
          line-height: 72px;
          color: #303133;
          font-size: 16px;
        }
        /deep/ .el-menu-item:hover {
          color: #00d6cb !important;
        }
      }
      .el-menu-item {
        height: 72px;
        color: #303133;
        font-size: 16px;
        padding: 10px 0 !important;
        box-sizing: border-box;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        div {
          width: 100%;
          height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .el-menu-item.is-active {
      background-color: transparent;
    }
  }
}

.el-menu--popup-bottom-start .el-submenu >>> .el-menu-item {
  color: red !important;
}
.el-menu--popup-bottom-start .el-submenu >>> .el-submenu__title:hover {
  color: #00d6cb !important;
}
.el-menu-item:hover {
  color: #00d6cb !important;
}
// /deep/.el-dropdown {
//   margin-left: 250px;
// }

/*菜单下拉框样式结束*/
</style>

<style lang="scss">
.transparent {
  .el-submenu__title i {
    color: #fff;
  }
}
.white {
  .el-submenu__title i {
    color: black;
  }
}
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  top: 0;
  min-width: 110px !important;
  text-align: center;

  .el-menu-item {
    color: #303133 !important;
  }
}
.rightMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.signBox {
  display: flex !important;
  font-size: 16px;
  flex: 1;
  justify-content: flex-end;
  height: 72px;
  line-height: 72px;
  div {
    padding: 0 20px;
    cursor: pointer;
  }
  .signIn {
    color: #00d6cb;
  }
}
.home-menu-icon {
  width: 24px;
  height: 24px;
  margin-right: 3px;
}
</style>
