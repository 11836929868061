<template>
  <div class="aboutUs">
    <div class="bg" :style="{ backgroundImage: 'url(' + bgImg + ')' }"></div>
    <div class="content">
      <ul class="sort">
        <li
          :class="index == activeTitle ? 'activeTitle' : ''"
          v-for="(item, index) in titleSort"
          :key="index"
          @click="changeTitle(item, index)"
        >
          {{ item.name }}
        </li>
      </ul>
      <div class="info_box">
        <div class="info_title">
          蜂鸟智造简介<img src="./img/组 1145.png" alt="" />
        </div>
        <div v-html="companyInfo"></div>
      </div>
      <div class="history">
        <h2>发展历程</h2>
        <div class="year">
          <img
            v-if="showLeftArrow"
            src="@/assets/img/aboutUs/next.svg"
            class="arrow-icon left"
            alt=""
            @click="yearPrev"
          />
          <ul>
            <li
              v-for="(item, index) in yearList"
              :key="index"
              :class="item == activeYear ? 'activeYear' : ''"
              @click="changeYear(item)"
            >
              {{ item }}
            </li>
          </ul>
          <img
            v-if="showRightArrow"
            src="@/assets/img/aboutUs/next.svg"
            class="arrow-icon right"
            alt=""
            @click="yearNext"
          />
        </div>
        <div class="month">
          <div class="month_left">
            <div v-for="(item, index) in historyList" :key="index">
              <span @click="onIconCLick(item)">
                <i class="hook"></i>
                {{ new Date(item.month).getMonth() + 1 }}月</span
              >
              <p>{{ item.introduction }}</p>
            </div>
          </div>
          <div class="month_right">
            <img :src="activeImg" alt="" />
          </div>
        </div>
      </div>
      <div class="env">
        <h2>公司环境</h2>
        <div class="pic-wall">
          <div
            v-for="(item, index) in envData"
            :key="index"
            class="is-grid"
            :class="(index + 1) % 2 === 1 ? 'is-odd' : 'is-even'"
          >
            <div
              v-for="(i, idx) in item"
              :key="idx"
              :class="getGridClass(idx + 1, (index + 1) % 2 === 1)"
            >
              <img :src="i.pic_url" :alt="i.name" class="env-pics" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { otherBanner } from "@/api/index";
import { about, env } from "@/api/aboutus";

export default {
  data() {
    return {
      companyInfo: {},
      titleSort: [
        { id: 1, name: "了解我们", url: "/aboutUs" },
        { id: 2, name: "加入我们", url: "/joinUs" },
        { id: 3, name: "联系我们", url: "contactUs" },
      ],
      activeTitle: "",
      maskShow: false,
      detailData: {},
      yearList: [2018, 2019, 2020, 2021, 2022, 2023],
      monthList: [],
      activeYear: 2023,
      imageYear: "",
      bgImg: "",
      currentYear: new Date().getFullYear(),
      companyInfo: {},
      historyList: [],
      activeImg: "",
      envData: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    showRightArrow() {
      return this.yearList[5] < this.currentYear;
    },
    showLeftArrow() {
      return this.yearList[0] > 2018;
    },
  },

  methods: {
    getBanner() {
      otherBanner({ page_name: "about_banner_url" }).then((res) => {
        if (res.state === 0) {
          this.bgImg = res.data;
        }
      });
    },
    getData(year) {
      about({ year }).then((res) => {
        if (res.state === 0) {
          this.companyInfo = res.data.company_info;
          this.historyList = res.data.history_list;
          this.activeImg = this.historyList[0].pic_url;
        }
      });
    },
    getEnv() {
      env().then((res) => {
        if (res.state === 0) {
          let odd = [];
          let even = [];
          let list = [];
          res.data.forEach((item, index) => {
            if (odd.length < 4) {
              odd.push(item);
              if (index === res.data.length - 1) {
                list.push(odd);
              }
            } else {
              even.push(item);
              if (even.length < 5) {
                if (index === res.data.length - 1) {
                  list.push(odd);
                  list.push(even);
                }
              } else {
                list.push(odd);
                list.push(even);
                odd = [];
                even = [];
              }
            }
          });
          this.envData = list;
        }
      });
    },
    getGridClass(idx, isOdd) {
      if (isOdd) {
        let trueIdx = idx - 4 > 0 ? idx - 4 : idx;
        if (trueIdx % 4 === 0) {
          return "odd-right";
        } else if (trueIdx % 3 === 0) {
          return "odd-mid-bottom";
        } else if (trueIdx % 2 === 0) {
          return "odd-mid-top";
        } else {
          return "odd-left";
        }
      } else {
        let trueIdx = idx - 5 > 0 ? idx - 5 : idx;
        if (trueIdx % 5 === 0) {
          return "even-right-bottom";
        } else if (trueIdx % 4 === 0) {
          return "even-right-top";
        } else if (trueIdx % 3 === 0) {
          return "even-mid";
        } else if (trueIdx % 2 === 0) {
          return "even-left-bottom";
        } else {
          return "even-left-top";
        }
      }
    },
    getYearList() {
      const newDate = new Date();
      let y = newDate.getFullYear() - 5;
      let arr = [];
      for (let i = 1; i < 7; i++) {
        arr.push(y);
        ++y;
      }
      this.yearList = arr;
    },
    nextYear() {},
    preYear() {},
    init() {
      this.activeTitle = 0;
      this.getBanner();
      this.getYearList();
      this.getData(this.currentYear);
      this.getEnv();
    },
    changeTitle(item, index) {
      if (index == this.activeTitle) return;
      // 更新title
      this.$router.replace(item.url);
    },
    changeYear(item) {
      if (item == this.activeYear) return;
      // 更新year
      this.activeYear = item;
      this.getData(this.activeYear);
    },
    yearNext() {
      this.yearList.push(this.yearList[5] + 1);
      this.yearList.shift();
      if (this.activeYear < this.yearList[0]) {
        this.activeYear = this.yearList[0];
        this.getData(this.activeYear);
      }
    },
    yearPrev() {
      this.yearList.unshift(this.yearList[0] - 1);
      this.yearList.pop();
      if (this.activeYear > this.yearList[5]) {
        this.activeYear = this.yearList[5];
        this.getData(this.activeYear);
      }
    },
    itemDetail(item) {
      this.detailData = item;
      this.maskShow = true;
    },
    onIconCLick(data) {
      this.activeImg = data.pic_url;
    },
  },
  watch: {
    maskShow: function (newVal, oldVal) {
      console.log(newVal, oldVal);
      // if(this.maskShow){
      //   document.body.style.overflow='hidden';
      // }else{
      //   document.body.style.overflow='';
      // }
    },
    activeYear: {
      handler(newV, oldV) {
        this.imageYear = this.yearList.find((item) => {
          return item.year == newV;
        })?.image;
      },
      deep: true,
      immediate: true,
    },
  },
  // computed: {
  //   imageYear() {
  //     this.imageYear = this.yearList.find((item) => {
  //       return item.year == this.activeYear;
  //     });
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.aboutUs {
  position: relative;
  .bg {
    width: 100%;
    height: 400px;
    // background-image: url("./img/组\ 1181.png");
    background-size: 100% 100%;
  }
  .content {
    box-sizing: border-box;
    // padding: 88px 144px 60px 144px;
    position: relative;
    background: #ffffff;
    .sort {
      width: 1280px;
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translate(-50%, 0);
      display: flex;
      line-height: 56px;
      font-size: 24px;
      color: #303133;
      background: #ffffff;
      li {
        width: 544px;
        height: 56px;
        cursor: pointer;
        box-shadow: 0px 10px 10px 1px rgba(0, 43, 51, 0.1);
      }
      .activeTitle {
        background: linear-gradient(270deg, #00d6cb 0%, #00a2c0 100%);
        color: #ffffff;
        box-shadow: none;
      }
    }
    .info_box {
      width: calc(100% - 640px);
      padding: 128px 320px 80px;
      // max-height: 672px;
      // background-image: url("./img/组 1182.png");
      text-align: left;
      color: #303133;
      .info_title {
        text-align: center;
        margin-bottom: 60px;
        font-size: 48px;
        font-weight: bold;
        position: relative;
        img {
          position: absolute;
          top: calc(50% - 20px);
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      // p {
      //   font-size: 16px;
      //   margin-bottom: 40px;
      //   line-height: 36px;
      //   white-space: pre-line;
      // }
    }
    .history {
      width: calc(100% - 640px);
      // height: 840px;
      padding: 70px 320px 70px;
      background: linear-gradient(16deg, #f8fdff 0%, #e7f8ff 100%);
      h2 {
        font-size: 48px;
        color: #303133;
      }
      .year {
        width: 1200px;
        height: 100px;
        position: relative;
        margin: 60px 0px 80px 0px;
        ul {
          position: absolute;
          left: 0px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          z-index: 10;
          li {
            width: 100px;
            height: 100px;
            border-radius: 100%;
            background: #ffffff;
            line-height: 100px;
            text-align: center;
            font-size: 36px;
            font-weight: 400;
            color: #303133;
            cursor: pointer;
          }
          .activeYear {
            background: #00d6cb;
            box-shadow: 0px 10px 10px 1px rgba(0, 67, 56, 0.1);
            color: #ffffff;
          }
        }
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 1200px;
          height: 3px;

          background: #b5e2e0;
          // background: linear-gradient(
          //   135deg,
          //   rgba(224, 224, 224, 0) 0%,
          //   #ececec 4%,
          //   #ececec 49%,
          //   #ececec 95%,
          //   rgba(224, 224, 224, 0) 100%
          // );
        }
        .arrow-icon {
          position: absolute;
          width: 60px;
          height: 60px;
          cursor: pointer;
          border-radius: 50%;
          &:active {
            opacity: 0.8;
          }
          &.left {
            left: -100px;
            top: 20px;
            transform: rotate(180deg);
            box-shadow: 0px -10px 10px 1px rgba(0, 67, 56, 0.1);
          }
          &.right {
            right: -100px;
            top: 20px;
            box-shadow: 0px 10px 10px 1px rgba(0, 67, 56, 0.1);
          }
        }
      }
      .month {
        display: flex;
        .month_left {
          flex: 1;
          text-align: left;
          margin-right: 20px;
          div {
            margin-bottom: 40px;
            span {
              display: block;
              font-size: 24px;
              color: #303133;
              font-weight: bold;
              position: relative;
              margin-bottom: 16px;
              cursor: pointer;
              .hook {
                position: absolute;
                top: 50%;
                left: -75px;
                transform: translate(0, -50%);
                width: 16px;
                height: 16px;
                background: #00d6cb;
                border-radius: 100%;
                z-index: 9;

                &::after {
                  content: "";
                  position: absolute;
                  top: 16px;
                  left: 50%;
                  transform: translate(-50%, 0);
                  width: 1px;
                  height: 105px;
                  background-color: #dadada;
                  z-index: 8;
                }
              }
            }
            p {
              font-size: 16px;
              color: #606266;
            }
          }
          div:last-of-type {
            span {
              .hook {
                &::after {
                  content: "";
                  height: 0;
                }
              }
            }
          }
        }
        .month_right {
          width: 839px;
          height: 401px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .env {
      background-color: #fafafb;
      padding: 60px 140px 110px;
      h2 {
        font-size: 48px;
        color: #303133;
      }
      .pic-wall {
        margin-top: 60px;
        .is-grid {
          width: 100%;
          display: grid;
          grid-gap: 24px;
          margin-bottom: 24px;
          &.is-odd {
            grid-template-columns: 666px 390px 528px;
            grid-template-areas:
              "odd-left odd-mid-top odd-right"
              "odd-left odd-mid-bottom odd-right";

            .odd-left {
              grid-area: odd-left;
              height: 464px;
            }
            .odd-right {
              grid-area: odd-right;
              height: 464px;
            }
            .odd-mid-top {
              grid-area: odd-mid-top;
              height: 220px;
            }
            .odd-mid-bottom {
              grid-area: odd-mid-bottom;
              height: 220px;
            }
          }
          &.is-even {
            grid-template-columns: 528px 666px 390px;
            grid-template-areas:
              "even-left-top even-mid even-right-top"
              "even-left-bottom even-mid even-right-bottom";
            .even-left-top {
              grid-area: even-left-top;
              height: 297px;
            }
            .even-left-bottom {
              grid-area: even-left-bottom;
              height: 297px;
            }
            .even-mid {
              grid-area: even-mid;
              height: 618px;
            }
            .even-right-top {
              grid-area: even-right-top;
              height: 297px;
            }
            .even-right-bottom {
              grid-area: even-right-bottom;
              height: 297px;
            }
          }
          .env-pics {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

// 遮罩
.mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: red;
  top: 0;
  .mask_Box {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    width: 780px;
    height: max-content;
    background: #ffffff;
    .top {
      width: 780px;
      height: 80px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 48px;
      border-bottom: 2px solid #ececec;
      .li_img {
        width: 80px;
        height: 80px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .li_content {
        width: 678px;
        height: 80px;
        display: flex;
        justify-content: space-between;
        .content_left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          span:nth-of-type(1) {
            font-size: 18px;
            color: #303133;
            font-weight: bold;
          }
          .numberOne {
            width: max-content;
            font-size: 14px;
            box-sizing: border-box;
            padding: 2px 8px;
            background: #ffffff;
            border: 1px solid #2b71ee;
            color: #2b71ee;
          }
          .numberTwo {
            width: max-content;
            font-size: 14px;
            box-sizing: border-box;
            padding: 2px 8px;
            background: #ffffff;
            border: 1px solid #00d6cb;
            color: #00d6cb;
          }
        }
        .content_right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          span:nth-of-type(1) {
            font-size: 18px;
            color: #ffc331;
            font-weight: bold;
            text-align: right;
          }
          span:nth-of-type(2) {
            height: 24px;
            line-height: 24px;
            color: #606266;
            font-size: 14px;
            position: relative;
            img {
              position: absolute;
              top: 3px;
              left: -24px;
              width: 16px;
              object-fit: contain;
            }
          }
        }
      }
    }
    .bottom {
      padding-top: 48px;
      text-align: left;
      span {
        display: block;
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 18px;
        color: #303133;
        font-weight: bold;
      }
      p {
        font-size: 16px;
        color: #606266;
        line-height: 30px;
      }
      .tips {
        display: block;
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }
  }
}
</style>
