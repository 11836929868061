<template>
  <div class="enterprise_box">
    <div
      class="bg_box"
      :style="{ backgroundImage: 'url(' + bgImg + ')' }"
    ></div>
    <div class="case_box">
      <div class="case" v-for="(item, index) in solutionData" :key="index">
        <div class="left">
          <div class="left_Po">
            <span class="title">{{ item.name }}</span>
            <div class="text">
              <div
                class="it"
                :class="activeDesc === it.desc ? 'active' : ''"
                v-for="(it, idx) in item.children"
                :key="idx"
                @click="onSolutionSelect(it.desc, item.name)"
              >
                <i><img src="./img/组 1270.png" alt="" /></i>
                <span>{{ it.name }}</span>
              </div>
            </div>
            <div
              v-if="activeName === item.name"
              class="desc"
              v-html="activeDesc"
            ></div>
          </div>
        </div>
        <div class="right">
          <div>
            <img :src="item.pic_url" alt="" />
          </div>
        </div>
      </div>
      <div class="case_sort">
        <span
          :class="item.name === activeCategory ? 'activeClass' : ''"
          @click="changeSort(item.name)"
          v-for="(item, index) in solutionData"
          :key="index"
          >{{ item.name }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { solution } from "@/api/solution";
import { otherBanner } from "@/api/index";
import { getToken } from "@/utils/auth";

export default {
  data() {
    return {
      listData: [],
      activeIndex: "",
      bgImg: "",
      solutionData: {},
      activeCategory: "",
      activeDesc: "",
      activeName: "",
    };
  },
  methods: {
    init() {
      this.getBanner();
      this.getData();
    },
    getBanner() {
      otherBanner({ page_name: "solution_banner_url" }).then((res) => {
        if (res.state === 0) {
          this.bgImg = res.data;
        }
      });
    },
    getData() {
      solution({ category: "高校院所" }).then((res) => {
        if (res.state === 0) {
          this.solutionData = res.data;
          this.activeCategory = res.data[0].name;
          if (getToken()) {
            this.activeName = res.data[0].name;
            this.activeDesc = res.data[0].children[0].desc;
          }
        }
      });
    },
    onSolutionSelect(desc, name) {
      if (getToken()) {
        this.activeDesc = desc;
        this.activeName = name;
      } else {
        this.$message.warning("请先登录后操作");
      }
    },
    changeSort(val) {
      if (val == this.activeCategory) return;
      // 更新当前活跃项
      this.activeCategory = val;
    },
  },
  mounted() {
    this.init();
    this.activeIndex = 0;
  },
};
</script>

<style lang="scss" scoped>
.enterprise_box {
  scroll-behavior: smooth !important;
  .bg_box {
    width: 100%;
    height: 400px;
    // background-image: url("./img/组 1253.png");
    background-size: 100% 100%;
  }
  .case_box {
    width: 100%;
    position: relative;
    font-size: 16px;
    // background: #293d3c;

    .case:nth-of-type(odd) {
      width: 100%;
      height: 480px;
      display: flex;
      background-color: #ffffff;
      .left {
        width: 50%;
        position: relative;
        .left_Po {
          position: absolute;
          top: 100px;
          left: 320px;
          .title {
            display: block;
            font-size: 32px;
            font-weight: bold;
            color: #303133;
            text-align: left;
          }
          .text {
            margin-top: 20px;
            width: 668px;
            height: max-content;
            display: flex;
            flex-wrap: wrap;
            font-size: 16px;
            color: #606266;
            .it {
              cursor: pointer;
              &.active {
                background-color: #00d6cb;
                color: #fff;
              }
            }
            div {
              padding: 0px 20px;
              height: 72px;
              background: #ffffff;
              border-radius: 40px;
              border: 1px solid #dddddd;
              margin-bottom: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 20px;
              i {
                width: 24px;
                height: 24px;
                margin-right: 16px;
              }
            }
          }
          .desc {
            width: 100%;
            box-sizing: border-box;
            padding-right: 28px;
            font-size: 14px;
            text-align: left;
          }
        }
      }

      .right {
        width: 50%;
        position: relative;
        div {
          position: absolute;
          left: 14px;
          top: 75px;
          width: 626px;
          height: 330px;
          overflow: hidden;
          img {
            cursor: pointer;
            width: 100%;
            height: 100%;
            transition: all 0.5s;
            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    .case:nth-of-type(even) {
      display: flex;
      width: 100%;
      height: 480px;
      flex-direction: row-reverse;
      background: #f7f8f9;
      .left {
        width: 50%;
        position: relative;
        .left_Po {
          position: absolute;
          top: 100px;
          left: 162px;
          .title {
            display: block;
            font-size: 32px;
            font-weight: bold;
            color: #303133;
            text-align: left;
          }
          .text {
            margin-top: 20px;
            width: 668px;
            height: max-content;
            display: flex;
            flex-wrap: wrap;
            font-size: 16px;
            color: #606266;

            .it {
              cursor: pointer;
              &.active {
                background-color: #00d6cb;
                color: #fff;
              }
            }
            div {
              padding: 0px 20px;
              height: 72px;
              background: #ffffff;
              border-radius: 40px;
              border: 1px solid #dddddd;
              margin-bottom: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 20px;
              i {
                width: 24px;
                height: 24px;
                margin-right: 16px;
              }
            }
          }
          .desc {
            width: 100%;
            box-sizing: border-box;
            padding-right: 28px;
            font-size: 14px;
            text-align: left;
          }
        }
      }

      .right {
        width: 50%;
        position: relative;
        div {
          position: absolute;
          left: 320px;
          top: 75px;
          width: 626px;
          height: 330px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.5s;
            cursor: pointer;
            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    .case_sort {
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 1280px;
      height: 56px;
      // padding: 0px 144px;
      background-color: #fff;

      display: flex;
      span {
        display: inline-block;
        width: calc(100% / 3);
        height: 100%;
        color: #303133;
        text-align: center;
        line-height: 56px;
        box-shadow: 0px 10px 10px 1px rgba(0, 43, 51, 0.1);
        cursor: pointer;
        &:hover {
          color: #00d6cb;
        }
      }
      .activeClass {
        color: #fff;
        box-shadow: none;
        background: linear-gradient(270deg, #00d6cb 0%, #00a2c0 100%);
      }
    }
  }
}
</style>
