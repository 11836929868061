/* ========== Home Page API ========== */
import request from "@/utils/request";
import Vue from "vue";
var vm = new Vue();

// Get home page banners
export function banner() {
  return request({
    url: vm.$BASE_URL + "/Index/banner",
    method: "get",
  });
}

// Get other pages banners
export function otherBanner(params) {
  return request({
    url: vm.$BASE_URL + "/Index/otherBanner",
    method: "get",
    params,
  });
}

// Get solutions
export function solution(params) {
  return request({
    url: vm.$BASE_URL + "/Index/solution",
    method: "get",
    params,
  });
}

// Get core service
export function service() {
  return request({
    url: vm.$BASE_URL + "/Index/service",
    method: "get",
  });
}

// Get partners
export function partner() {
  return request({
    url: vm.$BASE_URL + "/Index/partner",
    method: "get",
  });
}

// Get footer information
export function footer() {
  return request({
    url: vm.$BASE_URL + "/Index/footer",
    method: "get",
  });
}

export function getData() {
  return request({
    url: vm.$BASE_URL + "/index/getData",
    method: "get",
  });
}

export function getImages() {
  return request({
    url: vm.$BASE_URL + "/index/getImages",
    method: "get",
  });
}

export function getPartner() {
  return request({
    url: vm.$BASE_URL + "/index/getPartner",
    method: "get",
  });
}

export function getServiceContent() {
  return request({
    url: vm.$BASE_URL + "/index/getServiceContent",
    method: "get",
  });
}

export function getServiceObject() {
  return request({
    url: vm.$BASE_URL + "/index/getServiceObject",
    method: "get",
  });
}

// 获取banner
