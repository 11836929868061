import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/content/homePage/index";
import Solution from "@/views/content/solution/index";
import Colleges from "@/views/content/colleges/colleges";
import Enterprise from "@/views/content/enterprise/enterprise";
import ServiceCase from "@/views/content/serviceCase/index";
import CompanyDynamics from "@/views/content/companyDynamics/index";
import Demand from "@/views/content/demand/index";
import DemandDetail from "@/views/content/demand/demand";
import Expert from "@/views/content/expert/index";
import ExpertDetail from "@/views/content/expert/expert";
import Technology from "@/views/content/technology/index";
import TechnologyDetail from "@/views/content/technology/technology";
import AboutUs from "@/views/content/aboutUs/index";
import JoinUs from "@/views/content/joinUs/index";
import ContactUs from "@/views/content/contactUs/index";
import My from "@/views/content/my/index";
import Login from "@/views/content/my/login";
import Personal from "@/views/content/my/personal";
import releaseResult from "@/views/content/release/releaseResult";
import releaseDemand from "@/views/content/release/releaseDemand";

Vue.use(Router);
// 解决to跟from相同报错问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default new Router({
  mode: "hash",
  routes: [
    {
      path: "/",
      component: Home,
      name: "首页",
    },
    {
      path: "/demand",
      name: "需求库",
      component: Demand,
    },
    {
      path: "/demandDetail",
      name: "需求库",
      component: DemandDetail,
    },
    {
      path: "/expert",
      name: "专家库",
      component: Expert,
    },
    {
      path: "/expertDetail",
      name: "专家库",
      component: ExpertDetail,
    },
    {
      path: "/technology",
      name: "成果库",
      component: Technology,
    },
    {
      path: "/technologyDetail",
      name: "成果库",
      component: TechnologyDetail,
    },
    {
      path: "/home",
      name: "home",
      component: Home,
    },
    {
      path: "/Solution",
      name: "解决方案",
      component: Solution,
      hidden: true,
    },
    {
      path: "/colleges",
      name: "高校院所",
      component: Colleges,
    },
    {
      path: "/enterprise",
      name: "科创企业",
      component: Enterprise,
    },
    {
      path: "/serviceCase",
      name: "服务案例",
      component: ServiceCase,
    },
    {
      path: "/companyDynamics",
      name: "公司动态",
      component: CompanyDynamics,
    },
    {
      path: "/aboutUs",
      name: "关于我们",
      component: AboutUs,
    },
    {
      path: "/joinUs",
      name: "加入我们",
      component: JoinUs,
    },
    {
      path: "/contactUs",
      name: "联系我们",
      component: ContactUs,
    },
    {
      path: "/my",
      name: "个人中心",
      component: My,
    },
    {
      path: "/login",
      name: "登录",
      component: Login,
    },
    {
      path: "/personal",
      name: "个人中心",
      component: Personal,
    },
    {
      path: "/releaseResult",
      component: releaseResult,
      name: "发布结果",
    },
    {
      path: "/releaseDemand",
      component: releaseDemand,
      name: "发布需求",
    },
  ],
});
