<template>
  <div class="box">
    <div class="backButton" @click="goBack">
      <img
        src="@/assets/img/public/double_left_arrow.png"
        alt=""
        class="back-icon"
      />
      <span>返回</span>
    </div>
    <div class="itemBox">
      <div class="headTop">
        <!-- <div class="headImg">
          <img :src="detailData.pic_url" alt="" />
        </div> -->
        <div class="headDesc">
          <div class="detail-name">{{ detailData.name }}</div>
          <el-descriptions :column="3" :title="detailData.org" extra="">
            <el-descriptions-item label="研究领域">{{
              detailData.domain
            }}</el-descriptions-item>
            <el-descriptions-item label="所在地区">{{
              detailData.area
            }}</el-descriptions-item>
            <el-descriptions-item label="职称">{{
              detailData.job
            }}</el-descriptions-item>
            <el-descriptions-item
              v-if="isValid(detailData.paper_count)"
              label="论文数"
              >{{ detailData.paper_count }}</el-descriptions-item
            >
            <el-descriptions-item
              v-if="isValid(detailData.patent_count)"
              label="专利数"
              >{{ detailData.patent_count }}</el-descriptions-item
            >
            <el-descriptions-item
              v-if="isValid(detailData.h_factor)"
              label="H因子"
              >{{ detailData.h_factor }}</el-descriptions-item
            >
            <!-- <el-descriptions-item label="发布日期">{{
              formatDate(detailData.created_time)
            }}</el-descriptions-item> -->
            <!-- <el-descriptions-item
              label-class-name="myLabel"
            ></el-descriptions-item> -->
            <!-- <el-descriptions-item
              label-class-name="myLabel"
            ></el-descriptions-item> -->
            <el-descriptions-item label-class-name="myLabel">
              <div
                class="collectButton"
                :class="followStatus ? 'plain' : ''"
                @click="follow(detailData.id)"
              >
                {{ followStatus ? "取消关注" : "关注专家" }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="联系方式"
              :label-class-name="hide ? 'myLabel' : 'phone'"
            >
              <div class="phone" :class="hide ? 'hide' : ''">
                {{ detailData.mobile }}
              </div></el-descriptions-item
            >
          </el-descriptions>
        </div>
      </div>
      <div class="content">
        <div class="article">
          <el-descriptions :column="1" extra="">
            <el-descriptions-item label="履历背景">
              <div v-html="detailData.resume"></div>
            </el-descriptions-item>
            <el-descriptions-item label="科研成果">
              <div v-html="detailData.achievement"></div>
            </el-descriptions-item>
            <el-descriptions-item label="合作案例">
              <div v-html="detailData.cooperation"></div>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="others">
          <div
            class="demandItem"
            v-for="(item, index) in otherList"
            :key="index"
          >
            <!-- <div class="cover">
              <img :src="item.pic_url" alt="" />
            </div> -->
            <div class="title">{{ item.name }}</div>
            <el-tag size="small">{{ item.domain }}</el-tag>
            <div class="time">{{ formatDate(item.created_time) }} 发布</div>
            <div class="confirmButton" @click="toOtherItem(item)">查看详情</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { expert } from "@/api/service";
import { followExpert, cancelFollowExpert, followStatus } from "@/api/my";
import { Message } from "element-ui";

export default {
  name: "expertDetail",
  data() {
    return {
      hide: true,
      detailData: {},
      detailId: "",
      followStatus: false,
      otherList: [],
    };
  },
  methods: {
    getData(val) {
      this.detailData = val;
      this.detailId = val.id;
      this.$store.state.logged && this.checkStatus();
      const params = {
        page_num: 1,
        page_size: 5,
        name: "",
        city: "",
        domain: "",
      };
      expert(params).then((res) => {
        if (res.state === 0) {
          this.otherList = res.data.row_list
            .filter((item) => {
              return item.name !== this.detailData.name;
            })
            .slice(0, 3);
        }
      });
    },
    checkStatus() {
      followStatus({ data_type: "expert", data_id: this.detailId }).then(
        (res) => {
          if (res.state === 0) {
            this.followStatus = res.data;
          }
        }
      );
    },
    goBack() {
      this.$router.back();
    },
    toOtherItem(val) {
      this.getData(val);
    },
    formatDate(date) {
      if (!date) return;
      return date.split(" ")[0];
    },
    follow(id) {
      if (this.$store.state.logged) {
        if (this.followStatus) {
          cancelFollowExpert({ expert_id: id }).then((res) => {
            if (res.state === 0) {
              Message.success("取消关注成功");
              this.checkStatus();
            }
          });
        } else {
          followExpert({ expert_id: id }).then((res) => {
            if (res.state === 0) {
              Message.success("关注专家成功");
              this.checkStatus();
            }
          });
        }
      } else {
        this.$store.commit("setShowLogin", true);
      }
    },
    isValid(val) {
      return val !== null && val !== undefined;
    },
  },
  mounted() {
    if (!this.$route.query.data) {
      this.$router.back();
    } else {
      this.getData(JSON.parse(this.$route.query.data));
    }
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: calc(100% - 640px);
  height: auto;
  padding: 142px 320px 70px;
  background: #f3f8fd;
  color: #303133;
  .backButton {
    margin-bottom: 37px;
    text-align: left;
    display: flex;
    align-items: center;
    color: #00d6cb;
    font-size: 14px;
    cursor: pointer;
    .back-icon {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }
  }
  .itemBox {
    // background:#FAFAFB;
    // width: 100%;
    // box-sizing: border-box;
    // padding: 16px;
    height: auto;
  }
}
.headTop {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  overflow: hidden;
}
.headTop .headImg {
  width: 388px;
  height: 219px;
  margin-right: 26px;
  background: #f3f3f3;
  img {
    width: 100%;
    height: 100%;
  }
}
.headTop .headDesc {
  width: calc(100% - 476px);
  .detail-name {
    font-size: 24px;
    font-weight: 850;
    text-align: left;
    margin-bottom: 30px;
  }
}
.content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 16px;
  .article {
    width: 73%;
    box-sizing: border-box;
    padding: 34px 24px;
    background: #fff;
    border-radius: 4px;
    text-align: left;
    overflow: hidden;
    h5 {
      height: 50px;
      line-height: 50px;
    }
    p {
      line-height: 32px;
      margin-bottom: 30px;
    }
  }
  .others {
    width: 25%;
    text-align: left;
    .demandItem {
      background: #fff;
      box-sizing: border-box;
      padding: 24px;
      border-radius: 4px;
      overflow: hidden;
      margin-bottom: 16px;
      .cover {
        width: 100%;
        height: 140px;
        background: #f3f3f3;
        margin-bottom: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        font-weight: bold;
        line-height: 38px;
      }
      .subTitle {
        font-size: 14px;
        color: #909399;
        line-height: 28px;
      }
      .time {
        font-size: 12px;
        color: #909399;
      }
      .confirmButton {
        border-top: 1px solid #eeeeee;
        text-align: center;
        font-size: 12px;
        padding-top: 24px;
        margin-top: 16px;
        cursor: pointer;
      }
      .el-tag {
        margin: 16px 0;
      }
    }
  }
}
.collectButton {
  padding: 6px 30px;
  border-radius: 4px;
  border: 1px solid #00d6cb;
  background-color: #00d6cb;
  color: #fff;
  text-align: center;
  overflow: hidden;
  margin-top: 30px;
  cursor: pointer;
  &.plain {
    color: #00d6cb;
    background-color: #fff;
  }
}
// 重写 description
.el-descriptions {
  width: 100%;
}
/deep/.el-descriptions__header {
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.10417rem;
  text-align: left;
}
/deep/.myLabel {
  position: absolute;
  display: none;
  opacity: 0;
}
/deep/.myLabel::after {
  content: "";
  display: block;
}
/deep/.phone {
  font-size: 16px;
  font-weight: 850;
  margin-top: 30px;
  &.hide {
    display: none;
  }
}
// To deal with rich-text image over size issue
/deep/img {
  max-width: 100%;
}
</style>
