<template>
  <div id="app">
    <Header :headerStyle="headerStyle" @openLogin="openLogin"></Header>
    <router-view v-if="isRouterAlive"></router-view>
    <Footer></Footer>
    <!-- <div class="top_btn" @click="scrollTop" v-show="showBtn">
      <img src="./views/content/images/组 364.png" alt="" />
    </div> -->
    <SideMenu v-show="showBtn"></SideMenu>
    <Login v-if="showLogin" @close="closeLogin"></Login>
  </div>
</template>

<script>
import Header from "./views/header/header.vue";
import Footer from "./views/footer/footer.vue";
import SideMenu from "./views/sideMenu/sideMenu.vue";
import Login from "./views/login/login.vue";
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      headerStyle: "transparent",
      showBtn: false,
      isRouterAlive: true,
    };
  },
  components: {
    Header,
    Footer,
    SideMenu,
    Login,
  },
  mounted() {
    if (this._isMobile()) {
      window.location.href = "https://m.birvice.com";
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    // 回到顶部
    scrollTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.handleScroll();
    },
    // 是否滚动
    handleScroll() {
      let scrollY = document.documentElement.scrollTop;
      // console.log(scrollY);
      if (scrollY === 0 && this.$route.path === "/") {
        this.showBtn = false;
        this.headerStyle = "transparent";
      } else {
        this.showBtn = true;
        this.headerStyle = "white";
      }
    },
    openLogin() {
      this.$store.commit("setShowLogin", true);
    },
    closeLogin() {
      this.$store.commit("setShowLogin", false);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.path !== "/") {
        this.headerStyle = "white";
      } else {
        this.headerStyle = "transparent";
      }
      this.scrollTop();
    },
  },
  computed: {
    showLogin() {
      return this.$store.state.showLogin;
    },
  },
};
</script>

<style lang="scss">
/* 整个滚动条 */
::-webkit-scrollbar {
  padding-right: 10px;
  /* 对应纵向滚动条的宽度 */
  width: 8px;
  /* 对应横向滚动条的宽度 */
  height: 8px;
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  border-radius: 32px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 32px;
}

.el-pagination.is-background .el-pager li:not(.disabled) {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #fff !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #00d6cb !important;
  background-color: #fff !important;
  outline: 1px solid #00d6cb;
}
.el-pagination.is-background .el-pager li:hover {
  color: #00d6cb !important;
}
//上一页和下一页的样式
.el-pagination.is-background .btn-next {
  background-color: #fff !important;
  width: 40px;
  height: 40px;
}
.el-pagination.is-background .btn-prev {
  background-color: #fff !important;
  width: 40px;
  height: 40px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
html {
  // scroll-behavior: smooth;
  position: relative;
}
.top_btn {
  position: fixed;
  bottom: 120px;
  right: 20px;
  cursor: pointer;
  width: 48px;
  height: 48px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>

<style lang="scss" scoped>
/deep/.el-button--default{
  &:active{
    border-color: #00d6cb;
    color: #00d6cb;
    background-color: #fff;
  }
}
/deep/.el-button--primary {
  background-color: #00d6cb;
  border-color: #00d6cb;
  &:active {
    border-color: #00d6cb;
    background-color: #00d6cb;
    opacity: 0.8;
  }
}
</style>
