<template>
  <div
    class="aboutUs"
    v-loading="loading"
    element-loading-text="登录中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- <div class="bg"></div> -->
    <div class="mask">
      <!-- <div class="close" @click="closeMask"></div>
          <img src="./img/路径 8344.png" alt="" />
        </div> -->
      <div class="mask_Box">
        <h2>登录蜂鸟</h2>
        <div class="long" v-if="isPhone">
          <span>手机号<i style="color: #ff5931">*</i></span
          ><input
            type="text"
            placeholder="请输入手机号(中国大陆)"
            v-model="loginForm.phone"
            @focus="focus($event, loginForm.phone)"
            @blur="onblur($event, loginForm.phone)"
          />
        </div>
        <div class="long" v-else>
          <span>用户名<i style="color: #ff5931">*</i></span
          ><input
            type="text"
            placeholder="请输入用户名"
            v-model="loginForm.username"
            @focus="focus($event, loginForm.username)"
            @blur="onblur($event, loginForm.username)"
          />
        </div>

        <div class="long" v-if="isPhone">
          <span>验证码<i style="color: #ff5931">*</i></span
          ><input
            type="text"
            placeholder="请输入短信验证码"
            v-model="loginForm.verify_code"
            @focus="focus($event, loginForm.verify_code)"
            @blur="onblur($event, loginForm.verify_code)"
          />
          <button
            @click="sendCode(loginForm.verify_code)"
            :class="isSend ? 'sendBtn' : 'msgBtn'"
            :disabled="dis"
            type="primary"
          >
            <span v-if="show">发送验证码</span>
            <span v-else>{{ count }}s后重发</span>
          </button>
        </div>
        <div class="long" v-else>
          <span>密码<i style="color: #ff5931">*</i></span
          ><input
            type="password"
            placeholder="请输入密码"
            v-model="loginForm.password"
            @focus="focus($event, loginForm.password)"
            @blur="onblur($event, loginForm.password)"
          />
        </div>
        <button class="commitBtn" @click="login">登录</button>
        <span class="loginChange" @click="handleChange">账号登录</span>
      </div>
    </div>
  </div>
</template>
      
  <script>
var html = window.document.querySelector("html");
import {
  register,
  registerPhone,
  registerCode,
  loginByUserName,
} from "@/api/user";
import { Message } from "element-ui";
import store from "@/store";

export default {
  data() {
    return {
      loading: false,
      maskShow: true,
      loginForm: {
        username: "",
        phone: "",
        verify_code: "",
        password: "",
      },
      count: 0,
      dis: false,
      show: true,
      isSend: false, //按钮样式
      timer: null,
      activeSort: "",
      isPhone: false,
    };
  },
  mounted() {},
  destroyed() {},
  methods: {
    hover(e, behavior) {
      let list = document.querySelectorAll(".item");
      for (let i = 0; i < list.length; i++) {
        list[i].classList.remove("hoverClass");
      }
      e.target.classList.add("hoverClass");
    },
    cancelhover(e, behavior) {
      // e.target.classList.remove('hoverClass')
    },
    itemDetail(item) {
      this.detailData = item;
      if (item.title !== "我是技术经理人") return;
      this.maskShow = true;
      html.style.overflow = "hidden";
    },
    closeMask() {
      this.maskShow = false;
      html.style.overflow = "auto";
    },
    login() {
      this.loading = true;
      loginByUserName(this.loginForm).then((res) => {
        if (res.state === 200) {
          this.$store.state.logged = true;
          store.commit('setToken',res.data)
          this.loading = false;
          this.$router.push("/personal");
          // this.$message({
          //   type: "success",
          //   message: res.msg,
          // });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    focus(e, val) {
      e.target.style.border = "1px solid #00d6cb";
      e.target.classList.remove("change");
    },
    onblur(e, val) {
      if (!val) {
        e.target.style.border = "1px solid #ff5931";
        e.target.classList.add("change");
      } else {
        e.target.style.border = "1px solid #d1d1d1";
      }
    },
    sendCode() {
      registerPhone(this.loginForm.phone).then((res) => {
        if (res.state === 200) {
          registerCode(this.loginForm.phone).then((res) => {
            if (res.state === 200) {
              let TIME_COUNT = 60;
              if (!this.timer) {
                this.count = TIME_COUNT;
                this.isSend = true;
                this.show = false;
                this.dis = true;
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                  } else {
                    this.dis = false;
                    this.isSend = false;
                    this.show = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
            }
          });
        }
      });
    },
    handleChange() {
      this.isPhone = !this.isPhone;
      this.loginForm = this.$options.data().loginForm;
    },
    // 更新title
    changeTitle(item, index) {
      if (index == this.activeSort) return;
      this.activeSort = index;
    },
    upload() {
      console.log("上传");
    },
  },
  computed: {
    textareaLenth() {
      return this.textarea.length;
    },
  },
};
</script>
      
   <style lang="scss" scoped>
.aboutUs {
  position: relative;
  height: calc(100vh - 200px);
  .bg {
    width: 100%;
    height: 400px;
    background-image: url("./img/组\ 1254.png");
    background-size: 100% 100%;
  }
  .content {
    box-sizing: border-box;
    padding: 177px 144px 120px 144px;
    height: 635px;
    background: #f3f8fd;
    display: flex;
    justify-content: space-between;
    .item {
      width: 326px;
      height: 261px;
      padding: 77px 32px 0px 32px;
      background: #f3f8fd;
      box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.1);
      border: 2px solid #ffffff;
      display: flex;
      .text_Box {
        text-align: left;
        .icon {
          width: 56px;
          height: 6px;
        }
        span {
          display: block;
          margin-top: 16px;
          font-size: 32px;
          font-weight: bold;
          color: #303133;
        }
        p {
          margin-top: 56px;
          font-size: 16px;
          color: #606266;
        }
        button {
          display: none;
        }
      }
      .img {
        display: none;
      }
    }
    .hoverClass {
      padding: 77px 422px 0px 32px;
      width: 350px;
      height: 261px;
      background: linear-gradient(
        223deg,
        rgba(61, 131, 255, 0.1) 0%,
        rgba(1, 209, 246, 0.05) 100%
      );
      box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.1);
      border: 2px solid #ffffff;
      position: relative;
      .text_Box {
        p {
          margin-top: 20px;
        }
        button {
          display: block;
          margin-top: 30px;
          border: none;
          width: 224px;
          height: 48px;
          background: linear-gradient(270deg, #00d6cb 0%, #00a2c0 100%);
          color: #ffffff;
          cursor: pointer;
          font-size: 18px;
          transition: all 0.2s;
          &:hover {
            box-shadow: 0px 10px 10px 1px #99d1cd;
          }
          img {
            width: 20px;
            object-fit: contain;
          }
        }
      }
      .img {
        display: block;
        position: absolute;
        right: 32px;
        width: 358px;
        height: 269px;
        bottom: 126px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
  // 遮罩
  .mask {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;

    background-image: url("./img/组\ 1254.png");
    background-size: cover;
    .close {
      position: absolute;
      top: calc((100vh - 890px) / 2);
      right: 500px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .mask_Box {
      border-radius: 10px 10px 10px 10px;
      position: absolute;
      top: calc((100vh - 890px) / 2);
      left: 50%;
      top: 50%;
      margin-top: -180px;
      transform: translate(-50%, 0);
      box-sizing: border-box;
      padding: 50px 20px 20px;
      width: 594px;
      // height: 840px;
      height: max-content;
      overflow-x: hidden;
      overflow-y: hidden;
      background: #ffffff;
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 24px;
        color: #303133;
        margin-bottom: 8px;
      }
      .long {
        margin-top: 24px;
        width: 100%;
        text-align: left;
        position: relative;
        .show_words_limit {
          position: absolute;
          right: 16px;
          bottom: 10px;
          font-style: normal;
          color: #aaaaaa;
        }
        span {
          font-size: 18px;
          font-weight: bold;
        }
        input {
          margin-top: 9px;
          width: 100%;
          height: 48px;
          background: #ffffff;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #d1d1d1;
          text-indent: 1em;
          color: #303133;
          outline: none;
          &::-webkit-input-placeholder {
            text-indent: 1em;
            color: #aaaaaa;
            font-family: Avenir, Helvetica, Arial, sans-serif;
          }
        }
        .change {
          &::-webkit-input-placeholder {
            color: #ff5931 !important;
          }
        }
        .msgBtn {
          position: absolute;
          right: 8px;
          bottom: 6px;
          width: 88px;
          height: 36px;
          background: #00d6cb;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #00d6cb;
          cursor: pointer;
          span {
            font-size: 14px;
            color: #ffffff;
            line-height: 36px;
            text-align: center;
          }
        }
        .sendBtn {
          position: absolute;
          right: 8px;
          bottom: 6px;
          width: 88px;
          height: 36px;
          opacity: 0.5;
          background: #00d6cb;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #00d6cb;
          cursor: pointer;
          span {
            font-size: 14px;
            color: #ffffff;
            line-height: 36px;
            text-align: center;
          }
        }
      }
      .half {
        margin-top: 24px;
        width: 680px;
        text-align: left;
        padding-left: 56px;
        display: flex;
        justify-content: space-between;
        div {
          width: 320px;
          position: relative;
          span {
            display: block;
            margin-bottom: 9px;
            font-size: 18px;
            font-weight: bold;
          }
          input {
            width: 320px;
            height: 48px;
            background: #ffffff;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #d1d1d1;
            text-indent: 1em;
            outline: none;
            &::-webkit-input-placeholder {
              text-indent: 1em;
              color: #aaaaaa;
              font-family: Avenir, Helvetica, Arial, sans-serif;
            }
          }
          .change {
            &::-webkit-input-placeholder {
              color: #ff5931 !important;
            }
          }
          .msgBtn {
            position: absolute;
            right: 8px;
            bottom: 6px;
            width: 88px;
            height: 36px;
            background: #00d6cb;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #00d6cb;
            cursor: pointer;
            span {
              font-size: 14px;
              color: #ffffff;
              line-height: 36px;
              text-align: center;
            }
          }
          .sendBtn {
            position: absolute;
            right: 8px;
            bottom: 6px;
            width: 88px;
            height: 36px;
            opacity: 0.5;
            background: #00d6cb;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #00d6cb;
            cursor: pointer;
            span {
              font-size: 14px;
              color: #ffffff;
              line-height: 36px;
              text-align: center;
            }
          }
        }
      }
      .commitBtn {
        display: block;
        width: 100%;
        height: 56px;
        background: #00d6cb;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #00d6cb;
        margin: 0 auto;
        margin-top: 32px;
        font-size: 18px;
        color: #ffffff;
        cursor: pointer;
        margin-bottom: 20px;
      }
      .loginChange {
        cursor: pointer;
        font-size: 16px;
        &:hover {
          color: #00d6cb;
        }
      }
    }
  }
}
</style>