var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"demand"},[_c('span',{staticClass:"title"},[_vm._v("链接创新成果与产业需求")]),_c('div',{staticClass:"content_Box"},[_c('div',{staticClass:"left_box"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('button',{on:{"click":_vm.releaseResults}},[_vm._v("发布成果")]),_c('div',{staticClass:"left_title"},[_vm._v("加速创新成果转化")])]),_vm._m(3),_c('div',{staticClass:"right_box"},[_vm._m(4),_vm._m(5),_vm._m(6),_c('button',{on:{"click":_vm.releaseDemand}},[_vm._v("发布需求")]),_c('div',{staticClass:"right_title"},[_vm._v("助力企业创新升级")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_item"},[_c('div',[_c('img',{attrs:{"src":require("../images/组 975.png"),"alt":""}})]),_c('div',[_vm._v("资料完善")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_item"},[_c('div',[_c('img',{attrs:{"src":require("../images/组 968.png"),"alt":""}})]),_c('div',[_vm._v("技术评估")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left_item"},[_c('div',[_c('img',{attrs:{"src":require("../images/组 970.png"),"alt":""}})]),_c('div',[_vm._v("转化指导")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mid_box"},[_c('img',{attrs:{"src":require("../images/组 1073.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right_item"},[_c('div',[_c('img',{attrs:{"src":require("../images/组 972.png"),"alt":""}})]),_c('div',[_vm._v("专人对接")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right_item"},[_c('div',[_c('img',{attrs:{"src":require("../images/组 973.png"),"alt":""}})]),_c('div',[_vm._v("需求评估")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right_item"},[_c('div',[_c('img',{attrs:{"src":require("../images/组 974.png"),"alt":""}})]),_c('div',[_vm._v("方案征集")])])
}]

export { render, staticRenderFns }