/* ========== Service API ========== */
import request from "@/utils/request";
import Vue from "vue";
var vm = new Vue();

// Get category list
export function category(params) {
  return request({
    url: vm.$BASE_URL + "/Service/category",
    method: "get",
    params,
  });
}

// Get request pool list
export function req(data) {
  return request({
    url: vm.$BASE_URL + "/Service/req",
    method: "post",
    data,
  });
}

// Get expert pool list
export function expert(data) {
  return request({
    url: vm.$BASE_URL + "/Service/expert",
    method: "post",
    data,
  });
}

// Get technology pool list
export function ta(data) {
  return request({
    url: vm.$BASE_URL + "/Service/ta",
    method: "post",
    data,
  });
}

// Login by account and password
export function loginByPwd(data) {
  return request({
    url: vm.$BASE_URL + "/Service/memberLogin",
    method: "post",
    data,
  });
}

// Login by account and verify code
export function loginByCode(data) {
  return request({
    url: vm.$BASE_URL + "/Service/smsLogin",
    method: "post",
    data,
  });
}

// Sign in for a company member
export function signInForCompany(data) {
  return request({
    url: vm.$BASE_URL + "/Service/memberCompanyReg",
    method: "post",
    data,
  });
}

// Sign in for a expert member
export function signInForExpert(data) {
  return request({
    url: vm.$BASE_URL + "/Service/memberExpertReg",
    method: "post",
    data,
  });
}

// Sign in for a manager member
export function signInForManager(data) {
  return request({
    url: vm.$BASE_URL + "/Service/memberManagerReg",
    method: "post",
    data,
  });
}

// Get sms verify code
export function sms(params) {
  return request({
    url: vm.$BASE_URL + "/Service/sms",
    method: "get",
    params,
  });
}

// Publish a request
export function submitReq(data) {
  return request({
    url: vm.$BASE_URL + "/Service/submitReq",
    method: "post",
    data,
  });
}

// Publish a achievement
export function submitTa(data) {
  return request({
    url: vm.$BASE_URL + "/Service/submitTa",
    method: "post",
    data,
  });
}
