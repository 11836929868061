<template>
  <div class="continer">
    <div class="qrcode-wrap" :class="showShare ? 'show' : 'hide'">
      <div class="qrcode-title">
        <img
          src="@/assets/img/homePage/home_qr_title.png"
          alt=""
          class="title-icon"
          @click="copySite"
        />
        <span class="title-text">复制当前页面链接</span>
      </div>
      <img
        src="@/assets/img/homePage/qrcode_contact.jpg"
        alt=""
        class="qrcode"
      />
    </div>
    <div class="publish-wrap" :class="showPublish ? 'show' : 'hide'">
      <div class="publish-item" @click="toPublish('ta')">
        <img
          src="@/assets/img/homePage/home_pub_ta.png"
          alt="发布成果"
          class="item-icon"
        />
        <span>发布成果</span>
      </div>
      <div class="publish-item" @click="toPublish('req')">
        <img
          src="@/assets/img/homePage/home_pub_req.png"
          alt="发布需求"
          class="item-icon"
        />
        <span>发布需求</span>
      </div>
    </div>
    <div class="menu-wrap">
      <div class="menu-item bg-orange" @click="publish">
        <img
          class="item-icon"
          src="@/assets/img/homePage/home_side_publish.png"
          alt="发布"
        />
        <span class="item-label">快速发布</span>
      </div>
      <div class="menu-item" :class="showShare ? 'bg-gray' : ''" @click="share">
        <img
          class="item-icon"
          src="@/assets/img/homePage/home_side_share.png"
          alt="分享"
        />
        <span class="item-label">分享</span>
      </div>
      <div class="menu-item" @click="scrollTop">
        <img
          class="item-icon"
          src="@/assets/img/homePage/home_side_top.png"
          alt="顶部"
        />
        <span class="item-label">顶部</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showShare: false,
      showPublish: false,
    };
  },
  methods: {
    publish() {
      this.showPublish = !this.showPublish;
      this.showShare = false;
    },
    toPublish(type) {
      const url = type === "ta" ? "/releaseResult" : "/releaseDemand";
      this.$router.push(url);
    },
    share() {
      this.showShare = !this.showShare;
      this.showPublish = false;
    },
    copySite() {
      if (document.execCommand("Copy")) {
        var inputEle = document.createElement("input");
        inputEle.setAttribute("readonly", "readonly");
        inputEle.value = window.location.href;
        document.body.appendChild(inputEle);
        inputEle.select();
        document.execCommand("Copy");
      } else {
      }
    },
    scrollTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.continer {
  position: fixed;
  width: 292px;
  height: 316px;
  bottom: 100px;
  right: 12px;
  z-index: 10000;
  box-sizing: border-box;
  .qrcode-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 244px;
    box-sizing: border-box;
    padding: 20px;
    z-index: 999999;
    background-image: url("../../assets/img/homePage/home_qr_wrap.png");
    background-size: cover;

    .qrcode-title {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 14px;
      padding-bottom: 14px;
      border-bottom: 1px solid #e5e5e5;
      .title-icon {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .qrcode {
      width: 160px;
      height: 160px;
      margin-top: 14px;
    }
  }

  .publish-wrap {
    position: absolute;
    top: 70px;
    right: 60px;
    width: 120px;
    height: 80px;
    padding: 4px 30px 4px 14px;
    box-sizing: border-box;
    z-index: 999999;
    background-image: url("../../assets/img/homePage/home_pub_wrap.png");
    background-size: cover;

    .publish-item {
      width: 100%;
      height: 36px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        color: #00d6cb;
      }
      .item-icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }
    .publish-item + .publish-item {
      border-top: 1px solid #e5e5e5;
    }
  }
  .menu-wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    .menu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 56px;
      height: 56px;
      background: #ffffff;
      color: #303133;
      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
      margin-bottom: 16px;

      &.bg-orange {
        background: #ffc331;
        color: #ffffff;
      }
      &.bg-gray {
        background: #ececec;
      }
      .item-icon {
        width: 20px;
        height: 20px;
        margin-bottom: 4px;
      }
      .item-label {
        width: 100%;
        font-size: 12px;
      }
    }
  }
}
.show {
  // opacity: 1;
  display: block;
}
.hide {
  // opacity: 0;
  display: none;
}
</style>
