<template>
  <div
    class="background_box"
    :style="{ backgroundImage: 'url(' + bannerData.banner_url + ')' }"
  >
    <div class="search_box">
      <div class="search_title">
        <span
          :class="item.name === activeSort ? 'activeClass' : ''"
          @click="changeSort(item, index)"
          v-for="(item, index) in serachSort"
          :key="index"
          >{{ item.name }}</span
        >
      </div>
      <div class="search_input">
        <input v-model="name" type="text" placeholder="输入您想要的关键词" />
        <button @click="search(activeSort)">{{ activeSort }}</button>
      </div>
    </div>
    <div class="data_box">
      <div
        class="data_item"
        v-for="(item, index) in bannerData.adv_list"
        :key="index"
      >
        <div>
          <img :src="item.pic_url" :alt="item.introduction" />
        </div>
        <div>
          <span>{{ item.quantity }}</span>
          <p>{{ item.introduction }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { banner } from "@/api/index";

export default {
  data() {
    return {
      bannerData: {},
      count: 1,
      name: "",
      serachSort: [
        { id: 1, name: "找成果" },
        { id: 1, name: "找需求" },
        { id: 1, name: "找专家" },
      ],
      activeSort: "",
    };
  },
  methods: {
    getData() {
      banner().then((res) => {
        if (res.state === 0) {
          this.bannerData = res.data;
        }
      });
    },
    handleC() {
      console.log(this.count);
      getcarlist();
    },
    changeSort(item, index) {
      if (item.name == this.activeSort) return;
      // 更新当前活跃项
      this.activeSort = item.name;
    },
    search(type) {
      if (!this.name) return;
      let path = "";
      switch (type) {
        case "找成果":
          path = "/technology";
          break;
        case "找专家":
          path = "/expert";
          break;
        case "找需求":
          path = "/demand";
          break;
        default:
          return;
      }
      this.$router.push({ path, query: { name: this.name } });
    },
  },
  mounted() {
    this.getData();
    this.activeSort = this.serachSort[0].name;
  },
};
</script>

<style lang="scss" scoped>
.background_box {
  width: 100%;
  height: 920px;
  background-size: 100% 100%;
  position: relative;
  .search_box {
    // width: 580px;
    height: 100px;
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    .search_title {
      display: flex;
      justify-content: flex-start;
      span {
        padding: 19px 40px 19px 0px;
        text-align: left;
        font-size: 18px;
        color: #ffffff;
        cursor: pointer;
      }
      .activeClass {
        color: #00d6cb;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 22px;
          border: 5px solid transparent;
          border-bottom: 8px solid #fff;
        }
      }
    }
    .search_input {
      display: flex;
      input {
        width: 640px;
        height: 56px;
        background: rgba(#fff, 0.1);
        border: 1px solid rgba(#fff, 0.5);
        box-sizing: border-box;
        outline: none;
        color: #fff;
        font-size: 24px;
        padding-left: 20px;
        // filter: blur(1px);
        backdrop-filter: blur(30px);
      }
      input::input-placeholder {
        font-size: 14px;
        color: rgba(#fff, 0.5);
      }
      input::-webkit-input-placeholder {
        //兼容WebKit browsers（Chrome的内核）
        font-size: 14px;
        color: rgba(#fff, 0.5);
      }
      input::-moz-placeholder {
        //Mozilla Firefox 4 to 18
        font-size: 14px;
        color: rgba(#fff, 0.5);
      }
      input::-moz-placeholder {
        //Mozilla Firefox 19+
        font-size: 14px;
        color: rgba(#fff, 0.5);
      }
      input::-ms-input-placeholder {
        //Internet Explorer 10+
        font-size: 14px;
        color: rgba(#fff, 0.5);
      }

      button {
        width: 160px;
        height: 56px;
        background: linear-gradient(180deg, #00d6cb 0%, #00a2c0 100%);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        border: 1px solid #f8f8f8;
        color: #ffffff;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .data_box {
    width: 100%;
    height: 120px;
    position: absolute;
    bottom: 0;
    background-color: rgba(#001d23, 0.5);
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 100px;
    .data_item {
      width: 340px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      // &:not(:last-child)::after {
      //   content: "";
      //   position: absolute;
      //   right: 0;
      //   width: 0px;
      //   height: 64px;
      //   opacity: 0.5;
      //   outline: 1px solid #ffffff;
      // }
      div:nth-of-type(1) {
        width: 57px;
        height: 57px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
      div:nth-of-type(2) {
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span {
          font-size: 24px;
          color: #00d6cb;
          text-align: left;
        }
        p {
          font-size: 16px;
          color: #ffffff;
          text-align: left;
        }
      }
    }
  }
}
</style>
