<template>
  <div class="service">
    <span>核心服务</span>
    <div class="content_box">
      <div
        class="left_box"
        :class="index === activeIndex ? 'activeItem' : ''"
        v-for="(item, index) in serviceData"
        :key="index"
      >
        <div class="img_box">
          <img :src="item.pic_url" alt="" />
        </div>
        <div class="text_box">
          <p>{{ item.name }}</p>
          <p>
            {{ item.introduction }}
          </p>
        </div>
      </div>
      <ul class="right_box">
        <li
          :class="index === activeIndex ? 'activeClass' : ''"
          @mouseenter="changeSort(item, index)"
          v-for="(item, index) in serviceData"
          :key="index"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <!-- <div class="bottom_box">
      <div class="bottom_content_box">
        <div class="box_item" v-for="(item, index) in iconList" :key="index">
          <img :src="item.img" alt="图片加载失败" />
          <p class="item_text">{{ item.text }}</p>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { service } from "@/api/index";
export default {
  data() {
    return {
      iconList: [
        { img: require("../images/组 978.png"), text: "一键提交需求" },
        { img: require("../images/组 981.png"), text: "信息完善归类" },
        { img: require("../images/组 982.png"), text: "分发至技术团队" },
        { img: require("../images/组 984.png"), text: "技术团队响应接单" },
      ],
      activeIndex: "",
      serviceData: [],
    };
  },
  mounted() {
    this.activeIndex = 0;
    this.getData();
  },
  methods: {
    getData() {
      service().then((res) => {
        if (res.state === 0) {
          this.serviceData = res.data;
        }
      });
    },
    changeSort(item, index) {
      if (index == this.activeIndex) return;
      // 更新当前活跃项
      this.activeIndex = index;
    },
  },
  watch: {
    serviceContent: {
      handler(newVal, oldVal) {},
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.service {
  // width: 1920px;
  background-image: url("../images/组 1074.png");
  background-size: 100% 100%;
  height: 800px;
  padding-top: 120px;
  span {
    font-weight: bold;
    font-size: 48px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -28px;
      bottom: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      background-image: url("../images/椭圆\ 13.png");
      background-size: 100% 100%;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: -28px;
      bottom: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      background-image: url("../images/椭圆\ 13.png");
      background-size: 100% 100%;
    }
  }
}

.content_box {
  margin-top: 80px;
  width: 100%;
  padding: 0 320px;
  box-sizing: border-box;
  height: 450px;
  display: flex;
  overflow: hidden;
  .left_box {
    display: none;
    width: 953px;
    height: 430px;
    background-color: #ffffff;
    margin-right: 67px;
    .img_box {
      width: 517px;
      height: 430px;
      margin: auto 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text_box {
      width: 436px;
      height: 430px;
      box-sizing: border-box;
      padding: 0 24px 0 40px;
      background-image: url("../images/蒙版组\ 14.png");
      background-size: 250px 250px;
      background-repeat: no-repeat;
      background-position: 160px 200px;
      box-shadow: 0px 10px 10px 1px #e9efef;
      p:nth-of-type(1) {
        width: 100%;
        box-sizing: border-box;
        display: inline-block;
        font-weight: bold;
        font-size: 24px;
        text-align: left;
        padding: 40px 0 24px 80px;
      }
      p:nth-of-type(2) {
        width: 230px;
        display: inline-block;
        text-align: left;
        font-size: 16px;
        line-height: 26px;
        position: relative;
        &:before {
          content: '"';
          position: absolute;
          top: 0;
          left: -50px;
          width: 29px;
          height: 100px;
          font-size: 72px;
          font-weight: normal;
          color: #dddddd;
          line-height: 70px;
        }
        &:after {
          content: '"';
          position: absolute;
          right: -20px;
          top: 110px;
          width: 29px;
          height: 100px;
          font-size: 72px;
          font-weight: normal;
          color: #dddddd;
          line-height: 70px;
        }
      }
    }
  }
  .activeItem {
    display: flex;
  }
  .right_box {
    width: 260px;
    font-size: 16px;
    li {
      height: 71px;
      line-height: 71px;
      font-weight: bold;
      position: relative;
      text-indent: 18%;
      text-align: left;
      cursor: pointer;
      background: #fff;
      border-bottom: 1px solid #ececec;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translate(-0%, -50%);
        width: 10px;
        height: 10px;
        background-color: #ececec;
      }
      &:after {
        content: "";
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-right: 6px solid #00a2c0;
        left: -10px;
        top: 50%;
        transform: translate(-0%, -50%);
        position: absolute;
        opacity: 0;
      }
      &:last-child {
        border: none;
      }
    }
    .activeClass {
      background: linear-gradient(273deg, #00d6cb 0%, #00a2c0 100%);
      color: #ffffff;
      &:before {
        background-color: #ffffff;
      }
      &::after {
        opacity: 1;
      }
    }
  }
}

.bottom_box {
  width: 100%;
  height: 160px;
  background-color: rgba(#88ebe6, 0.15);
  .bottom_content_box {
    width: 1100px;
    height: 130px;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:after {
      content: "";
      position: absolute;
      z-index: 11;
      width: 1100px;
      height: 2px;
      border-bottom: 1px dashed #00a2c0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .box_item {
      width: 80px;
      height: 80px;
      border-radius: 100%;
      border: 1px dashed #00a2c0;
      z-index: 12;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background-color: #ffffff;
      &:before {
        content: "";
        width: 10px;
        height: 10px;
        background-color: #00a2c0;
        border-radius: 100%;
        position: absolute;
        bottom: -5px;
        left: calc(50%-5px);
      }
      &:after {
        content: "";
      }
      p {
        width: max-content;
        position: absolute;
        bottom: -40px;
        color: #00a2c0;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
</style>
