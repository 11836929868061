<template>
  <div class="index">
    <Background></Background>
    <Structure></Structure>
    <!-- <Sort></Sort> -->
    <Demand></Demand>
    <Service></Service>
    <Static></Static>
  </div>
</template>

<script>
import * as API from "@/api/index";
import Background from "./background";
import Structure from "./structure.vue";
// import Sort from "./sort.vue";
import Demand from "./demand.vue";
import Service from "./service";
import Static from "./static";

export default {
  data() {
    return {
      bannerData: {},
      partners: [],
      serviceContent: [],
      serviceObject: [],
      bgImg: {},
      positionImg: {},
      showResult: false,
      showDemand: false,
    };
  },
  components: {
    Background,
    Service,
    Static,
    Demand,
    // Sort,
    Structure,
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // API.getImages().then((res) => {
      //   if (res.state === 200) {
      //     this.bgImg = res.data[0];
      //     this.positionImg = res.data[1];
      //   }
      // });
      // API.getPartner().then((res) => {
      //   if (res.state === 200) {
      //     this.partners = res.data;
      //   }
      // });
      // API.getServiceContent().then((res) => {
      //   if (res.state === 200) {
      //     this.serviceContent = res.data;
      //   }
      // });
      // API.getServiceObject().then((res) => {
      //   if (res.state === 200) {
      //     this.serviceObject = res.data;
      //   }
      // });
    },
    releaseResults() {
      // this.showResult = true;
    },
    releaseDemand() {
      // this.showDemand = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.index {
  position: relative;
}
.detail {
  width: 1920px;
  height: 652px;
  padding: 70px 320px;
  background: #f3f8fd;
}
</style>
>
