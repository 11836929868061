<template>
  <div class="box">
    <div class="backButton" @click="backHome">
      <img
        src="@/assets/img/public/double_left_arrow.png"
        alt=""
        class="back-icon"
      />
      返回首页
    </div>
    <div class="itemBox">
      <span class="title">发布成果</span>
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="150px"
        label-position="left"
        hide-required-asterisk
      >
        <el-form-item label="技术名称" prop="name">
          <div slot="label" class="formItemLabel">
            <div class="titleLabel">技术名称</div>
          </div>
          <div class="formItem">
            <el-input
              v-model="form.name"
              placeholder="请输入技术名称"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item label="技术领域" prop="domain">
          <div slot="label" class="formItemLabel">
            <div class="titleLabel">技术领域</div>
            <div class="otherLabel">(可多选)</div>
          </div>
          <div class="technicalFieldBox">
            <div
              :class="isDomainActive(item) ? 'active' : 'default'"
              v-for="(item, index) in domains"
              :key="index"
              @click="onDomainClick(item)"
            >
              {{ item }}
            </div>
          </div>
        </el-form-item>
        <el-form-item label="技术阶段" prop="phase">
          <div slot="label" class="formItemLabel">
            <div class="titleLabel">技术阶段</div>
            <div class="otherLabel">(单选)</div>
          </div>
          <el-radio-group v-model="form.phase">
            <el-radio
              v-for="(item, index) in phrases"
              :key="index"
              :label="item"
              >{{ item }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="上传时间" prop="time">
          <div slot="label" class="formItemLabel">
            <div class="titleLabel">上传时间</div>
          </div>
          <el-input v-model="form.time"></el-input>
        </el-form-item> -->
        <el-form-item label="联系方式" prop="contact">
          <div slot="label" class="formItemLabel">
            <div class="titleLabel">联系方式</div>
          </div>
          <el-input
            v-model="form.contact"
            placeholder="请输入联系方式"
          ></el-input>
        </el-form-item>
        <el-form-item label="所在单位" prop="org">
          <div slot="label" class="formItemLabel">
            <div class="titleLabel">所在单位</div>
          </div>
          <el-input v-model="form.org" placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item prop="cooperation">
          <div slot="label" class="formItemLabel">
            <div class="titleLabel">合作方式</div>
            <div class="otherLabel">(可多选)</div>
          </div>
          <div class="technicalFieldBox">
            <div
              :class="isCooperationActive(item) ? 'active' : 'default'"
              v-for="(item, index) in cooperations"
              :key="index"
              @click="onCooperationClick(item)"
            >
              {{ item }}
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="desc">
          <div slot="label" class="formItemLabel">
            <div class="titleLabel">技术说明</div>
          </div>
          <vue-ueditor-wrap
            v-model="form.desc"
            :config="myConfig"
          ></vue-ueditor-wrap>
        </el-form-item>
        <el-form-item prop="pic_url">
          <div slot="label" class="formItemLabel">
            <div class="titleLabel">封面上传</div>
          </div>
          <el-upload
            :action="$imgUrl + '/File/upload'"
            :headers="{ 'FILE-API-SECRET': $FILE_HEADER }"
            list-type="picture-card"
            :on-preview="handlePicturePreview"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :on-exceed="handleExceed"
            :accept="'image/*'"
            name="formFile"
            :limit="1"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
        <el-form-item>
          <el-button class="confirmButton" @click="commit">提交审核</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import VueUeditorWrap from "vue-ueditor-wrap";
import { getEnumList } from "@/api/public";
import { submitTa } from "@/api/service";

export default {
  data() {
    return {
      domains: [],
      phrases: [],
      cooperations: [],

      dialogVisible: false,
      dialogImageUrl: "",
      form: {
        name: "",
        domain: [],
        phase: "",
        contact: "",
        org: "",
        cooperation: [],
        desc: "",
      },
      rules: {
        name: [{ required: true, message: "请输入技术名称", trigger: "blur" }],
        domain: [
          { required: true, message: "请选择技术领域", trigger: "blur" },
        ],
        phase: [{ required: true, message: "请选择技术阶段", trigger: "blur" }],
        contact: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
        ],
        org: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
        cooperation: [
          { required: true, message: "请选择合作方式", trigger: "blur" },
        ],
        desc: [{ required: true, message: "请输入技术说明", trigger: "blur" }],
      },
      myConfig: {
        UEDITOR_HOME_URL: "/UEditor/",
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 工具栏是否可以浮动
        autoFloatEnabled: false,
        // 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 关闭自动保存
        enableAutoSave: true,
      },
    };
  },
  components: {
    VueUeditorWrap,
  },
  methods: {
    getDomains() {
      getEnumList({ enum_type: "技术领域" }).then((res) => {
        if (res.state === 0) {
          this.domains = res.data;
        }
      });
    },
    getPhrase() {
      getEnumList({ enum_type: "技术阶段" }).then((res) => {
        if (res.state === 0) {
          this.phrases = res.data;
        }
      });
    },
    getCooperations() {
      getEnumList({ enum_type: "合作方式" }).then((res) => {
        if (res.state === 0) {
          this.cooperations = res.data;
        }
      });
    },
    handleSuccess(res, file) {
      this.form.pic_url = `${this.$imgUrl}${res.data}`;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePicturePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleExceed(files, fileList) {
      this.$message.error("只能上传1张封面哦");
    },
    backHome() {
      this.$router.push("/");
    },
    onDomainClick(val) {
      if (this.form.domain.includes(val)) {
        let index = this.form.domain.indexOf(val);
        this.form.domain.splice(index, 1);
      } else {
        this.form.domain.push(val);
      }
    },
    onCooperationClick(val) {
      if (this.form.cooperation.includes(val)) {
        let index = this.form.cooperation.indexOf(val);
        this.form.cooperation.splice(index, 1);
      } else {
        this.form.cooperation.push(val);
      }
    },
    // 显示激活样式
    isDomainActive(val) {
      return this.form.domain.includes(val);
    },
    isCooperationActive(val) {
      return this.form.cooperation.includes(val);
    },
    commit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = { ...this.form };
          params.domain = params.domain.join(",");
          params.cooperation = params.cooperation.join(",");
          submitTa(params).then((res) => {
            if (res.state === 0) {
              this.$message.success(`发布成果${res.msg}，请等待审核通过`);
              setTimeout(() => {
                this.backHome();
              }, 3000);
            }
          });
        } else {
          return false;
        }
      });
    },
    init() {
      this.getDomains();
      this.getPhrase();
      this.getCooperations();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: calc(100% - 640px);
  height: auto;
  padding: 142px 320px 70px;
  background: #f3f8fd;
  color: #303133;
  .itemBox {
    background: #ffffff;
    width: calc(100% - 438px);
    height: auto;
    padding: 43px 219px;
    .title {
      width: max-content;
      color: #303133;
      margin: 0 auto;
      display: block;
      margin-top: 13px;
      margin-bottom: 39px;
      font-size: 32px;
      font-weight: bold;
    }
    .el-from-item {
      position: relative;
      overflow: hidden;
    }
    .formItemLabel {
      display: flex;
      position: absolute;
      .titleLabel {
        font-size: 18px !important;
        font-weight: bold;
      }
      .titleLabel::after {
        content: "*";
        color: #f56c6c;
        font-weight: bold;
      }
      .otherLabel {
        font-size: 12px !important;
        color: #909399;
        font-size: 14px;
      }
    }
    .el-input,
    .el-input input {
      width: 100% !important;
      height: 48px !important;
      line-height: 48px !important;
    }
    .technicalFieldBox {
      display: flex;
      height: 40px;
      flex-wrap: wrap;
      align-items: center;
      .default,
      .active {
        cursor: pointer;
        position: relative;
        margin-right: 16px;
        padding: 0px 20px;
        height: 30px;
        line-height: 30px;
        background: #ffffff;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid #d1d1d1;
        overflow: hidden;
      }
      .active {
        border-color: #00d6cb;
        color: #00d6cb;
      }
      .active::after {
        position: absolute;
        content: "";
        width: 9px;
        height: 9px;
        background: #00d6cb;
        border-radius: 0px 4px 0px 8px;
        right: 0;
        top: 0;
      }
    }
    .confirmButton {
      width: 100%;
      height: 56px;
      padding: 0;
      margin: 0;
      // margin-top: 10px;
      line-height: 56px;
      text-align: center;
      background: #00d6cb;
      color: #fff;
      border: none;
    }
  }
}
.backButton {
  margin-bottom: 37px;
  text-align: left;
  display: flex;
  align-items: center;
  color: #00d6cb;
  font-size: 14px;
  cursor: pointer;
  .back-icon {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
}
// 表单重写样式
.el-form-item {
  text-align: left;
  margin-bottom: 30px;
}
.el-form-item__content {
  text-align: left !important;
}
</style>

<style>
/* // radio激活样式 */
.el-radio__input.is-checked + .el-radio__label {
  color: #28d4c1 !important;
}
/* 选中后小圆点的颜色 */
.el-radio__input.is-checked .el-radio__inner {
  background: #28d4c1 !important;
  border-color: #28d4c1 !important;
}
</style>
