/* ========== Company Dynamic API ========== */
import request from "@/utils/request";
import Vue from "vue";
var vm = new Vue();

// Get company news list
export function news(data) {
  return request({
    url: vm.$BASE_URL + "/News/news",
    method: "post",
    data,
  });
}
