<template>
  <div class="login-mask">
    <div class="login-wrap">
      <div class="login-title">欢迎登录蜂鸟智造官网</div>
      <div class="login-tabs">
        <div
          class="tab-item"
          :class="type === 'pwd' ? 'active' : ''"
          @click="tabChange('pwd')"
        >
          账号密码登录
        </div>
        <div
          class="tab-item"
          :class="type === 'code' ? 'active' : ''"
          @click="tabChange('code')"
        >
          短信验证登录
        </div>
      </div>
      <div v-show="type === 'code'" class="login-content">
        <el-form
          :model="codeForm"
          :rules="codeRules"
          ref="codeForm"
          label-position="left"
          label-width="90px"
        >
          <el-form-item label="账号" prop="user_mobile">
            <el-input
              v-model="codeForm.user_mobile"
              autocomplete="off"
              placeholder="请输入账号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="图形验证" prop="gcode">
            <div class="code-wrap">
              <el-input
                v-model.trim="codeForm.gcode"
                autocomplete="off"
                placeholder="请输入图形验证码"
                clearable
                :maxlength="4"
              ></el-input>
              <Gcode @drawGcode="getGcode" canvasId="login" />
            </div>
          </el-form-item>
          <el-form-item label="验证" prop="verify_code">
            <div class="code-wrap">
              <el-input
                v-model.number="codeForm.verify_code"
                autocomplete="off"
                placeholder="请输入验证码"
                clearable
                :maxlength="6"
              ></el-input>
              <el-button
                type="success"
                :disabled="disableSendCode"
                @click="sendCode"
                >{{ hasSent ? `${count}s后重发` : "发送验证码" }}</el-button
              >
            </div>
          </el-form-item>
        </el-form>
        <div class="submit" :class="agreed ? '' : 'disabled'" @click="login">
          登录
        </div>
        <div class="agree">
          <el-checkbox v-model="agreed"></el-checkbox>
          <span class="agree-text"
            >同意并阅读<span
              class="agreement-link"
              @click="openAgreement('agreement')"
              >《用户协议》</span
            >和<span class="agreement-link" @click="openAgreement('privacy')"
              >《隐私政策》</span
            ></span
          >
        </div>
      </div>
      <div v-show="type === 'pwd'" class="login-content">
        <el-form
          :model="pwdForm"
          :rules="pwdRules"
          ref="pwdForm"
          label-position="left"
          label-width="60px"
        >
          <el-form-item label="账号" prop="user_mobile">
            <el-input
              v-model="pwdForm.user_mobile"
              autocomplete="off"
              placeholder="请输入账号"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="user_pwd">
            <el-input
              v-model="pwdForm.user_pwd"
              type="password"
              autocomplete="off"
              placeholder="请输入密码"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="forget">
          <el-button type="text" @click="forgetPwd">忘记密码？</el-button>
        </div>
        <div
          class="submit without-forget"
          :class="agreed ? '' : 'disabled'"
          @click="login"
        >
          登录
        </div>
        <div class="agree">
          <el-checkbox v-model="agreed"></el-checkbox>
          <span class="agree-text"
            >同意并阅读<span
              class="agreement-link"
              @click="openAgreement('agreement')"
              >《用户协议》</span
            >和<span class="agreement-link" @click="openAgreement('privacy')"
              >《隐私政策》</span
            ></span
          >
        </div>
      </div>
      <img
        @click="close"
        src="@/assets/img/login/close_active.png"
        alt=""
        class="close-icon"
      />
      <img src="@/assets/img/login/qr_active.png" alt="" class="qr-icon" />
    </div>
    <el-dialog
      :title="agreementTitle"
      :visible.sync="showAgreement"
      width="50%"
      :modal-append-to-body="false"
      :append-to-body="true"
      center
    >
      <div v-html="agreementContent" class="agreement-content"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showAgreement = false"
          >确 认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { loginByCode, loginByPwd, sms } from "@/api/service";
import Gcode from "@/components/Gcode/Gcode.vue";
import { privacy, agreement } from "@/api/aboutus";
export default {
  inject: ["reload"],
  data() {
    const validateGcode = (rule, val, callback) => {
      if (!val) {
        return callback(new Error("请输入图形验证码"));
      } else {
        if (val.toUpperCase() !== this.gcode) {
          return callback(new Error("图形验证码不正确"));
        } else {
          return callback();
        }
      }
    };
    return {
      type: "pwd",
      agreed: false,
      hasSent: false,
      timer: null,
      count: 0,
      gcode: "",
      codeForm: {
        user_mobile: "",
        verify_code: "",
        gcode: "",
      },
      pwdForm: {
        user_mobile: "",
        user_pwd: "",
      },
      codeRules: {
        user_mobile: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        verify_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        gcode: [{ required: true, validator: validateGcode, trigger: "blur" }],
      },
      pwdRules: {
        user_mobile: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        user_pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      agreementTitle: "",
      agreementContent: null,
      showAgreement: false,
    };
  },
  methods: {
    tabChange(type) {
      this.type = type;
      this.$refs.codeForm.resetFields();
      this.$refs.pwdForm.resetFields();
    },
    close() {
      this.$refs.codeForm.resetFields();
      this.$refs.pwdForm.resetFields();
      this.$emit("close");
    },
    sendCode() {
      if (this.disableSendCode) return;
      if (this.type === "code" && this.codeForm.user_mobile) {
        sms({ user_mobile: this.codeForm.user_mobile }).then((res) => {
          if (res.state === 0) {
            let TIME_COUNT = 60;
            this.count = TIME_COUNT;
            this.hasSent = true;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.hasSent = false;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        });
      }
    },
    getGcode(val) {
      this.gcode = val;
    },
    login() {
      if (!this.agreed) return;
      if (this.type === "code") {
        this.$refs.codeForm.validate((valid) => {
          if (valid) {
            const params = { ...this.codeForm };
            loginByCode(params).then((res) => {
              if (res.state === 0) {
                this.$store.dispatch("set_token", res.data);
                this.$store.commit("getToken");
                this.reload();
              }
            });
            this.close();
          } else {
            return false;
          }
        });
      } else {
        this.$refs.pwdForm.validate((valid) => {
          if (valid) {
            const params = { ...this.pwdForm };
            loginByPwd(params).then((res) => {
              if (res.state === 0) {
                this.$store.dispatch("set_token", res.data);
                this.$store.commit("getToken");
                this.reload();
              }
            });
            this.close();
          } else {
            return false;
          }
        });
      }
    },
    forgetPwd() {},
    openAgreement(type) {
      if (type === "agreement") {
        agreement().then((res) => {
          if (res.state === 0) {
            this.agreementTitle = "用户协议";
            this.agreementContent = res.data;
            this.showAgreement = true;
          }
        });
      } else {
        privacy().then((res) => {
          if (res.state === 0) {
            this.agreementTitle = "隐私政策";
            this.agreementContent = res.data;
            this.showAgreement = true;
          }
        });
      }
    },
  },
  computed: {
    disableSendCode() {
      console.log(this.codeForm.gcode);
      console.log(this.gcode);
      return (
        this.hasSent ||
        !this.codeForm.user_mobile ||
        !this.codeForm.gcode ||
        this.codeForm.gcode.toUpperCase() !== this.gcode
      );
    },
  },
  components: {
    Gcode,
  },
};
</script>

<style lang="scss" scoped>
.login-mask {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba($color: #303133, $alpha: 0.3);
  top: 0;
  left: 0;
  z-index: 1000;
  .login-wrap {
    width: 480px;
    // height: 440px;
    padding: 40px 0;
    box-sizing: border-box;
    background-color: #fff;
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99999;
    .login-title {
      font-size: 24px;
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }
    .login-tabs {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      .tab-item {
        width: 50%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 18px;
        color: #606266;
        border-bottom: 1px solid #e5e5e5;
        cursor: pointer;
        &.active {
          border-color: #00d6cb;
          color: #303133;
          font-weight: 850;
        }
      }
    }
    .login-content {
      width: 100%;
      box-sizing: border-box;
      padding: 30px 40px 0;
      .code-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        /deep/.el-input {
          width: 178px !important;
          margin-right: 10px;
        }
        /deep/.el-button--success {
          background-color: #00d6cb;
          color: #fff;
          border-color: #00d6cb;
          flex: 1;
          &:hover {
            background-color: rgba(0, 214, 203, 0.2);
            color: #00d6cb;
          }
          &:active {
            background-color: #00d6cb;
            color: #fff;
          }
          &.is-disabled {
            background-color: rgba(0, 214, 203, 0.2);
            color: #00d6cb;
          }
        }
      }
      .forget {
        width: 100%;
        height: 40px;
        margin-top: -40px;
        display: flex;
        justify-content: flex-end;
      }
      .submit {
        width: 340px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin: 39px 0 20px auto;
        background-color: #00d6cb;
        color: #fff;
        font-size: 18px;
        border-radius: 2px;
        cursor: pointer;
        &.without-forget {
          margin-top: 0;
        }
        &:active {
          background-color: rgba(0, 214, 203, 0.2);
          color: #00d6cb;
          border-color: #00d6cb;
        }
        &.disabled {
          cursor: not-allowed;
          background-color: rgba(0, 214, 203, 0.2);
          color: #00d6cb;
          border-color: #00d6cb;
          &:active {
            background-color: rgba(0, 214, 203, 0.2);
            color: #00d6cb;
          }
        }
      }
      .agree {
        width: 340px;
        margin-left: auto;
        font-size: 14px;
        display: flex;
        align-items: center;
        .agree-text {
          margin-left: 8px;
          .agreement-link {
            cursor: pointer;
            color: #00d6cb;
            &:hover {
              opacity: 0.7;
            }
            &:active {
              opacity: 1;
            }
          }
        }
      }
    }

    .close-icon {
      widows: 32px;
      height: 32px;
      position: absolute;
      top: 10px;
      left: 10px;
      cursor: pointer;
    }
    .qr-icon {
      width: 64px;
      height: 64px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }
}
.agreement-content {
  box-sizing: border-box;
  padding: 0 10px;
}
/deep/.el-checkbox__inner {
  border-radius: 50%;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #00d6cb;
  border-color: #00d6cb;
}
/deep/.el-button--primary {
  background-color: #00d6cb;
  border-color: #00d6cb;
  &:hover {
    background-color: #00d6cb;
    border-color: #00d6cb;
    opacity: 0.8;
  }
  &:active {
    background-color: #00d6cb;
    border-color: #00d6cb;
    opacity: 1;
  }
}
</style>
