<template>
  <div class="box">
    <div class="backButton" @click="goBack">
      <img
        src="@/assets/img/public/double_left_arrow.png"
        alt=""
        class="back-icon"
      />
      <span>返回</span>
    </div>
    <div class="itemBox">
      <div class="headTop">
        <div class="headImg">
          <img :src="detailData.pic_url" alt="" />
        </div>
        <div class="headDesc">
          <div class="detail-name">{{ detailData.name }}</div>
          <div
            class="detail-status"
            :class="detailData.contact_status === '已解决' ? 'done' : ''"
          >
            <img
              v-if="detailData.contact_status === '已解决'"
              src="@/assets/img/demand/done.png"
              :alt="detailData.contact_status"
            />
            <img
              v-else
              src="@/assets/img/demand/undone.png"
              :alt="detailData.contact_status"
            />
            <span>{{ detailData.contact_status }}</span>
          </div>
          <el-descriptions :column="3" extra="">
            <el-descriptions-item label="来源地区">{{
              detailData.area
            }}</el-descriptions-item>
            <el-descriptions-item label="合作方式">{{
              detailData.cooperation
            }}</el-descriptions-item>
            <el-descriptions-item label="投资金额">{{
              formatMoney(detailData.money)
            }}</el-descriptions-item>
            <el-descriptions-item label="发布日期">{{
              formatDate(detailData.created_time)
            }}</el-descriptions-item>
            <el-descriptions-item label="截止日期">{{
              formatDate(detailData.deadline_time)
            }}</el-descriptions-item>
            <!-- <el-descriptions-item
              label-class-name="myLabel"
            ></el-descriptions-item> -->
            <el-descriptions-item
              label-class-name="myLabel"
            ></el-descriptions-item>
            <el-descriptions-item label-class-name="myLabel">
              <div
                class="collectButton"
                :class="followStatus ? 'plain' : ''"
                @click="follow(detailData.id)"
              >
                {{ followStatus ? "取消关注" : "关注需求" }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item
              label="联系方式"
              :label-class-name="hide ? 'myLabel' : 'phone'"
            >
              <div class="phone" :class="hide ? 'hide' : ''">
                {{ detailData.contact }}
              </div></el-descriptions-item
            >
          </el-descriptions>
        </div>
      </div>
      <div class="content">
        <div class="article" v-html="detailData.desc"></div>
        <div class="others">
          <div
            class="demandItem"
            v-for="(item, index) in otherList"
            :key="index"
          >
            <div class="cover">
              <img :src="item.pic_url" alt="" />
            </div>
            <div class="title">{{ item.name }}</div>
            <el-tag size="small">{{ item.cooperation }}</el-tag>
            <div class="time">{{ item.created_time }} 发布</div>
            <div class="confirmButton" @click="toOtherItem(item)">查看详情</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { req } from "@/api/service";
import { followReq, cancelFollowReq, followStatus } from "@/api/my";
import { Message } from "element-ui";

export default {
  name: "demandDetail",
  data() {
    return {
      hide: true,
      detailData: {},
      detailId: "",
      followStatus: false,
      otherList: [],
    };
  },
  methods: {
    getData(val) {
      this.detailData = val;
      this.detailId = val.id;
      this.$store.state.logged && this.checkStatus();
      const params = {
        page_num: 1,
        page_size: 5,
        name: "",
        city: "",
        cooperation: "",
      };
      req(params).then((res) => {
        if (res.state === 0) {
          this.otherList = res.data.row_list
            .filter((item) => {
              return item.name !== this.detailData.name;
            })
            .slice(0, 3);
        }
      });
    },
    // handleRemove(file, fileList) {
    //   console.log(file, fileList);
    // },
    // handlePicturePreview(file) {
    //   this.dialogImageUrl = file.url;
    //   this.dialogVisible = true;
    // },
    goBack() {
      this.$router.back();
    },
    toOtherItem(val) {
      this.getData(val);
    },
    formatDate(date) {
      if (!date) return;
      return date.split(" ")[0];
    },
    formatMoney(money) {
      let newMoney = money * 1;
      if (Number.isNaN(newMoney)) return;
      if (newMoney >= 10000) {
        newMoney = `${Math.round((newMoney / 10000) * 100) / 100}万`;
      } else if (newMoney >= 1000) {
        newMoney = `${Math.round((newMoney / 1000) * 100) / 100}千`;
      }
      return newMoney;
    },
    checkStatus() {
      followStatus({ data_type: "req", data_id: this.detailId }).then((res) => {
        if (res.state === 0) {
          this.followStatus = res.data;
        }
      });
    },
    follow(id) {
      if (this.$store.state.logged) {
        if (this.followStatus) {
          cancelFollowReq({ req_id: id }).then((res) => {
            if (res.state === 0) {
              Message.success("取消关注成功");
              this.checkStatus();
            }
          });
        } else {
          followReq({ req_id: id }).then((res) => {
            if (res.state === 0) {
              Message.success("关注需求成功");
              this.checkStatus();
            }
          });
        }
      } else {
        this.$store.commit("setShowLogin", true);
      }
    },
  },
  mounted() {
    if (!this.$route.query.data) {
      this.$router.back();
    } else {
      this.getData(JSON.parse(this.$route.query.data));
    }
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: calc(100% - 640px);
  height: auto;
  padding: 142px 320px 70px;
  background: #f3f8fd;
  color: #303133;
  .backButton {
    margin-bottom: 37px;
    text-align: left;
    display: flex;
    align-items: center;
    color: #00d6cb;
    font-size: 14px;
    cursor: pointer;
    .back-icon {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }
  }
  .itemBox {
    // background:#FAFAFB;
    // width: 100%;
    // box-sizing: border-box;
    // padding: 16px;
    height: auto;
  }
}
.headTop {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  overflow: hidden;
}
.headTop .headImg {
  width: 388px;
  height: 219px;
  margin-right: 26px;
  background: #f3f3f3;
  img {
    width: 100%;
    height: 100%;
  }
}
.headTop .headDesc {
  width: calc(100% - 476px);
  position: relative;
  .detail-name {
    width: calc(100% - 120px);
    font-size: 24px;
    font-weight: 850;
    text-align: left;
    margin-bottom: 30px;
  }
  .detail-status {
    position: absolute;
    top: 0;
    right: 20px;
    display: flex;
    align-items: center;
    color: #909399;
    font-size: 14px;
    &.done {
      color: #2b71ee;
    }
    img {
      margin-right: 8px;
    }
  }
}
.content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 16px;
  .article {
    width: 73%;
    box-sizing: border-box;
    padding: 34px 24px;
    background: #fff;
    border-radius: 4px;
    text-align: left;
    overflow: hidden;
    h5 {
      height: 50px;
      line-height: 50px;
    }
    p {
      line-height: 32px;
      margin-bottom: 30px;
    }
  }
  .others {
    width: 25%;
    text-align: left;
    .demandItem {
      background: #fff;
      box-sizing: border-box;
      padding: 24px;
      border-radius: 4px;
      overflow: hidden;
      margin-bottom: 16px;
      .cover {
        width: 100%;
        height: 140px;
        background: #f3f3f3;
        margin-bottom: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        font-weight: bold;
        line-height: 38px;
      }
      .subTitle {
        font-size: 14px;
        color: #909399;
        line-height: 28px;
      }
      .time {
        font-size: 12px;
        color: #909399;
      }
      .confirmButton {
        border-top: 1px solid #eeeeee;
        text-align: center;
        font-size: 12px;
        padding-top: 24px;
        margin-top: 16px;
        cursor: pointer;
      }
      .el-tag {
        margin: 16px 0;
      }
    }
  }
}
.collectButton {
  padding: 6px 30px;
  border-radius: 4px;
  border: 1px solid #00d6cb;
  background-color: #00d6cb;
  color: #fff;
  text-align: center;
  overflow: hidden;
  margin-top: 30px;
  cursor: pointer;
  &.plain {
    color: #00d6cb;
    background-color: #fff;
  }
}
// 重写 description
.el-descriptions {
  width: 100%;
}
/deep/.el-descriptions__header {
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.10417rem;
  text-align: left;
}
/deep/.myLabel {
  position: absolute;
  display: none;
  opacity: 0;
}
/deep/.myLabel::after {
  content: "";
  display: block;
}
/deep/.phone {
  font-size: 16px;
  font-weight: 850;
  margin-top: 30px;
  &.hide {
    display: none;
  }
}
// To deal with rich-text image over size issue
/deep/img {
  max-width: 100%;
}
</style>
