import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import "amfe-flexible/index.js";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import store from "./store";

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.name) {
    document.title = "蜂鸟智造 - " + to.name;
  }
  next();
});
// require("/mock/index.js")
Vue.prototype.$BASE_URL = process.env.VUE_APP_BASE_API;
Vue.prototype.$imgUrl = process.env.VUE_APP_FILE_URL;
Vue.prototype.$SYS_URL = process.env.VUE_APP_SYS_URL;
Vue.prototype.$FILE_HEADER = process.env.VUE_APP_FILE_HEADER;

Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
