<template>
  <div class="serviceCase">
    <div class="bg_box" :style="{ backgroundImage: 'url(' + bgImg + ')' }">
      <div class="search_Box">
        <span>服务案例</span>
        <div>
          <input type="text" placeholder="输入你想要的关键词" v-model="name" />
          <button @click="search">搜索案例</button>
        </div>
      </div>
    </div>

    <div class="content_box" v-if="!isClick">
      <div class="type_box">
        案例类型：
        <span
          :class="item === activeCategory ? 'activeIndex' : ''"
          v-for="(item, index) in categories"
          :key="index"
          @click="changeCategory(item)"
          >{{ item }}</span
        >
      </div>
      <ul class="content">
        <li
          v-for="(item, index) in cases"
          :key="index"
          @click="getDetail(item)"
        >
          <div class="img">
            <img :src="item.pic_url" alt="" />
          </div>
          <div class="text">
            <div v-html="keywordscolorful(item.name)"></div>
            <div class="data">
              <span>{{ item.created_time }}</span>
              <span
                ><i class="icon"><img src="./img/组 1111.png" alt="" /></i
                >{{ item.visit_count }}人</span
              >
            </div>
          </div>
        </li>
      </ul>
      <div class="page">
        <el-pagination
          background
          :current-page="pageNum"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="total"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </div>
    <div class="content_detail" v-if="isClick">
      <div class="left_detail">
        <div class="back_btn" @click="backToList">《 返回案例列表</div>
        <span class="left_title">{{ caseDetail.name }}</span>
        <div class="left_content">
          <div class="data_bg">
            <div>{{ caseDetail.created_time }} 发布</div>
            <img class="icon" src="./img/组 1111.png" alt="" />
            <div>{{ caseDetail.visit_count }}人</div>
          </div>
          <div class="h1" v-html="caseDetail.desc"></div>
        </div>
      </div>
      <div class="right_detail" v-loading="loading">
        <span class="right_title">其他案例</span>
        <ul>
          <li v-for="(item, index) in otherCase" :key="index">
            <div @click="getDetail(item)">
              <img :src="item.pic_url" alt="" />
            </div>
            <div>
              <p @click="getDetail(item)">{{ item.name }}</p>
              <div>
                <span>{{ item.created_time }}发布</span>
                <span
                  >{{ item.visit_count }}人<img src="./img/组 1111.png" alt=""
                /></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import { caseCategory, caseList } from "@/api/case";
import { otherBanner } from "@/api/index";

export default {
  data() {
    return {
      loading: false,
      pageNum: 1,
      pageSize: 12,
      activeCategory: "不限",
      name: "",
      isClick: false,
      categories: ["不限"],
      cases: [],
      total: 0,
      caseDetail: {},
      otherCase: [],
      bgImg: "",
    };
  },
  methods: {
    getBanner() {
      otherBanner({ page_name: "case_banner_url" }).then((res) => {
        if (res.state === 0) {
          this.bgImg = res.data;
        }
      });
    },
    getCategories() {
      caseCategory().then((res) => {
        if (res.state === 0) {
          // this.categories = this.$options.data().categories;
          this.categories = res.data;
          this.categories.unshift("不限");
        }
      });
    },
    getCases() {
      const params = {
        name: this.name,
        category: this.activeCategory === "不限" ? "" : this.activeCategory,
        page_num: this.pageNum,
        page_size: this.pageSize,
      };
      caseList(params).then((res) => {
        if (res.state === 0) {
          this.cases = res.data.row_list;
          this.total = res.data.rows_count;
        }
      });
    },
    search() {
      this.pageNum = 1;
      this.getCases();
      this.isClick = false;
    },
    pageChange(val) {
      this.pageNum = val;
      this.getCases();
    },
    getDetail(data) {
      this.caseDetail = data;
      this.otherCase = this.cases.filter((item) => {
        return item.name !== data.name;
      });
      this.isClick = true;
    },
    changeCategory(val) {
      if (val === this.activeCategory) return;
      this.activeCategory = val;
      this.pageNum = 1;
      this.getCases();
    },
    backToList() {
      this.isClick = false;
      this.getCases();
    },
    // 匹配颜色高亮
    keywordscolorful(str) {
      if (this.name === "") return str;
      let reg = new RegExp("(" + this.name + ")", "g");
      let newstr = str.replace(reg, "<font style='color:#00D6CB;'>$1</font>");
      return newstr;
    },
    init() {
      this.getBanner();
      this.getCategories();
      this.getCases();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.serviceCase {
  .bg_box {
    width: 100%;
    height: 400px;
    position: relative;
    // background-image: url("./img/矩形 387@2x.png");
    background-size: 100% 100%;
    .search_Box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      span {
        display: inline-block;
        font-size: 48px;
        color: #ffffff;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        margin-bottom: 32px;
        font-weight: bold;
      }
      div {
        width: max-content;
        height: 56px;
        display: flex;
        input {
          width: 640px;
          height: 100%;
          background: #ffffff;
          border: 1px solid #f8f8f8;
          box-sizing: border-box;
          outline: none;
          padding-left: 24px;
        }
        button {
          width: 160px;
          height: 100%;
          background: linear-gradient(180deg, #00d6cb 0%, #00a2c0 100%);
          border: 1px solid #f8f8f8;
          font-size: 20px;
          color: #ffffff;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
    }
  }

  .content_box {
    width: calc(100% - 288px);
    padding: 12px 144px;
    height: max-content;
    background: #f3f8fd;
    .type_box {
      margin-top: 48px;
      width: 100%;
      height: 96px;
      background-color: #ffffff;
      font-size: 18px;
      color: #909399;
      display: flex;
      align-items: center;
      text-indent: 58px;
      span {
        margin-left: 20px;
        padding: 3px 15px;
        font-size: 18px;
        color: #303133;
        cursor: pointer;
        text-indent: 0px;
        &:hover {
          color: #00d6cb;
        }
      }
      .activeIndex {
        background: linear-gradient(270deg, #00d6cb 0%, #00a2c0 100%);
        color: #ffffff;
      }
    }
    .content {
      height: max-content;
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;

      li {
        margin-right: calc((100% - 1560px) / 3);
        margin-top: 48px;
        width: 390px;
        height: 420px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .img {
          width: 390px;
          height: 244px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .text {
          padding: 24px;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          text-align: left;
          div {
            font-size: 18px;
            color: #303133;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .data {
            margin-top: 8px;
            display: flex;
            justify-content: space-between;
            span {
              font-size: 14px;
              color: #909399;
              position: relative;
              .icon {
                position: absolute;
                left: -30px;
                top: 2px;
                width: 16px;
                height: 10px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          P:nth-of-type(2) {
            margin-top: 20px;
            font-size: 16px;
            color: #909399;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &:hover {
          margin-top: 40px;
          box-shadow: 0px 10px 10px 1px rgba(0, 0, 0, 0.06);
          .img {
            overflow: hidden;
            img {
              transform: scale(1.2);
              transition: all 0.3s ease-in;
            }
          }
        }
      }
      li:nth-of-type(4n) {
        margin-right: 0px;
      }
    }
    .page {
      margin: 60px 0px;
    }
  }

  .content_detail {
    background-color: #f3f8fd;
    display: flex;
    justify-content: space-between;
    padding: 184px 138px 66px 138px;
    .left_detail {
      position: relative;
      width: 1058px;
      padding: 44px 80px;
      height: max-content;
      background-color: #ffffff;
      .back_btn {
        position: absolute;
        color: #00d6cb;
        font-size: 14px;
        top: -52px;
        left: 0;
        cursor: pointer;
      }
      .left_title {
        font-size: 32px;
        font-weight: bold;
        color: #303133;
      }
      .left_content {
        text-align: left;
        .data_bg {
          margin-top: 16px;
          width: 1058px;
          height: 40px;
          background: #f5f5f5;
          display: flex;
          justify-content: center;
          .icon {
            display: block;
            width: 16px;
            height: 16px;
            margin-top: 12px;
            margin-right: 6px;
            margin-left: 168px;
          }
          div {
            height: 40px;
            line-height: 40px;
            text-align: center;
            display: flex;
            color: #909399;
          }
        }
        .h1 {
          font-size: 16px;
          color: #303133;
          margin-top: 40px;
        }
      }
    }

    .right_detail {
      background-color: #ffffff;
      width: 390px;
      height: max-content;
      text-align: left;
      .right_title {
        display: inline-block;
        font-size: 20px;
        font-weight: bold;
        color: #303133;
        text-indent: 20px;
        margin-top: 30px;
      }
      ul {
        li {
          height: 130px;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          position: relative;
          margin: auto;
          &:not(:last-child)::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 18px;
            width: 354px;
            height: 1px;
            background-color: #f1f1f1;
          }
          div:nth-of-type(1) {
            width: 140px;
            height: 88px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              cursor: pointer;
              transition: all 0.5s;
              &:hover {
                transform: scale(1.2);
              }
            }
          }
          div:nth-of-type(2) {
            width: 197px;
            height: 88px;
            display: flex;
            flex-direction: column;
            p {
              height: 32px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;

              &:hover {
                cursor: pointer;
                color: #00d6cb;
              }
            }
            div {
              margin-top: 20px;
              width: 100%;
              height: 30px;
              line-height: 30px;
              font-size: 12px;
              color: #909399;
              display: flex;
              justify-content: space-between;
              span:nth-of-type(2) {
                display: inline-block;
                position: relative;
                img {
                  position: absolute;
                  top: 9px;
                  left: -20px;
                  height: 10px;
                  width: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
// To deal with rich-text image over size issue
/deep/img {
  max-width: 100%;
}
</style>
<style>
.el-pagination.is-background .btn-prev {
  background-color: #ffffff;
}
.el-pagination.is-background .el-pager li {
  background-color: #ffffff;
}
.el-pagination.is-background .btn-next {
  background-color: #ffffff;
}
</style>
