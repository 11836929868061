<template>
  <!-- 企业注册表 -->
  <div class="mask_Box">
    <h2>注册为科技企业</h2>
    <div class="long">
      <span>用户名<i style="color: #ff5931">*</i></span
      ><input
        type="text"
        placeholder="请输入用户名"
        v-model="registerForm.user_name"
        @focus="focus($event, registerForm.user_name)"
        @blur="onblur($event, registerForm.user_name)"
      />
    </div>
    <div class="half">
      <div>
        <span>手机号<i style="color: #ff5931">*</i></span
        ><input
          type="text"
          placeholder="请输入手机号(中国大陆)"
          v-model="registerForm.user_mobile"
          @focus="focus($event, registerForm.user_mobile)"
          @blur="onblur($event, registerForm.user_mobile)"
        />
      </div>
      <div>
        <span>验证码<i style="color: #ff5931">*</i></span
        ><input
          type="text"
          placeholder="请输入短信验证码"
          v-model="registerForm.verify_code"
          @focus="focus($event, registerForm.verify_code)"
          @blur="onblur($event, registerForm.verify_code)"
        />
        <button
          @click="sendCode(registerForm.verify_code)"
          :class="isSend ? 'sendBtn' : 'msgBtn'"
          :disabled="dis"
          type="primary"
        >
          <span v-if="show">发送验证码</span>
          <span v-else>{{ count }}s后重发</span>
        </button>
      </div>
    </div>
    <div class="half">
      <div>
        <span>邮箱<i style="color: #ff5931">*</i></span
        ><input
          type="text"
          placeholder="请输入邮箱账号"
          v-model="registerForm.user_email"
          @focus="focus($event, registerForm.user_email)"
          @blur="onblur($event, registerForm.user_email)"
        />
      </div>
      <div>
        <span>密码<i style="color: #ff5931">*</i></span
        ><input
          type="user_pwd"
          placeholder="设置6-18位密码"
          v-model="registerForm.user_pwd"
          @focus="focus($event, registerForm.user_pwd)"
          @blur="onblur($event, registerForm.user_pwd)"
        />
      </div>
    </div>
    <div class="long">
      <span>公司名称<i style="color: #ff5931">*</i></span
      ><input
        type="text"
        placeholder="请输入公司名称"
        v-model="registerForm.companny"
        @focus="focus($event, registerForm.companny)"
        @blur="onblur($event, registerForm.companny)"
      />
    </div>
    <div class="half">
      <div>
        <span>统一社会信用代码<i style="color: #ff5931">*</i></span
        ><input
          type="text"
          placeholder="请输入统一社会信用代码 "
          v-model="registerForm.credit_code"
          @focus="focus($event, registerForm.credit_code)"
          @blur="onblur($event, registerForm.credit_code)"
        />
      </div>
      <div>
        <span>您的职位<i style="color: #ff5931">*</i></span
        ><input
          type="text"
          placeholder="请输入您的职位"
          v-model="registerForm.job"
          @focus="focus($event, registerForm.job)"
          @blur="onblur($event, registerForm.job)"
        />
      </div>
    </div>
    <div class="half">
      <div>
        <span>所在省份<i style="color: #ff5931">*</i></span>
        <el-select
          v-model="registerForm.province"
          placeholder="请选择所在省份"
          style="width: 100%"
          size="medium"
          @change="onProvinceSelect"
          clearable
        >
          <el-option
            v-for="item in provinces"
            :key="item.id"
            :label="item.province"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span>所在城市<i style="color: #ff5931">*</i></span>
        <el-select
          v-model="registerForm.city"
          placeholder="请选择所在城市"
          style="width: 100%"
          size="medium"
          clearable
          @change="onCitySelect"
        >
          <el-option
            v-for="item in cities"
            :key="item.city"
            :label="item.city"
            :value="item.city"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="long">
      <span>单位简介<i style="color: #ff5931">*</i></span
      ><textarea
        name=""
        id="textarea"
        cols="30"
        rows="10"
        placeholder="请输入单位简介"
        minlength="1"
        maxlength="100"
        v-model="registerForm.desc"
        @focus="focus($event, registerForm.desc)"
        @blur="onblur($event, registerForm.desc)"
      ></textarea>
      <div class="show_words_limit">{{ descCount }}/100</div>
    </div>
    <button class="commitBtn" @click="register">提交审核</button>
  </div>
</template>
<script>
import { getProvinceList, getCityList } from "@/api/public";
import { signInForCompany, sms } from "@/api/service";
export default {
  data() {
    return {
      provinces: [],
      cities: [],
      registerForm: {
        user_name: "",
        user_mobile: "",
        verify_code: "",
        user_email: "",
        user_pwd: "",
        companny: "",
        credit_code: "",
        province: "",
        city: "",
        area: "",
        job: "",
        desc: "",
      },
      count: 0,
      dis: false,
      show: true,
      isSend: false, //按钮样式
      timer: null,
    };
  },
  computed: {
    descCount() {
      return this.registerForm.desc.length;
    },
  },
  methods: {
    getProvinces() {
      getProvinceList().then((res) => {
        if (res.state === 0) {
          this.provinces = res.data;
        }
      });
    },
    onProvinceSelect(val) {
      this.registerForm.city = "";
      getCityList({ province_id: val }).then((res) => {
        if (res.state === 0) {
          this.cities = res.data;
        }
      });
    },
    onCitySelect(val) {
      const province = this.provinces.find((item) => {
        return item.id === this.registerForm.province;
      }).province;
      this.registerForm.area = `${province}/${val}`;
    },
    sendCode() {
      if (!this.registerForm.user_mobile) return;
      this.$emit("showGcode");
    },
    sms() {
      sms({ user_mobile: this.registerForm.user_mobile }).then((res) => {
        if (res.state === 0) {
          let TIME_COUNT = 60;
          this.count = TIME_COUNT;
          this.isSend = true;
          this.show = false;
          this.dis = true;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.dis = false;
              this.isSend = false;
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      });
    },
    register() {
      signInForCompany(this.registerForm).then((res) => {
        if (res.state === 0) {
          this.$message.success(`科技企业注册${res.msg}`);
          this.$emit("closeMask");
          const user = {
            user_mobile: this.registerForm.user_mobile,
            user_pwd: this.registerForm.user_pwd,
          };
          this.$emit("login", user);
        } else {
          this.$message.error(res.msg);
        }
      });
      this.maskShow = false;
      html.style.overflow = "auto";
      this.logged = true;
    },
    focus(e, val) {
      e.target.style.border = "1px solid #00d6cb";
      e.target.classList.remove("change");
    },
    onblur(e, val) {
      if (!val) {
        e.target.style.border = "1px solid #ff5931";
        e.target.classList.add("change");
      } else {
        e.target.style.border = "1px solid #d1d1d1";
      }
    },
    init() {
      this.getProvinces();
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
@import "./mask.scss";
/deep/.el-select--medium {
  height: 50px;
  .el-input--suffix {
    height: 100%;
    .el-input__inner {
      height: 100%;
    }
  }
}
</style>
