<template>
  <div class="structure">
    <span class="title">从想法到产品实现过程</span>
    <div class="content_box">
      <div>
        <img src="../images/组 1069.png" alt="" @mouseenter="hover($event)"  @mouseleave="cancelHover($event)"/>
        <span>概念验证</span>
      </div>
      <div>
        <img src="../images/组 1070.png" alt="" />
        <span>需求分析</span>
        <span>DVT(设计验证)</span>
        <span>样品设计</span>
        <span>EVT(工程验证)</span>
      </div>
      <div>
        <img src="../images/组 1068.png" alt="" @mouseenter="hover($event)" @mouseleave="cancelHover($event)"/>
        <span>中试熟化</span>
      </div>
      <div>
        <img src="../images/组 1071.png" alt="" />
        <span>需求分析</span>
        <span>质量体系设计</span>
        <span>文件标准化</span>
        <span>工艺设计</span>
        <span>PVT(产品试制)</span>
        <span>供应链设计</span>
      </div>
      <div>
        <img src="../images/组 1067.png" alt="" @mouseenter="hover($event)" @mouseleave="cancelHover($event)"/>
        <span>产品交付</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    hover(e) {
      e.target.style.transform = "scale(1.2)";
      e.target.style.zIndex = "999";
    },
    cancelHover(e) {
      e.target.style.transform = "scale(1)";
    },
  },
};
</script>

<style lang="scss" scoped>
.structure {
  width: 100%;
  height: 920px;
  .title {
    display: inline-block;
    margin: 0 auto;
    margin-top: 120px;
    font-weight: bold;
    font-size: 48px;
    position: relative;
    margin-bottom: 200px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -28px;
      bottom: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      background-image: url("../images/椭圆\ 13.png");
      background-size: 100% 100%;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: -28px;
      bottom: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      background-image: url("../images/椭圆\ 13.png");
      background-size: 100% 100%;
    }
  }
  .content_box {
    width: max-content;
    margin: 0 auto;
    display: flex;
    position: relative;
    font-size: 16px;
    div:nth-of-type(1) {
      position: absolute;
      top: 57px;
      width: 144px;
      height: 144px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-right: 20px;
      z-index: 9;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.1s ease;
      }
      span {
        position: absolute;
        bottom: -44px;
        font-size: 24px;
        color: #00a2c0;
        font-weight: bold;
      }
    }
    div:nth-of-type(2) {
      position: absolute;
      top: 0;
      z-index: 9;
      width: 453px;
      height: 271.5px;
      img {
        position: absolute;
        left: 0;
        z-index: 9;
        width: 100%;
        height: 100%;
      }
      position: relative;
      span:nth-of-type(1) {
        position: absolute;
        width: 180px;
        height: 50px;
        line-height: 50px;
        top: -50px;
        left: -63px;
        background: #ffffff;
        box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.06);
        border-radius: 25px 25px 25px 25px;
        color: #303133;
      }
      span:nth-of-type(2) {
        position: absolute;
        width: 180px;
        height: 50px;
        line-height: 50px;
        top: -50px;
        left: 137px;
        background: #ffffff;
        box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.06);
        border-radius: 25px 25px 25px 25px;
        color: #303133;
      }
      span:nth-of-type(3) {
        position: absolute;
        width: 180px;
        height: 50px;
        line-height: 50px;
        bottom: -50px;
        left: -63px;
        background: #ffffff;
        box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.06);
        border-radius: 25px 25px 25px 25px;
        color: #303133;
      }
      span:nth-of-type(4) {
        position: absolute;
        width: 180px;
        height: 50px;
        line-height: 50px;
        bottom: -50px;
        left: 137px;
        background: #ffffff;
        box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.06);
        border-radius: 25px 25px 25px 25px;
        color: #303133;
      }
    }
    div:nth-of-type(3) {
      position: absolute;
      z-index: 9;
      top: 57px;
      width: 144px;
      height: 144px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-right: 7px;
      img {
        position: absolute;
        left: 0;
        z-index: 9;
        width: 100%;
        height: 100%;
        transition: all 0.1s ease;
      }
      span {
        position: absolute;
        bottom: -44px;
        font-size: 24px;
        color: #00a2c0;
        font-weight: bold;
      }
    }
    div:nth-of-type(4) {
      position: absolute;
      z-index: 9;
      width: 609px;
      height: 271.5px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      span:nth-of-type(1) {
        position: absolute;
        width: 180px;
        height: 50px;
        line-height: 50px;
        top: -50px;
        left: -63px;
        background: #ffffff;
        box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.06);
        border-radius: 25px 25px 25px 25px;
        color: #303133;
      }
      span:nth-of-type(2) {
        position: absolute;
        width: 180px;
        height: 50px;
        line-height: 50px;
        top: -50px;
        left: 137px;
        background: #ffffff;
        box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.06);
        border-radius: 25px 25px 25px 25px;
        color: #303133;
      }
      span:nth-of-type(3) {
        position: absolute;
        width: 180px;
        height: 50px;
        line-height: 50px;
        top: -50px;
        left: 337px;
        background: #ffffff;
        box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.06);
        border-radius: 25px 25px 25px 25px;
        color: #303133;
      }
      span:nth-of-type(4) {
        position: absolute;
        width: 180px;
        height: 50px;
        line-height: 50px;
        bottom: -50px;
        left: -63px;
        background: #ffffff;
        box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.06);
        border-radius: 25px 25px 25px 25px;
        color: #303133;
      }
      span:nth-of-type(5) {
        position: absolute;
        width: 180px;
        height: 50px;
        line-height: 50px;
        bottom: -50px;
        left: 137px;
        background: #ffffff;
        box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.06);
        border-radius: 25px 25px 25px 25px;
        color: #303133;
      }
      span:nth-of-type(6) {
        position: absolute;
        width: 180px;
        height: 50px;
        line-height: 50px;
        bottom: -50px;
        left: 337px;
        background: #ffffff;
        box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.06);
        border-radius: 25px 25px 25px 25px;
        color: #303133;
      }
    }
    div:nth-of-type(5) {
      position: absolute;
      top: 57px;
      z-index: 9;
      width: 144px;
      height: 144px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        position: absolute;
        z-index: 9;
        width: 100%;
        height: 100%;
        transition: all 0.1s ease;
      }
      span {
        position: absolute;
        bottom: -44px;
        font-size: 24px;
        color: #00a2c0;
        font-weight: bold;
      }
    }
  }
}
</style>