/* ========== About Us API ========== */
import request from "@/utils/request";
import Vue from "vue";
var vm = new Vue();

// Get company information & history
export function about(params) {
  return request({
    url: vm.$BASE_URL + "/AboutUs/about",
    method: "get",
    params,
  });
}

// Get company open job category list
export function jobCategory() {
  return request({
    url: vm.$BASE_URL + "/AboutUs/category",
    method: "get",
  });
}

// Get company open job list
export function jobList(data) {
  return request({
    url: vm.$BASE_URL + "/AboutUs/job",
    method: "post",
    data,
  });
}

// Get company contact information
export function contact() {
  return request({
    url: vm.$BASE_URL + "/AboutUs/contact",
    method: "get",
  });
}

// Get company environment pictures
export function env() {
  return request({
    url: vm.$BASE_URL + "/AboutUs/env",
    method: "get",
  });
}

// Get privacy file
export function privacy() {
  return request({
    url: vm.$BASE_URL + "/AboutUs/privacy",
    method: "get",
  });
}

// Get agreement file
export function agreement() {
  return request({
    url: vm.$BASE_URL + "/AboutUs/agreement",
    method: "get",
  });
}
