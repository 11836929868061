<template>
  <div class="aboutUs">
    <div class="bg" :style="{ backgroundImage: 'url(' + bgImg + ')' }"></div>
    <div class="logged_content">
      <div class="top">
        <div class="top_box">
          <div class="head">
            <el-upload
              class="avatar-uploader"
              action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <img v-else src="./img/组 1184@2x.png" alt="" />
            </el-upload>
          </div>
          <span>{{ profile.user_name }}</span>
        </div>
        <ul class="sort">
          <li
            v-for="(item, index) in sortList"
            :key="index"
            :class="index == activeSort ? 'activeSort' : ''"
            @click="changeTitle(item, index)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
      <div class="bottom">
        <!-- 个人资料 -->
        <div class="bottom_box" v-if="activeSort === 0">
          <div class="bottom_title">账号管理</div>
          <div class="manager_box">
            <div class="btn_box">
              <span>绑定邮箱</span>
              <span>
                {{ profile.user_email }}
                <span class="link" @click="openVerification('email')"
                  >更改邮箱</span
                >
              </span>
            </div>
            <div class="btn_box">
              <span>手机号码</span>
              <span>
                {{ profile.user_mobile }}
                <span class="link" @click="openVerification('mobile')"
                  >更改手机号</span
                >
              </span>
            </div>
            <button @click="openVerification('pwd')">修改密码</button>
          </div>
          <div class="splitLine"></div>
          <div class="bottom_title">活跃概况</div>
          <ul class="activeDetail">
            <li>
              <span>用户组</span>
              <span>{{ profile.member_type }}</span>
            </li>
            <li>
              <span>注册时间</span>
              <span>{{ profile.reg_time }}</span>
            </li>
            <li>
              <span>最后访问</span>
              <span>{{ profile.logined_time }}</span>
            </li>
          </ul>
        </div>
        <!-- 我的发布 -->
        <div class="bottom_box" v-if="activeSort === 1">
          <el-tabs v-model="activePublish">
            <el-tab-pane label="发布的需求" name="req">
              <div class="img_box">
                <div
                  v-for="(item, index) in dataList"
                  :key="index"
                  class="img_item"
                  @click="goDetail(item, activePublish)"
                >
                  <div class="img_top">
                    <img :src="item.pic_url" alt="" />
                  </div>
                  <div class="img_bottom">
                    <p>
                      {{ item.name }}
                    </p>
                    <p class="img_tag">
                      <el-tag
                        size="small"
                        :type="
                          item.approve_status === '已通过'
                            ? 'success'
                            : item.approve_status === '未通过'
                            ? 'danger'
                            : ''
                        "
                        effect="dark"
                      >
                        {{ item.approve_status }}
                      </el-tag>
                    </p>
                  </div>
                </div>
                <div class="upload" @click="toPublish">
                  <div class="upload_box">
                    <div class="img_upload">
                      <img src="./img/组 1185.png" alt="" />
                    </div>
                    <span>发布需求</span>
                  </div>
                </div>
              </div>
              <div class="pagination">
                <el-pagination
                  background
                  :current-page="pageNum"
                  :page-size="pageSize"
                  layout="prev, pager, next"
                  :total="dataCount"
                  @current-change="pageChange"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="发布的成果" name="ta">
              <div class="img_box">
                <div
                  v-for="(item, index) in dataList"
                  :key="index"
                  class="img_item"
                  @click="goDetail(item, activePublish)"
                >
                  <div class="img_top">
                    <img :src="item.pic_url" alt="" />
                  </div>
                  <div class="img_bottom">
                    <p>
                      {{ item.name }}
                    </p>
                    <p class="img_tag">
                      <el-tag
                        size="small"
                        :type="
                          item.approve_status === '已通过'
                            ? 'success'
                            : item.approve_status === '未通过'
                            ? 'danger'
                            : ''
                        "
                        effect="dark"
                      >
                        {{ item.approve_status }}
                      </el-tag>
                    </p>
                  </div>
                </div>
                <div class="upload" @click="toPublish">
                  <div class="upload_box">
                    <div class="img_upload">
                      <img src="./img/组 1185.png" alt="" />
                    </div>
                    <span>发布成果</span>
                  </div>
                </div>
              </div>
              <div class="pagination">
                <el-pagination
                  background
                  :current-page="pageNum"
                  :page-size="pageSize"
                  layout="prev, pager, next"
                  :total="dataCount"
                  @current-change="pageChange"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- 我的关注 -->
        <div class="bottom_box" v-if="activeSort === 2">
          <el-tabs v-model="activeFollow">
            <el-tab-pane label="关注的需求" name="req">
              <div class="img_box">
                <div
                  v-for="(item, index) in dataList"
                  :key="index"
                  class="img_item is-follow"
                  @click="goDetail(item, activeFollow)"
                >
                  <div class="img_top">
                    <img :src="item.pic_url" alt="" />
                  </div>
                  <div class="img_bottom">
                    <p>
                      {{ item.name }}
                    </p>
                    <!-- <p class="img_tag">
                      <el-tag
                        size="small"
                        :type="
                          item.approve_status === '已通过'
                            ? 'success'
                            : item.approve_status === '未通过'
                            ? 'danger'
                            : ''
                        "
                        effect="dark"
                      >
                        {{ item.approve_status }}
                      </el-tag>
                    </p> -->
                  </div>
                  <div
                    class="img_btn"
                    @click.stop="cancelFollow('req', item.id)"
                  >
                    取消关注
                  </div>
                </div>
              </div>
              <div class="pagination">
                <el-pagination
                  background
                  :current-page="pageNum"
                  :page-size="pageSize"
                  layout="prev, pager, next"
                  :total="dataCount"
                  @current-change="pageChange"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="关注的成果" name="ta">
              <div class="img_box">
                <div
                  v-for="(item, index) in dataList"
                  :key="index"
                  class="img_item is-follow"
                  @click="goDetail(item, activeFollow)"
                >
                  <div class="img_top">
                    <img :src="item.pic_url" alt="" />
                  </div>
                  <div class="img_bottom">
                    <p>
                      {{ item.name }}
                    </p>
                    <!-- <p class="img_tag">
                      <el-tag
                        size="small"
                        :type="
                          item.approve_status === '已通过'
                            ? 'success'
                            : item.approve_status === '未通过'
                            ? 'danger'
                            : ''
                        "
                        effect="dark"
                      >
                        {{ item.approve_status }}
                      </el-tag>
                    </p> -->
                  </div>
                  <div
                    class="img_btn"
                    @click.stop="cancelFollow('ta', item.id)"
                  >
                    取消关注
                  </div>
                </div>
              </div>
              <div class="pagination">
                <el-pagination
                  background
                  :current-page="pageNum"
                  :page-size="pageSize"
                  layout="prev, pager, next"
                  :total="dataCount"
                  @current-change="pageChange"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="关注的专家" name="expert">
              <div class="img_box">
                <div
                  v-for="(item, index) in dataList"
                  :key="index"
                  class="img_item is-follow"
                  @click="goDetail(item, activeFollow)"
                >
                  <div class="img_top">
                    <img :src="item.pic_url" alt="" />
                  </div>
                  <div class="img_bottom">
                    <p class="img_expert">
                      <span> {{ item.name }}</span>
                      <el-tag size="small" effect="dark">
                        {{ item.job }}
                      </el-tag>
                    </p>
                  </div>
                  <div
                    class="img_btn"
                    @click.stop="cancelFollow('expert', item.id)"
                  >
                    取消关注
                  </div>
                </div>
              </div>
              <div class="pagination">
                <el-pagination
                  background
                  :current-page="pageNum"
                  :page-size="pageSize"
                  layout="prev, pager, next"
                  :total="dataCount"
                  @current-change="pageChange"
                >
                </el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
          <!-- <div class="img_box">
            <div v-if="dataList.length < 5" class="upload_plus" @click="upload">
              <div class="upload_box">
                <div class="img_upload">
                  <img src="./img/组 1185.png" alt="" />
                </div>
                <span>发布新技术</span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="mask" v-if="maskShow" @click="maskShow = true">
      <div class="close" @click="closeMask">
        <img src="./img/路径 8344.png" alt="" />
      </div>
      <div class="mask_Box">
        <h2>注册为科技企业</h2>
        <div class="long">
          <span>用户名<i style="color: #ff5931">*</i></span
          ><input
            type="text"
            placeholder="请输入用户名"
            v-model="registerForm.username"
            @focus="focus($event, registerForm.username)"
            @blur="onblur($event, registerForm.username)"
          />
        </div>
        <div class="half">
          <div>
            <span>手机号<i style="color: #ff5931">*</i></span
            ><input
              type="text"
              placeholder="请输入手机号(中国大陆)"
              v-model="registerForm.phone"
              @focus="focus($event, registerForm.phone)"
              @blur="onblur($event, registerForm.phone)"
            />
          </div>
          <div>
            <span>验证码<i style="color: #ff5931">*</i></span
            ><input
              type="text"
              placeholder="请输入短信验证码"
              v-model="registerForm.verify_code"
              @focus="focus($event, registerForm.verify_code)"
              @blur="onblur($event, registerForm.verify_code)"
            />
            <button
              @click="sendCode(registerForm.verify_code)"
              :class="isSend ? 'sendBtn' : 'msgBtn'"
              :disabled="dis"
              type="primary"
            >
              <span v-if="show">发送验证码</span>
              <span v-else>{{ count }}s后重发</span>
            </button>
          </div>
        </div>
        <div class="half">
          <div>
            <span>邮箱<i style="color: #ff5931">*</i></span
            ><input
              type="text"
              placeholder="请输入邮箱账号"
              v-model="registerForm.email"
              @focus="focus($event, registerForm.email)"
              @blur="onblur($event, registerForm.email)"
            />
          </div>
          <div>
            <span>密码<i style="color: #ff5931">*</i></span
            ><input
              type="password"
              placeholder="设置6-18位密码"
              v-model="registerForm.password"
              @focus="focus($event, registerForm.password)"
              @blur="onblur($event, registerForm.password)"
            />
          </div>
        </div>
        <div class="half" v-if="detailData.title !== '我是技术经理人'">
          <div>
            <span>公司名称<i style="color: #ff5931">*</i></span
            ><input
              type="text"
              placeholder="请输入公司名称"
              v-model="registerForm.name"
              @focus="focus($event, registerForm.name)"
              @blur="onblur($event, registerForm.name)"
            />
          </div>
          <div>
            <span>统一社会信用代码<i style="color: #ff5931">*</i></span
            ><input
              type="text"
              placeholder="请输入统一社会信用代码 "
              v-model="registerForm.creditCodeL"
              @focus="focus($event, registerForm.creditCodeL)"
              @blur="onblur($event, registerForm.creditCodeL)"
            />
          </div>
        </div>
        <div class="half" v-if="detailData.title !== '我是技术经理人'">
          <div>
            <span>所在省份/办事处<i style="color: #ff5931">*</i></span
            ><input
              type="text"
              placeholder="请输入所在省份/办事处"
              v-model="registerForm.fdsf"
              @focus="focus($event, registerForm.fdsf)"
              @blur="onblur($event, registerForm.fdsf)"
            />
          </div>
          <div>
            <span>您的职位<i style="color: #ff5931">*</i></span
            ><input
              type="text"
              placeholder="请输入您的职位"
              v-model="registerForm.work"
              @focus="focus($event, registerForm.work)"
              @blur="onblur($event, registerForm.work)"
            />
          </div>
        </div>
        <div class="long" v-if="detailData.title !== '我是技术经理人'">
          <span>单位简介<i style="color: #ff5931">*</i></span
          ><textarea
            name=""
            id="textarea"
            cols="30"
            rows="10"
            placeholder="请输入单位简介"
            minlength="1"
            maxlength="100"
            v-model="registerForm.info"
            @focus="focus($event, registerForm.info)"
            @blur="onblur($event, registerForm.info)"
          ></textarea>
          <div class="show_words_limit">{{ registerForm.info.length }}/100</div>
        </div>
        <button class="commitBtn" @click="register">提交审核</button>
      </div>
    </div> -->

    <el-dialog
      title="更改邮箱"
      :visible.sync="showEmail"
      width="25%"
      :close-on-click-modal="false"
    >
      <el-form :model="emailForm">
        <el-form-item>
          <el-input
            v-model.trim="emailForm.user_email"
            placeholder="请输入新邮箱"
            clearable
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEmail = false">取 消</el-button>
        <el-button type="primary" :disabled="!canEmail" @click="submitUpdate"
          >确 认</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="更改手机号"
      :visible.sync="showMobile"
      width="25%"
      :close-on-click-modal="false"
    >
      <el-form :model="mobileForm" ref="mobileForm">
        <el-form-item>
          <el-input
            v-model.trim="mobileForm.user_mobile"
            placeholder="请输入新手机号码"
            clearable
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="verification-code-wrap">
            <el-input
              v-model="mobileForm.verify_code"
              maxlength="6"
              placeholder="请输入验证码"
              clearable
            ></el-input>
            <el-button
              :disabled="countNew > 0"
              @click="getCode(mobileForm.user_mobile, true)"
              >获取验证码
              {{ countNew > 0 ? "(" + countNew + ")" : "" }}</el-button
            >
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showMobile = false">取 消</el-button>
        <el-button type="primary" :disabled="!canMobile" @click="submitUpdate"
          >确 认</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="更改密码"
      :visible.sync="showPwd"
      width="25%"
      :close-on-click-modal="false"
    >
      <el-form :model="pwdForm" ref="pwdForm" :rules="rules">
        <el-form-item>
          <el-input
            v-model.trim="pwdForm.user_pwd"
            placeholder="请输入新密码"
            clearable
            type="password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item prop="confirm_pwd">
          <el-input
            v-model.trim="pwdForm.confirm_pwd"
            placeholder="请确认新密码"
            clearable
            type="password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showPwd = false">取 消</el-button>
        <el-button type="primary" :disabled="!canPwd" @click="submitUpdate"
          >确 认</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="身份验证"
      :visible.sync="showVerification"
      width="25%"
      :close-on-click-modal="false"
    >
      <p class="dialog-text">
        为了您的帐户安全，请验证身份。验证成功后进行下一步操作。
      </p>
      <el-form :model="verificationForm">
        <el-form-item>
          <el-input
            v-model.trim="verificationForm.user_mobile"
            disabled
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="verification-code-wrap">
            <el-input
              v-model="verificationForm.verify_code"
              maxlength="6"
              placeholder="请输入验证码"
              clearable
            ></el-input>
            <el-button
              :disabled="count > 0"
              @click="getCode(verificationForm.user_mobile)"
              >获取验证码 {{ count > 0 ? "(" + count + ")" : "" }}</el-button
            >
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showVerification = false">取 消</el-button>
        <el-button
          type="primary"
          :disabled="!canVerify"
          @click="submitVerification"
          >验 证</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
var html = window.document.querySelector("html");
// import { register, registerPhone, registerCode } from "@/api/user";
import { Message } from "element-ui";
import {
  profile,
  myReq,
  myTa,
  myFollowReq,
  myFollowExpert,
  myFollowTa,
  cancelFollowReq,
  cancelFollowExpert,
  cancelFollowTa,
  sms,
  checkSms,
  email,
  mobile,
  pwd,
  avatar,
} from "@/api/my";
import { otherBanner } from "@/api/index";

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value !== this.pwdForm.user_pwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      dataList: [
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画",
        },
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画",
        },
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画",
        },
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画",
        },
      ],
      imgData1: [
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画第三方第三方第三方打算发多少",
        },
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画",
        },
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画",
        },
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画",
        },
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画",
        },
      ],
      sortList: [
        { id: 1, title: "个人资料" },
        { id: 1, title: "我的发布" },
        { id: 1, title: "我的关注" },
      ],
      logged: false,
      listData: [
        {
          id: "",
          title: "我是科技企业",
          text: "提出技术创新需求，利用外部创新资源，有效开展产学研协同创新。",
          img: "北京 北京市 东城区建材中路29号大陆国际999号2层",
          color: "#2B71EE",
          img: require("./img/矩形 507.png"),
        },
        {
          id: "",
          title: "我是专家",
          text: "展示技术创新成果，精准对接产业资源，一站式实现科技成果转化。",
          img: "北京 北京市 东城区建材中路29号大陆国际999号2层",
          color: "#00D6CB",
          img: require("./img/2.png"),
        },
        {
          id: "",
          title: "我是技术经理人",
          text: "链接创新端与产业端，服务科技成果转化全过程，助力企业创新升级。",
          img: "北京 北京市 东城区建材中路29号大陆国际999号2层",
          color: "#FFC331",
          img: require("./img/3.png"),
        },
      ],
      active: "",
      maskShow: false,
      // registerForm: {
      //   user_type: 1,
      //   username: "",
      //   phone: "",
      //   verify_code: "",
      //   email: "",
      //   password: "",
      //   name: "",
      //   creditCodeL: "",
      //   fdsf: "",
      //   work: "",
      //   info: "",
      // },
      count: 0,
      countNew: 0,
      // dis: false,
      // show: true,
      // isSend: false, //按钮样式
      timer: null,
      timerNew: null,
      activeSort: 0,
      bgImg: "",
      profile: {},
      activeType: "",
      activePublish: "req",
      activeFollow: "req",
      pageNum: 1,
      pageSize: 8,
      dataList: [],
      dataCount: 0,
      emailForm: {
        user_email: "",
      },
      mobileForm: {
        user_mobile: "",
        verify_code: "",
      },
      pwdForm: {
        user_pwd: "",
        confirm_pwd: "",
      },
      verificationForm: {
        user_mobile: "",
        verify_code: "",
      },
      showVerification: false,
      showEmail: false,
      showMobile: false,
      showPwd: false,
      updateType: "",
      rules: {
        confirm_pwd: [{ validator: validatePass, trigger: "blur" }],
      },
    };
  },
  watch: {
    activePublish() {
      this.getDataList();
    },
    activeFollow() {
      this.getDataList();
    },
  },
  mounted() {
    if (this.$store.state.logged) {
      this.init();
    } else {
      this.$store.commit("setShowLogin", true);
    }

    // document.querySelectorAll(".item")[0].classList.add("hoverClass");
  },
  destroyed() {},
  methods: {
    async getBanner() {
      otherBanner({ page_name: "login_banner_url" }).then((res) => {
        if (res.state === 0) {
          this.bgImg = res.data;
        }
      });
    },
    async getProfile() {
      profile().then((res) => {
        if (res.state === 0) {
          this.profile = res.data;
        }
      });
    },
    async getDataList() {
      const params = {
        page_num: this.pageNum,
        page_size: this.pageSize,
      };
      switch (this.activeSort) {
        case 1:
          switch (this.activePublish) {
            case "req":
              myReq(params).then((res) => {
                if (res.state === 0) {
                  this.dataList = res.data.row_list;
                  this.dataCount = res.data.rows_count;
                }
              });
              break;
            case "ta":
              myTa(params).then((res) => {
                if (res.state === 0) {
                  this.dataList = res.data.row_list;
                  this.dataCount = res.data.rows_count;
                }
              });
              break;
            default:
              return;
          }
          break;
        case 2:
          switch (this.activeFollow) {
            case "req":
              myFollowReq(params).then((res) => {
                if (res.state === 0) {
                  this.dataList = res.data.row_list;
                  this.dataCount = res.data.rows_count;
                }
              });
              break;
            case "expert":
              myFollowExpert(params).then((res) => {
                if (res.state === 0) {
                  this.dataList = res.data.row_list;
                  this.dataCount = res.data.rows_count;
                }
              });
              break;
            case "ta":
              myFollowTa(params).then((res) => {
                if (res.state === 0) {
                  this.dataList = res.data.row_list;
                  this.dataCount = res.data.rows_count;
                }
              });
              break;
            default:
              return;
          }
          break;
        default:
          return;
      }
    },
    hover(e, behavior) {
      let list = document.querySelectorAll(".item");
      for (let i = 0; i < list.length; i++) {
        list[i].classList.remove("hoverClass");
      }
      e.target.classList.add("hoverClass");
    },
    cancelhover(e, behavior) {
      // e.target.classList.remove('hoverClass')
    },
    itemDetail(item) {
      this.detailData = item;
      if (item.title !== "我是技术经理人") return;
      this.maskShow = true;
      html.style.overflow = "hidden";
    },
    closeMask() {
      this.maskShow = false;
      html.style.overflow = "auto";
    },
    // register() {
    //   register(this.registerForm).then((res) => {
    //     this.$message.error(res.msg);
    //     if (res.state === 200) {
    //       this.$message({
    //         message: res.data,
    //         type: "success",
    //       });
    //     } else {
    //       this.$message.error(res.data);
    //     }
    //   });
    //   this.maskShow = false;
    //   html.style.overflow = "auto";
    //   this.logged = true;
    // },
    focus(e, val) {
      e.target.style.border = "1px solid #00d6cb";
      e.target.classList.remove("change");
    },
    onblur(e, val) {
      if (!val) {
        e.target.style.border = "1px solid #ff5931";
        e.target.classList.add("change");
      } else {
        e.target.style.border = "1px solid #d1d1d1";
      }
    },
    getCode(mobile, isNew = false) {
      let TIME_COUNT = 60;
      if (isNew) {
        if (!this.timerNew) {
          this.countNew = TIME_COUNT;
          this.timerNew = setInterval(() => {
            if (this.countNew > 0 && this.countNew <= TIME_COUNT) {
              this.countNew--;
            } else {
              clearInterval(this.timerNew);
              this.timerNew = null;
            }
          }, 1000);
        }
      } else {
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      }
      sms({ user_mobile: mobile });
    },
    // 更新title
    changeTitle(item, index) {
      if (index == this.activeSort) return;
      this.activeSort = index;
      this.getDataList();
    },
    upload() {
      console.log("上传");
    },
    toPublish() {
      const url =
        this.activePublish === "req" ? "/releaseDemand" : "/releaseResult";
      this.$router.push(url);
    },
    pageChange(val) {
      this.pageNum = val;
      this.getDataList();
    },
    goDetail(item, type) {
      const data = JSON.stringify(item);
      let url = "";
      switch (type) {
        case "req":
          url = "/demandDetail";
          break;
        case "expert":
          url = "/expertDetail";
          break;
        case "ta":
          url = "/technologyDetail";
          break;
        default:
          return;
      }
      this.$router.push({ path: url, query: { data } });
    },
    cancelFollow(type, id) {
      switch (type) {
        case "req":
          cancelFollowReq({ req_id: id }).then((res) => {
            if (res.state === 0) {
              Message.success("取消关注成功");
              this.getDataList();
            }
          });
          break;
        case "expert":
          cancelFollowExpert({ expert_id: id }).then((res) => {
            if (res.state === 0) {
              Message.success("取消关注成功");
              this.getDataList();
            }
          });
          break;
        case "ta":
          cancelFollowTa({ ta_id: id }).then((res) => {
            if (res.state === 0) {
              Message.success("取消关注成功");
              this.getDataList();
            }
          });
          break;
        default:
          return;
      }
    },
    openEmail() {
      this.emailForm = {
        user_email: "",
      };
      this.showEmail = true;
    },
    openMobile() {
      this.mobileForm = {
        user_mobile: "",
        verify_code: "",
      };
      this.showMobile = true;
    },
    openPwd() {
      this.pwdForm = {
        user_pwd: "",
        confirm_pwd: "",
      };
      this.showPwd = true;
    },
    openVerification(type) {
      this.updateType = type;
      this.verificationForm.user_mobile = this.profile.user_mobile;
      this.verificationForm.verify_code = "";
      this.showVerification = true;
    },
    submitVerification() {
      const params = {
        ...this.verificationForm,
      };
      checkSms(params).then((res) => {
        if (res.state === 0) {
          this.showVerification = false;
          switch (this.updateType) {
            case "email":
              this.openEmail();
              break;
            case "mobile":
              this.openMobile();
              break;
            case "pwd":
              this.openPwd();
              break;
            default:
              return;
          }
        }
      });
    },
    submitUpdate() {
      let params = {};
      switch (this.updateType) {
        case "email":
          params = { ...this.emailForm };
          email(params).then((res) => {
            if (res.state === 0) {
              Message.success("更改邮箱成功");
              this.getProfile();
            }
          });
          this.showEmail = false;
          break;
        case "mobile":
          params = { ...this.mobileForm };
          checkSms(params).then((res) => {
            if (res.state === 0) {
              mobile(params).then((response) => {
                if (response.state === 0) {
                  Message.success("更改手机号码成功");
                  this.getProfile();
                }
              });
            }
          });
          this.showMobile = false;
          break;
        case "pwd":
          params = { ...this.pwdForm };
          pwd(params).then((res) => {
            if (res.state === 0) {
              Message.success("更改密码成功");
            }
          });
          this.showPwd = false;
          break;
        default:
          return;
      }
    },
    async init() {
      await this.getBanner();
      await this.getProfile();
    },
  },
  computed: {
    textareaLenth() {
      return this.textarea.length;
    },
    canEmail() {
      return this.emailForm.user_email;
    },
    canMobile() {
      return this.mobileForm.user_mobile && this.mobileForm.verify_code;
    },
    canPwd() {
      return this.pwdForm.user_pwd && this.pwdForm.confirm_pwd;
    },
    canVerify() {
      return (
        this.verificationForm.user_mobile && this.verificationForm.verify_code
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.aboutUs {
  position: relative;
  .bg {
    width: 100%;
    height: 400px;
    // background-image: url("./img/组\ 1254.png");
    background-size: 100% 100%;
  }
  .content {
    box-sizing: border-box;
    padding: 177px 144px 120px 144px;
    height: 635px;
    background: #f3f8fd;
    display: flex;
    justify-content: space-between;
    .item {
      width: 326px;
      height: 261px;
      padding: 77px 32px 0px 32px;
      background: #f3f8fd;
      box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.1);
      border: 2px solid #ffffff;
      display: flex;
      .text_Box {
        text-align: left;
        .icon {
          width: 56px;
          height: 6px;
        }
        span {
          display: block;
          margin-top: 16px;
          font-size: 32px;
          font-weight: bold;
          color: #303133;
        }
        p {
          margin-top: 56px;
          font-size: 16px;
          color: #606266;
        }
        button {
          display: none;
        }
      }
      .img {
        display: none;
      }
    }
    .hoverClass {
      padding: 77px 422px 0px 32px;
      width: 350px;
      height: 261px;
      background: linear-gradient(
        223deg,
        rgba(61, 131, 255, 0.1) 0%,
        rgba(1, 209, 246, 0.05) 100%
      );
      box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.1);
      border: 2px solid #ffffff;
      position: relative;
      .text_Box {
        p {
          margin-top: 20px;
        }
        button {
          display: block;
          margin-top: 30px;
          border: none;
          width: 224px;
          height: 48px;
          background: linear-gradient(270deg, #00d6cb 0%, #00a2c0 100%);
          color: #ffffff;
          cursor: pointer;
          font-size: 18px;
          transition: all 0.2s;
          &:hover {
            box-shadow: 0px 10px 10px 1px #99d1cd;
          }
          img {
            width: 20px;
            object-fit: contain;
          }
        }
      }
      .img {
        display: block;
        position: absolute;
        right: 32px;
        width: 358px;
        height: 269px;
        bottom: 126px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .logged_content {
    .top {
      width: 100%;
      height: 300px;
      box-sizing: border-box;
      padding: 0 320px;
      background: #ffffff;
      position: relative;
      .top_box {
        width: max-content;
        position: absolute;
        top: -60px;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        flex-direction: column;
        justify-content: center;
        .head {
          margin: 0 auto;
          background: #fff;
          border-radius: 100%;
          width: 120px;
          height: 120px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        span:nth-of-type(1) {
          display: block;
          margin-top: 24px;
          font-size: 32px;
          color: #303133;
          font-weight: bold;
        }
        span:nth-of-type(2) {
          display: block;
          margin-top: 8px;
          color: #606266;
          font-size: 18px;
        }
      }
      .sort {
        width: 512px;
        height: 56px;
        box-sizing: border-box;
        padding: 8px;
        background-color: #f3f8fd;
        font-size: 20px;
        position: absolute;
        bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        li {
          width: 160px;
          height: 40px;
          line-height: 40px;
          cursor: pointer;
          position: relative;
          &.activeSort {
            background-color: #fff;
            font-weight: 850;
          }
        }
      }
    }
    .bottom {
      padding: 0px 320px 80px;
      background-image: url("@/assets/img/my/my-card-bg.png");
      background-size: 100% 100%;
      // background: #f3f8fd;
      .bottom_box {
        padding: 32px 0;
        width: 1280px;
        height: 440px;
        // background: #ffffff;
        text-align: left;

        .bottom_title {
          width: 100%;
          text-align: left;
          font-size: 18px;
          color: #303133;
          margin-bottom: 36px;
          position: relative;
          .more {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 16px;
            color: #2b71ee;
            img {
              margin-left: 8px;
              width: 14px;
              height: 14px;
            }
          }
        }
        .manager_box {
          width: 100%;
          position: relative;
          .btn_box {
            display: inline-block;
            text-align: left;
            width: 555px;
            margin-bottom: 56px;
            span:nth-of-type(1) {
              display: block;
              font-size: 14px;
              color: #909399;
              margin-bottom: 12px;
            }
            span:nth-of-type(2) {
              width: max-content;
              display: block;
              font-size: 16px;
              color: #303133;
              position: relative;
              line-height: 100%;
              .link {
                position: absolute;
                font-size: 16px;
                line-height: 100%;
                top: 0px;
                right: -100px;
                color: #00d6cb;
                cursor: pointer;
              }
            }
          }
          button {
            position: absolute;
            right: 0;
            color: #00d6cb;
            width: 160px;
            height: 48px;
            background: #ffffff;
            border: 1px solid #00d6cb;
            cursor: pointer;
          }
        }

        .img_box {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 30px 50px;

          .img_item {
            width: 300px;
            height: 280px;
            background: #fff;
            box-shadow: 0px 10px 10px 1px rgba(0, 0, 0, 0.06);
            cursor: pointer;
            &.is-follow {
              height: 310px;
            }

            .img_top {
              width: 300px;
              height: 170px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .img_bottom {
              height: 80px;
              padding: 16px 10px;
              box-sizing: border-box;
              display: flex;
              flex: 1;
              flex-direction: column;
              justify-content: space-between;
              p {
                width: 100%;
                text-align: left;
                font-size: 16px;
                color: #303133;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                &.img_tag {
                  text-align: right;
                }
              }
            }

            .img_btn {
              width: 160px;
              height: 32px;
              line-height: 32px;
              border: 1px solid #909399;
              font-size: 16px;
              border-radius: 4px;
              margin: 0 auto;
              text-align: center;
            }
          }

          .img_item_plus {
            margin-left: 24px;
            width: 252px;
            height: 280px;
            background: #fafbfd;
            margin-bottom: 56px;
            cursor: pointer;
            &:hover {
              box-shadow: 0px 10px 10px 1px rgba(0, 0, 0, 0.06);
            }
            .img_top {
              width: 252px;
              height: 142px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .img_bottom_plus {
              padding: 16px 10px 16px 10px;

              p {
                text-align: left;
                font-size: 16px;
                color: #303133;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .icon {
                margin-top: 16px;
                display: flex;
                justify-content: space-between;
                .icon_left {
                  text-align: center;
                  width: 72px;
                  height: 20px;
                  background: #00d6cb;
                  font-size: 14px;
                  color: #ffffff;
                }
                .icon_right {
                  font-size: 14px;
                  color: #909399;
                  span {
                    font-size: 14px;
                    color: #ffc331;
                  }
                }
              }
            }
          }

          .upload {
            width: 300px;
            height: 280px;
            background: #fafbfd;
            border: 1px dashed #d1d1d1;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .upload_box {
              width: max-content;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .img_upload {
                margin: 0 auto;
                img {
                  width: 40px;
                  object-fit: contain;
                }
              }
              span {
                font-size: 16px;
                color: #909399;
              }
            }
          }
          .upload_plus {
            margin-left: 24px;
            width: 252px;
            height: 280px;
            background: #fafbfd;
            border: 1px dashed #d1d1d1;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .upload_box {
              width: max-content;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .img_upload {
                margin: 0 auto;
                img {
                  width: 40px;
                  object-fit: contain;
                }
              }
              span {
                font-size: 16px;
                color: #909399;
              }
            }
          }
          .img_item:nth-of-type(1) {
            margin-left: 0 !important;
          }
          .img_item_plus:nth-of-type(1) {
            margin-left: 0 !important;
          }
        }
        .activeDetail {
          display: flex;
          flex-wrap: wrap;
          li {
            width: 555px;
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;
            span:nth-of-type(1) {
              font-size: 14px;
              color: #909399;
              display: block;
              margin-bottom: 8px;
            }
            span:nth-of-type(2) {
              font-size: 16px;
              color: #303133;
            }
          }
          li:nth-of-type(3) {
            width: max-content;
          }
        }
        span {
          font-size: 16px;
          color: #303133;
        }
        .num {
          display: inline-block;
          text-indent: 22px;
          font-size: 16px;
          color: #303133;
        }
        .follow {
          margin-top: 16px;
          margin-bottom: 27px;
          display: flex;
          li {
            margin-left: 32px;
            width: 80px;
            height: 32px;
            background: #ececec;
            font-size: 16px;
            color: #303133;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
          }
          li:nth-of-type(1) {
            margin-left: 0px;
          }
        }
        .splitLine {
          margin-top: 8px;
          margin-bottom: 40px;
          width: 100%;
          height: 1px;
          background-color: #dcdcdc;
        }
      }
    }
  }
  // 遮罩
  .mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background-color: rgba($color: #303133, $alpha: 0.5);
    backdrop-filter: blur(10px);
    top: 0;
    .close {
      position: absolute;
      top: calc((100vh - 890px) / 2);
      right: 500px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .mask_Box {
      border-radius: 10px 10px 10px 10px;
      position: absolute;
      top: calc((100vh - 890px) / 2);
      left: 50%;
      transform: translate(-50%, 0);
      padding: 50px 0px 0px;
      width: 794px;
      // height: 840px;
      height: max-content;
      overflow-x: hidden;
      overflow-y: hidden;
      background: #ffffff;
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 24px;
        color: #303133;
        margin-bottom: 8px;
      }
      .long {
        margin-top: 24px;
        width: 680px;
        padding-left: 56px;
        text-align: left;
        position: relative;
        .show_words_limit {
          position: absolute;
          right: 16px;
          bottom: 10px;
          font-style: normal;
          color: #aaaaaa;
        }
        span {
          font-size: 18px;
          font-weight: bold;
        }
        input {
          margin-top: 9px;
          width: 680px;
          height: 48px;
          background: #ffffff;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #d1d1d1;
          text-indent: 1em;
          color: #303133;
          outline: none;
          &::-webkit-input-placeholder {
            text-indent: 1em;
            color: #aaaaaa;
            font-family: Avenir, Helvetica, Arial, sans-serif;
          }
        }
        .change {
          &::-webkit-input-placeholder {
            color: #ff5931 !important;
          }
        }
        #textarea {
          padding: 9px 0px;
          margin-top: 9px;
          display: block;
          width: 680px;
          height: 62px;
          resize: none;
          border: 1px solid #d1d1d1;
          text-indent: 1em;
          color: #303133;
          line-height: 20px;
          outline: none;
          border-radius: 4px 4px 4px 4px;
          &::-webkit-input-placeholder {
            text-indent: 1em;
            color: #aaaaaa;
            font-family: Avenir, Helvetica, Arial, sans-serif;
          }
          .placeholder_class {
            text-indent: 1em;
            color: #ff5931;
          }
        }
      }
      .half {
        margin-top: 24px;
        width: 680px;
        text-align: left;
        padding-left: 56px;
        display: flex;
        justify-content: space-between;
        div {
          width: 320px;
          position: relative;
          span {
            display: block;
            margin-bottom: 9px;
            font-size: 18px;
            font-weight: bold;
          }
          input {
            width: 320px;
            height: 48px;
            background: #ffffff;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #d1d1d1;
            text-indent: 1em;
            outline: none;
            &::-webkit-input-placeholder {
              text-indent: 1em;
              color: #aaaaaa;
              font-family: Avenir, Helvetica, Arial, sans-serif;
            }
          }
          .change {
            &::-webkit-input-placeholder {
              color: #ff5931 !important;
            }
          }
          .msgBtn {
            position: absolute;
            right: 8px;
            bottom: 6px;
            width: 88px;
            height: 36px;
            background: #00d6cb;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #00d6cb;
            cursor: pointer;
            span {
              font-size: 14px;
              color: #ffffff;
              line-height: 36px;
              text-align: center;
            }
          }
          .sendBtn {
            position: absolute;
            right: 8px;
            bottom: 6px;
            width: 88px;
            height: 36px;
            opacity: 0.5;
            background: #00d6cb;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #00d6cb;
            cursor: pointer;
            span {
              font-size: 14px;
              color: #ffffff;
              line-height: 36px;
              text-align: center;
            }
          }
        }
      }
      .commitBtn {
        display: block;
        width: 680px;
        height: 56px;
        background: #00d6cb;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #00d6cb;
        margin: 0 auto;
        margin-top: 32px;
        font-size: 18px;
        color: #ffffff;
        cursor: pointer;
        margin-bottom: 50px;
      }
    }
  }
}
.dialog-text {
  text-align: left;
  margin-bottom: 20px;
}
.verification-code-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  .el-input {
    margin-right: 10px;
  }
}
.pagination {
  width: 100%;
  text-align: center;
  margin-top: 58px;
}
/deep/.el-tag--success {
  background-color: #00d6cb !important;
  border-color: #00d6cb !important;
}
/deep/.el-tag--dark {
  margin-left: 16px;
  color: #fff !important;
}
/deep/.el-dialog__header {
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
}
/deep/.el-dialog__body {
  padding: 20px;
  border-bottom: 1px solid #e8e8e8;
}
</style>
