<template>
  <div class="aboutUs">
    <div class="bg" :style="{ backgroundImage: 'url(' + bgImg + ')' }"></div>
    <div class="content">
      <ul class="sort">
        <li
          :class="index == activeTitle ? 'activeTitle' : ''"
          v-for="(item, index) in titleSort"
          :key="index"
          @click="changeTitle(item, index)"
        >
          {{ item.name }}
        </li>
      </ul>
      <div class="content_box">
        <div class="left_box">
          <div class="content_sort">
            <div class="sort_item_wrap">
              <span class="item_label">岗位分类：</span>
              <div class="item_val_wrap">
                <span
                  :class="item == activeJob ? 'activePort' : ''"
                  v-for="(item, index) in jobCategory"
                  :key="index"
                  @click="handleChange(item, 'job')"
                  >{{ item }}</span
                >
              </div>
            </div>
            <div class="sort_item_wrap">
              <span class="item_label">招聘类型：</span>
              <div class="item_val_wrap">
                <span
                  :class="item == activeType ? 'activePort' : ''"
                  v-for="(item, index) in typeCategory"
                  :key="index"
                  @click="handleChange(item, 'type')"
                  >{{ item }}</span
                >
              </div>
            </div>
            <div class="sort_item_wrap">
              <span class="item_label">工作地点：</span>
              <div class="item_val_wrap">
                <span
                  :class="item == activeCity ? 'activePort' : ''"
                  v-for="(item, index) in cityCategory"
                  :key="index"
                  @click="handleChange(item, 'city')"
                  >{{ item }}</span
                >
              </div>
            </div>
          </div>
          <ul class="list">
            <li
              v-for="(item, index) in jobList"
              :key="index"
              @click="getDetail(item)"
            >
              <div class="li_item">
                <div class="li_img">
                  <img src="./img/组 1164.png" alt="" />
                </div>
                <div class="li_content">
                  <div class="content_left">
                    <span>{{ item.name }}</span>
                    <span
                      :class="
                        item.recruit_type == '社会招聘'
                          ? 'numberOne'
                          : 'numberTwo'
                      "
                      >{{ item.recruit_type }}</span
                    >
                  </div>
                  <div class="content_right">
                    <span
                      >{{
                        item.salary_low ? formatNumber(item.salary_low) : "0"
                      }}
                      ~
                      {{
                        item.salary_high ? formatNumber(item.salary_high) : "0"
                      }}</span
                    >
                    <span
                      ><img src="./img/组 1147.png" alt="" />工作地点：{{
                        item.area.split("/")[1]
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="page">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="12"
              :total="total"
              :current-page="page_num"
              @current-change="handleCurrentChange"
              @prev-click="handlePrev"
              @next-click="handleNext"
            >
            </el-pagination>
          </div>
        </div>
        <!-- <div class="right_box">
          <span>关注蜂鸟公众号</span>
          <img :src="$imgUrl + publicData[6].text" alt="" />
          <p>
            {{ publicData[3].text }}
          </p>
        </div> -->
      </div>
    </div>
    <div class="mask" v-if="maskShow" @click="maskShow = false">
      <div class="mask_Box">
        <div class="close" @click="closeMask">
          <img src="./img/组 1259.png" alt="" />
        </div>
        <div class="top">
          <div class="li_img">
            <img src="./img/组 1164.png" alt="" />
          </div>
          <div class="li_content">
            <div class="content_left">
              <span>{{ detailData.name }}</span>
              <span
                :class="
                  detailData.recruit_type == '社会招聘'
                    ? 'numberOne'
                    : 'numberTwo'
                "
                >{{ detailData.recruit_type }}</span
              >
            </div>
            <div class="content_right">
              <span
                >{{ detailData.salary_low ? formatNumber(detailData.salary_low) : "0" }}
                ~
                {{
                  detailData.salary_high ? formatNumber(detailData.salary_high) : "0"
                }}</span
              >
              <span>
                <img src="./img/组 1147.png" alt="" />工作地点：{{
                  detailData.area
                }}</span
              >
            </div>
          </div>
        </div>
        <div class="middle">
          <span>联系电话：{{ detailData.phone }}</span>
          <span class="tips">简历投递：{{ detailData.email }}</span>
        </div>
        <div class="bottom">
          <div v-html="detailData.desc"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jobCategory, jobList } from "@/api/aboutus";
var html = window.document.querySelector("html");
import { otherBanner } from "@/api/index";

export default {
  data() {
    return {
      bgImg: "",
      titleSort: [
        { id: 1, name: "了解我们", url: "/aboutUs" },
        { id: 2, name: "加入我们", url: "/joinUs" },
        { id: 3, name: "联系我们", url: "contactUs" },
      ],
      maskShow: false,
      detailData: {},
      publicData: [],
      jobCategory: ["不限"],
      typeCategory: ["不限"],
      cityCategory: ["不限"],
      activeTitle: 1,
      activeJob: "不限",
      activeType: "不限",
      activeCity: "不限",
      page_num: 1,
      page_size: 12,
      jobList: [],
      total: 0,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    getBanner() {
      otherBanner({ page_name: "about_banner_url" }).then((res) => {
        if (res.state === 0) {
          this.bgImg = res.data;
        }
      });
    },
    getCategories() {
      jobCategory().then((res) => {
        if (res.state === 0) {
          this.jobCategory = ["不限"].concat(res.data["岗位分类"]);
          this.typeCategory = ["不限"].concat(res.data["招聘类型"]);
          this.cityCategory = ["不限"].concat(res.data["工作城市"]);
        }
      });
    },
    getJobs() {
      const params = {
        page_num: this.page_num,
        page_size: this.page_size,
        category: this.activeJob === "不限" ? "" : this.activeJob,
        recruit_type: this.activeType === "不限" ? "" : this.activeType,
        city: this.activeCity === "不限" ? "" : this.activeCity,
      };
      jobList(params).then((res) => {
        if (res.state === 0) {
          this.jobList = res.data.row_list;
          this.total = res.data.rows_count;
        }
      });
    },
    changeTitle(item, index) {
      if (index == this.activeTitle) return;
      // 更新title
      this.$router.replace(item.url);
    },
    handleChange(val, type) {
      switch (type) {
        case "job":
          if (val === this.activeJob) return;
          this.activeJob = val;
          break;
        case "type":
          if (val === this.activeType) return;
          this.activeType = val;
          break;
        case "city":
          if (val === this.activeCity) return;
          this.activeCity = val;
          break;
        default:
          return;
      }
      this.getJobs();
    },
    getDetail(val) {
      this.detailData = val;
      this.maskShow = true;
      html.style.overflow = "hidden";
    },
    closeMask() {
      this.maskShow = false;
      html.style.overflow = "auto";
    },
    // 页码
    handleCurrentChange(val) {
      this.page_num = val;
      this.getJobs();
    },
    // 翻页
    handlePrev(val) {
      console.log(val);
    },
    handleNext(val) {
      console.log(val);
    },
    // 格式化金钱数值
    formatNumber(num) {
      return num / (1e3).toFixed(1) + "k";
    },
    init() {
      this.getBanner();
      this.getCategories();
      this.getJobs();
    },
  },
};
</script>

<style lang="scss" scoped>
.aboutUs {
  position: relative;
  .bg {
    width: 100%;
    height: 400px;
    // background-image: url("./img/组\ 1181.png");
    background-size: 100% 100%;
  }
  .content {
    box-sizing: border-box;
    padding: 88px 320px 60px 320px;
    position: relative;
    background: #ffffff;
    .sort {
      width: 1280px;
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translate(-50%, 0);
      display: flex;
      line-height: 56px;
      font-size: 24px;
      color: #303133;
      background: #ffffff;

      li {
        width: 544px;
        height: 56px;
        cursor: pointer;
        box-shadow: 0px 10px 10px 1px rgba(0, 43, 51, 0.1);
      }
      .activeTitle {
        background: linear-gradient(270deg, #00d6cb 0%, #00a2c0 100%);
        color: #ffffff;
        box-shadow: none;
      }
    }
    .content_box {
      width: 100%;
      .left_box {
        width: 100%;
        // height: 880px;
        background: #ffffff;
        padding: 50px 0 0;
        .content_sort {
          position: relative;
          padding-bottom: 32px;
          .sort_item_wrap {
            font-size: 16px;
            color: #909399;
            margin-bottom: 20px;
            text-align: left;
            display: flex;
            align-items: flex-start;
            .item_label {
              width: 100px;
              height: 30px;
              line-height: 30px;
            }
            .item_val_wrap {
              display: flex;
              align-items: center;
              flex: 1;
              flex-wrap: wrap;
              span {
                margin-right: 20px;
                margin-bottom: 6px;
                display: inline-block;
                font-size: 18px;
                padding: 3px 15px;
                color: #303133;
                cursor: pointer;
                &:hover {
                  color: #00d6cb;
                }
                &.activePort {
                  background: linear-gradient(270deg, #00d6cb 0%, #00a2c0 100%);
                  color: #ffffff;
                }
              }
            }
          }
          &:after {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 2px;
            background: #ececec;
          }
        }
        .list {
          padding: 56px 0px 0px;
          height: max-content;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          row-gap: 50px;
          column-gap: 38px;
          li {
            width: 398px;
            height: 128px;
            background: linear-gradient(270deg, #fcfbfa 0%, #f2fafa 100%);
            box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.06);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .li_item {
              width: 358px;
              height: 80px;
              display: flex;
              justify-content: space-between;
              .li_img {
                width: 80px;
                height: 80px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .li_content {
                width: 250px;
                height: 80px;
                display: flex;
                justify-content: space-between;
                .content_left {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  span:nth-of-type(1) {
                    text-align: left;
                    width: 100px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                    font-size: 18px;
                    color: #303133;
                    font-weight: bold;
                  }
                  .numberOne {
                    width: max-content;
                    font-size: 14px;
                    box-sizing: border-box;
                    padding: 2px 8px;
                    background: #ffffff;
                    border: 1px solid #2b71ee;
                    color: #2b71ee;
                  }
                  .numberTwo {
                    width: max-content;
                    font-size: 14px;
                    box-sizing: border-box;
                    padding: 2px 8px;
                    background: #ffffff;
                    border: 1px solid #00d6cb;
                    color: #00d6cb;
                  }
                }
                .content_right {
                  width: 160px;
                  text-align: right;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  span:nth-of-type(1) {
                    font-size: 18px;
                    color: #ffc331;
                    font-weight: bold;
                    text-align: right;
                  }
                  span:nth-of-type(2) {
                    text-align: left;
                    height: 24px;
                    line-height: 24px;
                    color: #606266;
                    font-size: 14px;
                    position: relative;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                    img {
                      float: left;
                      margin-top: 3px;
                      margin-right: 8px;
                      width: 16px;
                      object-fit: contain;
                    }
                  }
                }
              }
            }
          }
        }
        .page {
          margin-top: 40px;
        }
      }
      .right_box {
        width: 326px;
        height: 740px;
        background: #ffffff;
        padding: 40px 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        span {
          font-size: 18px;
          color: #303133;
          font-weight: bold;
        }
        img {
          width: 220px;
          height: 222px;
        }
        p {
          font-size: 18px;
          color: #606266;
          line-height: 36px;
          text-align: left;
          text-indent: 2em;
          white-space: pre-line;
        }
      }
    }
  }
}

// 遮罩
.mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba($color: #303133, $alpha: 0.5);
  backdrop-filter: blur(10px);
  top: 0;

  .mask_Box {
    border-radius: 10px 10px 10px 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px 0px;
    width: 880px;
    height: 660px;
    overflow-x: hidden;
    overflow-y: hidden;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    .close {
      position: absolute;
      top: 0;
      right: 0px;
      width: 37px;
      height: 37px;
      cursor: pointer;
      &:hover {
        background-color: #ff6f4d;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .top {
      padding: 0px 50px 20px;
      width: 780px;
      height: 80px;
      display: flex;
      justify-content: space-between;

      .li_img {
        width: 80px;
        height: 80px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .li_content {
        width: 678px;
        height: 80px;
        display: flex;
        justify-content: space-between;
        .content_left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          span:nth-of-type(1) {
            font-size: 18px;
            color: #303133;
            font-weight: bold;
          }
          .numberOne {
            width: max-content;
            font-size: 14px;
            box-sizing: border-box;
            padding: 2px 8px;
            background: #ffffff;
            border: 1px solid #2b71ee;
            color: #2b71ee;
          }
          .numberTwo {
            width: max-content;
            font-size: 14px;
            box-sizing: border-box;
            padding: 2px 8px;
            background: #ffffff;
            border: 1px solid #00d6cb;
            color: #00d6cb;
          }
        }
        .content_right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          span:nth-of-type(1) {
            font-size: 18px;
            color: #ffc331;
            font-weight: bold;
            text-align: right;
          }
          span:nth-of-type(2) {
            height: 24px;
            line-height: 24px;
            color: #606266;
            font-size: 14px;
            position: relative;
            img {
              position: absolute;
              top: 3px;
              left: -24px;
              width: 16px;
              object-fit: contain;
            }
          }
        }
      }
    }
    .middle {
      position: relative;
      margin-bottom: 50px;
      width: 100%;
      height: 60px;
      font-size: 18px;
      padding: 0 50px 0 150px;
      box-sizing: border-box;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &::after {
        content: "";
        position: absolute;
        left: 50px;
        top: 80px;
        width: 780px;
        height: 2px;
        background-color: #ececec;
      }
    }
    .bottom {
      width: 780px;
      margin-left: 50px;
      padding-right: 20px;
      box-sizing: content-box;
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
      text-align: left;
      span {
        display: block;
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 18px;
        color: #303133;
        font-weight: bold;
      }
      p {
        white-space: pre-line;
        font-size: 16px;
        color: #606266;
        line-height: 30px;
      }
      .tips {
        display: block;
        margin-top: 16px;
      }
    }
  }
}
</style>
