import request from "@/utils/request";
import Vue from 'vue'
var vm =new Vue()

// 技术经理人
export function register(data) {
  return request({
    url: vm.$BASE_URL+"/userCenter/register",
    method: "post",
    data,
  });
}
export function registerPhone(phone) {
  return request({
    url: vm.$BASE_URL+`/userCenter/register/${phone}`,
    method: "get",
  });
}
export function registerCode(phone) {
  return request({
    url: vm.$BASE_URL+`/userCenter/register/code/${phone}`,
    method: "get",
  });
}

export function loginByUserName(data) {
  return request({
    url: vm.$BASE_URL+`/userCenter/login`,
    method: "post",
    data
  });
}
