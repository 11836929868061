/* ========== Service Case API ========== */
import request from "@/utils/request";
import Vue from "vue";
var vm = new Vue();

// Get service case category list
export function caseCategory() {
  return request({
    url: vm.$BASE_URL + "/Case/category",
    method: "get",
  });
}

// Get service case list
export function caseList(data) {
  return request({
    url: vm.$BASE_URL + "/Case/case",
    method: "post",
    data,
  });
}
