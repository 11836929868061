<template>
  <canvas :id="canvasId" @click="draw"></canvas>
</template>

<script>
import { strAll } from "./consts.js";
export default {
  props: {
    canvasId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      gcode: "",
    };
  },
  methods: {
    draw() {
      console.log(this.canvasId, "dcaca");
      let str = "";
      for (let i = 0; i < 4; i++) {
        str += strAll[Math.floor(Math.random() * 33)];
      }
      this.gcode = str;
      let canvasWidth = 120;
      let canvasHeight = 40;
      let canvas = document.getElementById(this.canvasId);
      let context = canvas.getContext("2d");
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      context.fillStyle = "#ccc";
      context.fillRect(0, 0, 300, 200);
      let aLength = str.length;
      for (let i = 0; i < aLength; i++) {
        let deg = (Math.random() * 15 * Math.PI) / 100;
        let txt = str[i];
        let x = 10 + i * 30;
        let y = 25 + Math.random() * 8;
        context.font = "bold 23px 微软雅黑";
        // context.
        context.translate(x, y);
        context.rotate(deg);
        context.fillStyle = this.randomColor();
        context.fillText(txt, 0, 0);
        context.rotate(-deg);
        context.translate(-x, -y);
      }
      for (let i = 0; i <= 5; i++) {
        context.strokeStyle = this.randomColor();
        context.beginPath();
        context.moveTo(
          Math.random() * canvasWidth,
          Math.random() * canvasHeight
        );
        context.lineTo(
          Math.random() * canvasWidth,
          Math.random() * canvasHeight
        );
        context.stroke();
      }
      for (let i = 0; i <= 30; i++) {
        context.strokeStyle = this.randomColor();
        context.beginPath();
        let x = Math.random() * canvasWidth;
        let y = Math.random() * canvasHeight;
        context.moveTo(x, y);
        context.lineTo(x + 1, y + 1);
        context.stroke();
      }
      this.$emit("drawGcode", this.gcode);
    },
    randomColor() {
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);
      return `rgb(${r},${g},${b})`;
    },
  },
  mounted() {
    this.draw();
  },
};
</script>

<style lang="scss" scoped>
canvas {
  cursor: pointer;
}
</style>
