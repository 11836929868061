<template>
  <div class="aboutUs">
    <div class="bg"></div>
    <div class="content">
      <div
        v-for="(item, index) in listData"
        :key="index"
        class="item"
        @mouseenter="hover($event, 'mouseenter')"
        @mouseleave="cancelhover($event, 'mouseleave')"
      >
        <div class="text_Box">
          <div class="icon" :style="{ background: item.color }"></div>
          <span>{{ item.title }}</span>
          <p>{{ item.text }}</p>
          <button @click="itemDetail(item)">
            成为合作伙伴 <img src="./img/组 1210@2x.png" alt="" />
          </button>
        </div>
        <div class="img">
          <img :src="item.img" alt="" />
        </div>
      </div>
    </div>
    <!-- 企业注册表单 -->
    <div class="mask" v-if="maskShow" @click="maskShow = true">
      <div class="close" @click="closeMask">
        <img src="./img/路径 8344.png" alt="" />
      </div>
      <enterprise
        v-if="detailData.title === '我是科技企业'"
        ref="enterprise"
        @closeMask="closeMask"
        @login="login"
        @showGcode="openGcode"
      ></enterprise>
      <expert
        v-else-if="detailData.title === '我是专家'"
        ref="expert"
        @closeMask="closeMask"
        @login="login"
        @showGcode="openGcode"
      ></expert>
      <technical-manager
        v-else-if="detailData.title === '我是技术经理人'"
        ref="technicalManager"
        @closeMask="closeMask"
        @login="login"
        @showGcode="openGcode"
      ></technical-manager>
      <!-- <div class="mask_Box">
        <h2>注册为科技企业</h2>
        <div class="long">
          <span>用户名<i style="color: #ff5931">*</i></span
          ><input
            type="text"
            placeholder="请输入用户名"
            v-model="registerForm.username"
            @focus="focus($event, registerForm.username)"
            @blur="onblur($event, registerForm.username)"
          />
        </div>
        <div class="half">
          <div>
            <span>手机号<i style="color: #ff5931">*</i></span
            ><input
              type="text"
              placeholder="请输入手机号(中国大陆)"
              v-model="registerForm.phone"
              @focus="focus($event, registerForm.phone)"
              @blur="onblur($event, registerForm.phone)"
            />
          </div>
          <div>
            <span>验证码<i style="color: #ff5931">*</i></span
            ><input
              type="text"
              placeholder="请输入短信验证码"
              v-model="registerForm.verify_code"
              @focus="focus($event, registerForm.verify_code)"
              @blur="onblur($event, registerForm.verify_code)"
            />
            <button
              @click="sendCode(registerForm.verify_code)"
              :class="isSend ? 'sendBtn' : 'msgBtn'"
              :disabled="dis"
              type="primary"
            >
              <span v-if="show">发送验证码</span>
              <span v-else>{{ count }}s后重发</span>
            </button>
          </div>
        </div>
        <div class="half">
          <div>
            <span>邮箱<i style="color: #ff5931">*</i></span
            ><input
              type="text"
              placeholder="请输入邮箱账号"
              v-model="registerForm.email"
              @focus="focus($event, registerForm.email)"
              @blur="onblur($event, registerForm.email)"
            />
          </div>
          <div>
            <span>密码<i style="color: #ff5931">*</i></span
            ><input
              type="password"
              placeholder="设置6-18位密码"
              v-model="registerForm.password"
              @focus="focus($event, registerForm.password)"
              @blur="onblur($event, registerForm.password)"
            />
          </div>
        </div>
        <div class="half" v-if="detailData.title !== '我是技术经理'">
          <div>
            <span>公司名称<i style="color: #ff5931">*</i></span
            ><input
              type="text"
              placeholder="请输入公司名称"
              v-model="registerForm.name"
              @focus="focus($event, registerForm.name)"
              @blur="onblur($event, registerForm.name)"
            />
          </div>
          <div>
            <span>统一社会信用代码<i style="color: #ff5931">*</i></span
            ><input
              type="text"
              placeholder="请输入统一社会信用代码 "
              v-model="registerForm.creditCodeL"
              @focus="focus($event, registerForm.creditCodeL)"
              @blur="onblur($event, registerForm.creditCodeL)"
            />
          </div>
        </div>
        <div class="half" v-if="detailData.title !== '我是技术经理'">
          <div>
            <span>所在省份/办事处<i style="color: #ff5931">*</i></span
            ><input
              type="text"
              placeholder="请输入所在省份/办事处"
              v-model="registerForm.fdsf"
              @focus="focus($event, registerForm.fdsf)"
              @blur="onblur($event, registerForm.fdsf)"
            />
          </div>
          <div>
            <span>您的职位<i style="color: #ff5931">*</i></span
            ><input
              type="text"
              placeholder="请输入您的职位"
              v-model="registerForm.work"
              @focus="focus($event, registerForm.work)"
              @blur="onblur($event, registerForm.work)"
            />
          </div>
        </div>
        <div class="long" v-if="detailData.title !== '我是技术经理'">
          <span>单位简介<i style="color: #ff5931">*</i></span
          ><textarea
            name=""
            id="textarea"
            cols="30"
            rows="10"
            placeholder="请输入单位简介"
            minlength="1"
            maxlength="100"
            v-model="registerForm.info"
            @focus="focus($event, registerForm.info)"
            @blur="onblur($event, registerForm.info)"
          ></textarea>
          <div class="show_words_limit">{{ registerForm.info.length }}/100</div>
        </div>
        <button class="commitBtn" @click="register">提交审核</button>
      </div> -->
    </div>

    <el-dialog
      title="图形验证码"
      width="400px"
      :visible.sync="showGcodeForm"
      :close-on-click-modal="false"
    >
      <el-form
        ref="gcodeForm"
        :model="gcodeForm"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="图形验证码" prop="gcode">
          <div class="gcode-wrap">
            <el-input
              v-model.trim="gcodeForm.gcode"
              autocomplete="off"
              clearable
              :maxlength="4"
            ></el-input>
            <Gcode ref="Gcode" canvasId="register" @drawGcode="getGcode" />
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitGcode">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
var html = window.document.querySelector("html");
import { register, registerPhone, registerCode } from "@/api/user";
import { Message } from "element-ui";
import Enterprise from "./mask/enterprise.vue";
import TechnicalManager from "./mask/technicalManager.vue";
import Expert from "./mask/expert.vue";
import { loginByPwd } from "@/api/service";
import Gcode from "@/components/Gcode/Gcode.vue";
export default {
  data() {
    const validateGcode = (rule, val, callback) => {
      if (!val) {
        return callback(new Error("请输入图形验证码"));
      } else {
        if (val.toUpperCase() !== this.gcode) {
          return callback(new Error("图形验证码不正确"));
        } else {
          return callback();
        }
      }
    };
    return {
      detailData: null,
      imgData: [
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画",
        },
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画",
        },
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画",
        },
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画",
        },
      ],
      imgData1: [
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画第三方第三方第三方打算发多少",
        },
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画",
        },
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画",
        },
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画",
        },
        {
          img: require("./img/矩形 430.png"),
          text: "二维AE科普宣传MG手绘动漫flash逐帧飞碟说动画",
        },
      ],
      sortList: [
        { id: 1, title: "个人资料" },
        { id: 1, title: "需求管理" },
        { id: 1, title: "技术管理" },
      ],
      logged: false,
      listData: [
        {
          id: "",
          title: "我是科技企业",
          text: "提出技术创新需求，利用外部创新资源，有效开展产学研协同创新。",
          img: "北京 北京市 东城区建材中路29号大陆国际999号2层",
          color: "#2B71EE",
          img: require("./img/矩形 507.png"),
        },
        {
          id: "",
          title: "我是专家",
          text: "展示技术创新成果，精准对接产业资源，一站式实现科技成果转化。",
          img: "北京 北京市 东城区建材中路29号大陆国际999号2层",
          color: "#00D6CB",
          img: require("./img/2.png"),
        },
        {
          id: "",
          title: "我是技术经理人",
          text: "链接创新端与产业端，服务科技成果转化全过程，助力企业创新升级。",
          img: "北京 北京市 东城区建材中路29号大陆国际999号2层",
          color: "#FFC331",
          img: require("./img/3.png"),
        },
      ],
      active: "",
      maskShow: false,
      registerForm: {
        user_type: 1,
        username: "",
        phone: "",
        verify_code: "",
        email: "",
        password: "",
        name: "",
        creditCodeL: "",
        fdsf: "",
        work: "",
        info: "",
      },
      count: 0,
      dis: false,
      show: true,
      isSend: false, //按钮样式
      timer: null,
      activeSort: "",
      showGcodeForm: false,
      gcode: "",
      gcodeForm: {
        gcode: "",
      },
      rules: {
        gcode: [{ required: true, validator: validateGcode, trigger: "blur" }],
      },
    };
  },
  components: {
    Enterprise,
    Expert,
    TechnicalManager,
    Gcode,
  },
  mounted() {
    this.activeSort = 0;
    document.querySelectorAll(".item")[0].classList.add("hoverClass");
  },
  destroyed() {},
  methods: {
    getGcode(val) {
      this.gcode = val;
    },
    hover(e, behavior) {
      let list = document.querySelectorAll(".item");
      for (let i = 0; i < list.length; i++) {
        list[i].classList.remove("hoverClass");
      }
      e.target.classList.add("hoverClass");
    },
    cancelhover(e, behavior) {
      // e.target.classList.remove('hoverClass')
    },
    itemDetail(item) {
      this.detailData = item;
      this.maskShow = true;
      html.style.overflow = "hidden";
    },
    closeMask() {
      this.maskShow = false;
      html.style.overflow = "auto";
    },
    register() {
      register(this.registerForm).then((res) => {
        this.$message.error(res.msg);
        if (res.state === 200) {
          this.$message({
            message: res.data,
            type: "success",
          });
        } else {
          this.$message.error(res.data);
        }
      });
      this.maskShow = false;
      html.style.overflow = "auto";
      this.logged = true;
    },
    // focus(e, val) {
    //   e.target.style.border = "1px solid #00d6cb";
    //   e.target.classList.remove("change");
    // },
    // onblur(e, val) {
    //   if (!val) {
    //     e.target.style.border = "1px solid #ff5931";
    //     e.target.classList.add("change");
    //   } else {
    //     e.target.style.border = "1px solid #d1d1d1";
    //   }
    // },
    // sendCode() {
    //   registerPhone(this.registerForm.phone).then((res) => {
    //     if (res.state === 200) {
    //       registerCode(this.registerForm.phone).then((res) => {
    //         if (res.state === 200) {
    //           let TIME_COUNT = 60;
    //           if (!this.timer) {
    //             this.count = TIME_COUNT;
    //             this.isSend = true;
    //             this.show = false;
    //             this.dis = true;
    //             this.timer = setInterval(() => {
    //               if (this.count > 0 && this.count <= TIME_COUNT) {
    //                 this.count--;
    //               } else {
    //                 this.dis = false;
    //                 this.isSend = false;
    //                 this.show = true;
    //                 clearInterval(this.timer);
    //                 this.timer = null;
    //               }
    //             }, 1000);
    //           }
    //         }
    //       });
    //     }
    //   });
    // },
    // 更新title
    changeTitle(item, index) {
      if (index == this.activeSort) return;
      this.activeSort = index;
    },
    upload() {
      console.log("上传");
    },
    openGcode() {
      this.showGcodeForm = true;
      this.$nextTick(() => {
        this.$refs.Gcode.draw();
        this.$refs.gcodeForm.resetFields();
      });
    },
    submitGcode() {
      this.$refs.gcodeForm.validate((valid) => {
        if (valid) {
          switch (this.detailData.title) {
            case "我是科技企业":
              this.$refs.enterprise.sms();
              break;
            case "我是专家":
              this.$refs.expert.sms();
              break;
            case "我是技术经理人":
              this.$refs.technicalManager.sms();
              break;
            default:
              return;
          }
          this.showGcodeForm = false;
          this.$refs.gcodeForm.resetFields();
        } else {
          return false;
        }
      });
    },
    login(data) {
      loginByPwd(data).then((res) => {
        if (res.state === 0) {
          this.$store.dispatch("set_token", res.data);
          this.$store.commit("getToken");
        }
      });
    },
  },
  computed: {
    textareaLenth() {
      return this.textarea.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.aboutUs {
  position: relative;
  .bg {
    width: 100%;
    height: 400px;
    background-image: url("./img/组\ 1254.png");
    background-size: 100% 100%;
  }
  .content {
    box-sizing: border-box;
    padding: 177px 144px 120px 144px;
    height: 635px;
    background: #f3f8fd;
    display: flex;
    justify-content: space-between;
    .item {
      width: 326px;
      height: 261px;
      padding: 77px 32px 0px 32px;
      background: #f3f8fd;
      box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.1);
      border: 2px solid #ffffff;
      display: flex;
      .text_Box {
        text-align: left;
        .icon {
          width: 56px;
          height: 6px;
        }
        span {
          display: block;
          margin-top: 16px;
          font-size: 32px;
          font-weight: bold;
          color: #303133;
        }
        p {
          margin-top: 56px;
          font-size: 16px;
          color: #606266;
        }
        button {
          display: none;
        }
      }
      .img {
        display: none;
      }
    }
    .hoverClass {
      padding: 77px 422px 0px 32px;
      width: 350px;
      height: 261px;
      background: linear-gradient(
        223deg,
        rgba(61, 131, 255, 0.1) 0%,
        rgba(1, 209, 246, 0.05) 100%
      );
      box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.1);
      border: 2px solid #ffffff;
      position: relative;
      .text_Box {
        p {
          margin-top: 20px;
        }
        button {
          display: block;
          margin-top: 30px;
          border: none;
          width: 224px;
          height: 48px;
          background: linear-gradient(270deg, #00d6cb 0%, #00a2c0 100%);
          color: #ffffff;
          cursor: pointer;
          font-size: 18px;
          transition: all 0.2s;
          &:hover {
            box-shadow: 0px 10px 10px 1px #99d1cd;
          }
          img {
            width: 20px;
            object-fit: contain;
          }
        }
      }
      .img {
        display: block;
        position: absolute;
        right: 32px;
        width: 358px;
        height: 269px;
        bottom: 126px;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
  /deep/.el-dialog__body {
    padding: 30px 20px 0;
  }

  /deep/.el-button--primary {
    background-color: #00d6cb;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 1;
    }
  }
  .gcode-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    /deep/.el-input {
      margin-right: 10px !important;
    }
  }

  // 遮罩
  .mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1001;
    background-color: rgba($color: #303133, $alpha: 0.5);
    backdrop-filter: blur(10px);
    top: 0;
    .close {
      position: absolute;
      top: calc((100vh - 890px) / 2);
      right: 500px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .mask_Box {
      border-radius: 10px 10px 10px 10px;
      position: absolute;
      top: calc((100vh - 890px) / 2);
      left: 50%;
      transform: translate(-50%, 0);
      padding: 50px 0px 0;
      width: 794px;
      max-height: 800px;
      // height: max-content;
      overflow: auto;
      // overflow-x: hidden;
      // overflow-y: hidden;
      background: #ffffff;
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 24px;
        color: #303133;
        margin-bottom: 8px;
      }
      .long {
        margin-top: 24px;
        width: 680px;
        padding-left: 56px;
        text-align: left;
        position: relative;
        .show_words_limit {
          position: absolute;
          right: 16px;
          bottom: 10px;
          font-style: normal;
          color: #aaaaaa;
        }
        span {
          font-size: 18px;
          font-weight: bold;
        }
        input {
          margin-top: 9px;
          width: 680px;
          height: 48px;
          background: #ffffff;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #d1d1d1;
          text-indent: 1em;
          color: #303133;
          outline: none;
          &::-webkit-input-placeholder {
            text-indent: 1em;
            color: #aaaaaa;
            font-family: Avenir, Helvetica, Arial, sans-serif;
          }
        }
        .change {
          &::-webkit-input-placeholder {
            color: #ff5931 !important;
          }
        }
        #textarea {
          padding: 9px 0px;
          margin-top: 9px;
          display: block;
          width: 680px;
          height: 62px;
          resize: none;
          border: 1px solid #d1d1d1;
          text-indent: 1em;
          color: #303133;
          line-height: 20px;
          outline: none;
          border-radius: 4px 4px 4px 4px;
          &::-webkit-input-placeholder {
            text-indent: 1em;
            color: #aaaaaa;
            font-family: Avenir, Helvetica, Arial, sans-serif;
          }
          .placeholder_class {
            text-indent: 1em;
            color: #ff5931;
          }
        }
      }
      .half {
        margin-top: 24px;
        width: 680px;
        text-align: left;
        padding-left: 56px;
        display: flex;
        justify-content: space-between;
        div {
          width: 320px;
          position: relative;
          span {
            display: block;
            margin-bottom: 9px;
            font-size: 18px;
            font-weight: bold;
          }
          input {
            width: 320px;
            height: 48px;
            background: #ffffff;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #d1d1d1;
            text-indent: 1em;
            outline: none;
            &::-webkit-input-placeholder {
              text-indent: 1em;
              color: #aaaaaa;
              font-family: Avenir, Helvetica, Arial, sans-serif;
            }
          }
          .change {
            &::-webkit-input-placeholder {
              color: #ff5931 !important;
            }
          }
          .msgBtn {
            position: absolute;
            right: 8px;
            bottom: 6px;
            width: 88px;
            height: 36px;
            background: #00d6cb;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #00d6cb;
            cursor: pointer;
            span {
              font-size: 14px;
              color: #ffffff;
              line-height: 36px;
              text-align: center;
            }
          }
          .sendBtn {
            position: absolute;
            right: 8px;
            bottom: 6px;
            width: 88px;
            height: 36px;
            opacity: 0.5;
            background: #00d6cb;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #00d6cb;
            cursor: pointer;
            span {
              font-size: 14px;
              color: #ffffff;
              line-height: 36px;
              text-align: center;
            }
          }
        }
      }
      .commitBtn {
        display: block;
        width: 680px;
        // height: 56px;
        background: #00d6cb;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #00d6cb;
        margin: 0 auto;
        margin-top: 32px;
        font-size: 18px;
        color: #ffffff;
        cursor: pointer;
        margin-bottom: 50px;
      }
    }
  }
}
</style>
