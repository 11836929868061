<template>
  <!-- 技术注册表 -->
  <div class="mask_Box">
    <h2>注册为技术经理人</h2>
    <div class="long">
      <span>用户名<i style="color: #ff5931">*</i></span
      ><input
        type="text"
        placeholder="请输入用户名"
        v-model="registerForm.user_name"
        @focus="focus($event, registerForm.user_name)"
        @blur="onblur($event, registerForm.user_name)"
      />
    </div>
    <div class="half">
      <div>
        <span>手机号<i style="color: #ff5931">*</i></span
        ><input
          type="text"
          placeholder="请输入手机号(中国大陆)"
          v-model="registerForm.user_mobile"
          @focus="focus($event, registerForm.user_mobile)"
          @blur="onblur($event, registerForm.user_mobile)"
        />
      </div>
      <div>
        <span>验证码<i style="color: #ff5931">*</i></span
        ><input
          type="text"
          placeholder="请输入短信验证码"
          v-model="registerForm.verify_code"
          @focus="focus($event, registerForm.verify_code)"
          @blur="onblur($event, registerForm.verify_code)"
        />
        <button
          @click="sendCode(registerForm.verify_code)"
          :class="isSend ? 'sendBtn' : 'msgBtn'"
          type="primary"
          :disabled="dis"
        >
          <span v-if="show">发送验证码</span>
          <span v-else>{{ count }}s后重发</span>
        </button>
      </div>
    </div>
    <div class="half">
      <div>
        <span>邮箱<i style="color: #ff5931">*</i></span
        ><input
          type="text"
          placeholder="请输入邮箱账号"
          v-model="registerForm.user_email"
          @focus="focus($event, registerForm.user_email)"
          @blur="onblur($event, registerForm.user_email)"
        />
      </div>
      <div>
        <span>密码<i style="color: #ff5931">*</i></span
        ><input
          type="password"
          placeholder="设置6-18位密码"
          v-model="registerForm.user_pwd"
          @focus="focus($event, registerForm.user_pwd)"
          @blur="onblur($event, registerForm.user_pwd)"
        />
      </div>
    </div>
    <button class="commitBtn" @click="register">提交审核</button>
  </div>
</template>
<script>
import { signInForManager, sms } from "@/api/service";
export default {
  data() {
    return {
      registerForm: {
        user_name: "",
        user_mobile: "",
        user_email: "",
        user_pwd: "",
        verify_code: "",
      },
      count: 0,
      dis: false,
      show: true,
      isSend: false, //按钮样式
      timer: null,
    };
  },
  watch: {
    // dis() {
    //   return !Boolean(this.registerForm.user_mobile);
    // },
  },
  methods: {
    register() {
      signInForManager(this.registerForm).then((res) => {
        if (res.state === 0) {
          this.$message.success(`技术经理人注册${res.msg}`);
          this.$emit("closeMask");
          const user = {
            user_mobile: this.registerForm.user_mobile,
            user_pwd: this.registerForm.user_pwd,
          };
          this.$emit("login", user);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    focus(e, val) {
      e.target.style.border = "1px solid #00d6cb";
      e.target.classList.remove("change");
    },
    onblur(e, val) {
      if (!val) {
        e.target.style.border = "1px solid #ff5931";
        e.target.classList.add("change");
      } else {
        e.target.style.border = "1px solid #d1d1d1";
      }
    },
    sendCode() {
      if (!this.registerForm.user_mobile) return;
      this.$emit("showGcode");
    },
    sms() {
      sms({ user_mobile: this.registerForm.user_mobile }).then((res) => {
        if (res.state === 0) {
          let TIME_COUNT = 60;
          console.log(res.data);
          this.count = TIME_COUNT;
          this.isSend = true;
          this.show = false;
          this.dis = true;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.dis = false;
              this.isSend = false;
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      });
    },
    // 更新title
    // changeTitle(item, index) {
    //   if (index == this.activeSort) return;
    //   this.activeSort = index;
    // },
  },
};
</script>
<style lang="scss" scoped>
@import "./mask.scss";
</style>
