/* ========== Solution Page API ========== */
import request from "@/utils/request";
import Vue from "vue";
var vm = new Vue();

// Get solution with category
export function solution(params) {
  return request({
    url: vm.$BASE_URL + `/Solution/solution`,
    method: "get",
    params,
  });
}

export function getSolution(data) {
  return request({
    url: vm.$BASE_URL + `/solution/getSolution?solution_type=${data}`,
    method: "get",
  });
}
