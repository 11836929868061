<template>
  <div class="aboutUs">
    <div class="bg" :style="{ backgroundImage: 'url(' + bgImg + ')' }"></div>
    <div class="content">
      <ul class="sort">
        <li
          :class="index == activeTitle ? 'activeTitle' : ''"
          v-for="(item, index) in titleSort"
          :key="index"
          @click="changeTitle(item, index)"
        >
          {{ item.name }}
        </li>
      </ul>
      <div class="content_box">
        <div class="left_box">
          <!-- <h2>联系我们(总部)</h2>
          <div class="icon_box">
            <div class="tel_box">
              <div class="tel_img">
                <img src="./img/组 1267.png" alt="" />
              </div>
              <div class="icon_text">
                公司电话：<span>{{ publicData[4].text }}</span>
              </div>
            </div>
            <div class="email_box">
              <div class="email_img">
                <img src="./img/组 1268.png" alt="" />
              </div>
              <div class="icon_text">
                企业邮箱：<span>{{ publicData[5].text }}</span>
              </div>
            </div>
          </div> -->
          <div class="address_wrap">
            <div
              v-for="(item, index) in contactData"
              :key="index"
              class="address_item"
            >
              <div class="item_title">
                <img
                  src="@/assets/img/aboutUs/address_title.png"
                  alt=""
                  class="title_icon"
                />
                <span class="title_text">{{ item.name }}</span>
              </div>
              <div class="item_content">
                <img :src="item.pic_url" alt="" class="content_img" />
                <div class="content_info">
                  <p>
                    <span class="info_label">联系方式</span>
                    <span class="info_val">{{ item.phone }}</span>
                  </p>
                  <p>
                    <span class="info_label">公司地址</span>
                    <span class="info_val">{{ item.address }}</span>
                  </p>
                  <div class="info_map" :id="'mapContainer' + index"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- <ul>
            <li
              v-for="(item, index) in listData"
              :key="index"
              :class="index == active ? 'active' : ''"
              @click="change(item, index)"
            >
              <span>{{ item.title }}</span>
              <p>
                <i style="color: #909399; font-style: normal">电话 </i
                >{{ item.phone }}
              </p>
              <p>
                <i style="color: #909399; font-style: normal">地址 </i
                >{{ item.detail_address }}
              </p>
            </li>
          </ul> -->
          <!-- <el-tabs v-model="activeTab" class="tabs" @tab-click="change">
            <el-tab-pane
              v-for="(item, index) in listData"
              :key="index"
              :class="active"
              :name="`tab${index}`"
              :label="item.address"
            ></el-tab-pane>
          </el-tabs> -->
          <!-- <div class="bg_img_box">
            <div class="bg_img">
              <div
                class="maker"
                :style="{ top: item.y * 1.3 + 'px', left: item.x * 2 + 'px' }"
                v-for="(item, index) in listData"
                :key="index"
                @click="change(item, index)"
              >
                <img src="./img/icon-location.png" alt="" />
                <div class="maker_text">{{ item.address }}</div>
                <div
                  class="maker_img_box"
                  :class="
                    index == active ? 'maker_img_box_show' : 'maker_img_box'
                  "
                >
                  <div class="maker_desc">
                    <img src="./img/addrImg.jpg" alt="" />
                    <div class="desc">
                      <div class="head">
                        <h5>{{ listData[active].address }}</h5>
                        <div>
                          <img src="./img/路径 8372.png" class="icon" />{{
                            listData[active].phone
                          }}
                        </div>
                      </div>
                      <div>地址：{{ listData[active].detail_address }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div class="right_box">
          <span>关注蜂鸟公众号</span>
          <img :src="$imgUrl + publicData[6].text" alt="" />
          <p>
            {{ publicData[3].text }}
          </p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
var html = window.document.querySelector("html");
import { otherBanner } from "@/api/index";
import { contact } from "@/api/aboutus";

export default {
  data() {
    return {
      listData: [],
      active: 0,
      activeTab: "tab0",
      titleSort: [
        { id: 1, name: "了解我们", url: "/aboutUs" },
        { id: 2, name: "加入我们", url: "/joinUs" },
        { id: 3, name: "联系我们", url: "contactUs" },
      ],
      activeTitle: "",
      publicData: [],
      bgImg: "",
      contactData: [],
    };
  },
  mounted() {
    this.activeTitle = 2;
    this.active = 0;
    this.init();
    var map = new AMap.Map("container");
  },
  methods: {
    getBanner() {
      otherBanner({ page_name: "about_banner_url" }).then((res) => {
        if (res.state === 0) {
          this.bgImg = res.data;
        }
      });
    },
    getData() {
      contact().then((res) => {
        if (res.state === 0) {
          this.contactData = res.data;
          this.$nextTick(() => {
            this.contactData.forEach((item, index) => {
              this.renderMap(index, item);
            });
          });
        }
      });
    },
    renderMap(index, item) {
      let marker = new AMap.Marker({
        icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
        position: [item.lng, item.lat],
        title: item.name,
      });
      let map = new AMap.Map("mapContainer" + index, {
        zoom: 11, //级别
        center: [item.lng, item.lat], //中心点坐标
        viewMode: "2D", //使用3D视图
      });
      map.add(marker);
    },
    init() {
      this.getBanner();
      this.getData();
    },
    changeTitle(item, index) {
      if (index == this.activeTitle) return;
      // 更新title
      this.$router.replace(item.url);
    },
    change(tab, event) {
      const address = event.target.innerHTML;
      this.listData.map((item, index) => {
        if (item.address === address) {
          if (index == this.active) return;
          // 更新title
          this.active = index;
          this.activeTab = "tab" + index;
        }
      });
    },
    handleClickTab() {},
  },
};
</script>

<style lang="scss" scoped>
.aboutUs {
  position: relative;
  .bg {
    width: 100%;
    height: 400px;
    // background-image: url("./img/组\ 1181.png");
    background-size: 100% 100%;
  }
  .content {
    box-sizing: border-box;
    padding: 88px 320px 60px 320px;
    position: relative;
    background: #ffffff;
    .sort {
      width: 1280px;
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translate(-50%, 0);
      display: flex;
      line-height: 56px;
      font-size: 24px;
      color: #303133;
      background: #ffffff;
      li {
        width: 544px;
        height: 56px;
        cursor: pointer;
        box-shadow: 0px 10px 10px 1px rgba(0, 43, 51, 0.1);
      }
      .activeTitle {
        background: linear-gradient(270deg, #00d6cb 0%, #00a2c0 100%);
        color: #ffffff;
        box-shadow: none;
      }
    }
    .content_box {
      display: flex;
      .left_box {
        width: 1280px;
        height: max-content;
        background: #ffffff;
        padding: 36px 60px 0px;
        h2 {
          font-size: 32px;
          color: #303133;
          text-align: left;
          font-weight: bold;
        }
        .icon_box {
          margin-top: 32px;
          display: flex;
          justify-content: space-between;
          .tel_box {
            width: 520px;
            display: flex;
            align-items: center;
            .tel_img {
              width: 48px;
              height: 48px;
              margin-right: 16px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .icon_text {
              font-size: 18px;
              color: #909399;
              span {
                font-size: 18px;
                color: #303133;
              }
            }
          }
          .email_box {
            width: 520px;
            display: flex;
            align-items: center;
            .email_img {
              width: 48px;
              height: 48px;
              margin-right: 16px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .icon_text {
              font-size: 18px;
              color: #909399;
              span {
                font-size: 18px;
                color: #303133;
              }
            }
          }
        }
        .tabs {
          margin-top: 40px;
        }
        .el-tabs__header {
          padding: 0 40px;
        }
        ul {
          margin-top: 40px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            width: 490px;
            height: 116px;
            background: #ffffff;
            margin-bottom: 20px;
            text-align: left;
            padding: 24px 0px 0px 30px;
            border: 1px solid #efefef;
            color: #303133;
            cursor: pointer;
            span {
              font-size: 18px;
              font-weight: bold;
            }
            p:nth-of-type(1) {
              margin-top: 22px;
              font-size: 16px;
            }
            p:nth-of-type(2) {
              margin-top: 7px;
              font-size: 16px;
            }
          }
          .active {
            border: 1px solid #00d6cb;
            box-shadow: 0px 6px 6px 1px rgba(0, 0, 0, 0.1);
          }
        }
        .bg_img_box {
          width: 100%;
          height: 519px;
          min-width: 1280px;
          margin-bottom: 69px;
          .bg_img {
            width: 100%;
            min-width: 1280px;
            height: 519px;
            background-image: url("./img/map.jpg");
            background-size: 1280px 519px;
            margin: 0 auto;
            margin-top: 60px;
            position: relative;
            .maker {
              cursor: pointer;
              position: absolute;
              // top: 215px;
              // left: 218px;
              width: 24px;
              height: 24px;
              img {
                width: 49px;
                height: 64px;
              }
              .maker_text {
                font-weight: bold;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                width: max-content;
                font-size: 18px;
                color: #303133;
              }
              .maker_img_box {
                display: none;
                position: absolute;
                left: 236%;
                top: -24px;
                // transform: translate(-50%, 0);
                width: 520px;
                height: 140px;
                background: #ffffff;
                box-shadow: 0px 6px 8px 1px rgba(0, 0, 0, 0.1);
                .maker_desc {
                  display: flex;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 520px;
                  height: 140px;
                  overflow: hidden;
                  img {
                    width: 140px;
                    height: 140px;
                  }
                  .desc {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 24px 16px;
                    text-align: left;
                    font-size: 16px;
                    .head {
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      margin-bottom: 16px;
                      line-height: 18px;
                      div::first-child {
                        font-weight: bold;
                      }
                      .icon {
                        width: 18px;
                        height: 16px;
                        margin-right: 12px;
                      }
                    }
                  }
                }
              }
              .maker_img_box_show {
                display: block;
                position: absolute;
                left: 236%;
                top: -24px;
                // transform: translate(-50%, 0);
                width: 520px;
                height: 140px;
                background: #ffffff;
                box-shadow: 0px 6px 8px 1px rgba(0, 0, 0, 0.1);
                .maker_desc {
                  display: flex;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 520px;
                  height: 140px;
                  font-size: 16px;
                  overflow: hidden;
                  img {
                    width: 140px;
                    height: 140px;
                  }
                }
              }
              .triangle {
                position: absolute;
                top: -25px;
                left: 50%;
                transform: translate(-50%, 0);
                width: 0;
                height: 0;
                border-left: 9px solid transparent;
                border-right: 9px solid transparent;
                border-top: 12px solid #ffffff;
              }
            }
          }
        }
        .address_wrap {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 40px;
          .address_item {
            box-sizing: border-box;

            .item_title {
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              .title_icon {
                width: 18px;
                height: 20px;
                margin-right: 10px;
              }
              .title_text {
                font-size: 18px;
                font-weight: 850;
              }
            }
            .item_content {
              width: 400px;
              height: 565px;
              display: flex;
              flex-direction: column;
              .content_img {
                width: 400px;
                height: 227px;
              }
              .content_info {
                width: 400px;
                height: 338px;
                border: 1px solid #e5e5e5;
                border-top: none;
                font-size: 16px;
                box-sizing: border-box;
                padding: 16px;
                p {
                  display: flex;
                  margin: 6px 0;
                  span {
                    text-align: left;
                    &.info_label {
                      color: #909399;
                      margin-right: 12px;
                      width: 80px;
                    }
                    &.info_val {
                      flex: 1;
                    }
                  }
                }
                .info_map {
                  width: 368px;
                  height: 207px;
                  margin: 10px auto 0;
                }
              }
            }
          }
        }
      }
      .right_box {
        width: 326px;
        height: 740px;
        background: #ffffff;
        padding: 40px 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        span {
          font-size: 18px;
          color: #303133;
          font-weight: bold;
        }
        img {
          width: 220px;
          height: 222px;
        }
        p {
          font-size: 18px;
          color: #606266;
          line-height: 36px;
          text-align: left;
          text-indent: 2em;
          white-space: pre-line;
        }
      }
    }
  }
}
// 重写tab
/deep/.el-tabs__item:hover {
  color: #00d6cb !important ;
}
/deep/.el-tabs__item.is-active {
  color: #00d6cb !important ;
}
/deep/.el-tabs__active-bar {
  background-color: #00d6cb !important;
}
/deep/.amap-logo {
  opacity: 0;
}
/deep/.amap-copyright {
  opacity: 0;
}
</style>
