import request from "@/utils/request";
import Vue from "vue";
var vm = new Vue();

// Get normal enum values list
export function getEnumList(params) {
  return request({
    url: vm.$SYS_URL + `/Public/getEnumList`,
    method: "get",
    params,
  });
}

// Get cascade enum values list
export function getEnumCascadeList(params) {
  return request({
    url: vm.$SYS_URL + `/Public/getEnumCascadeList`,
    method: "get",
    params,
  });
}

// Get province list
export function getProvinceList() {
  return request({
    url: vm.$SYS_URL + `/Public/getProvinceList`,
    method: "get",
  });
}

// Get city list by province id
export function getCityList(params) {
  return request({
    url: vm.$SYS_URL + `/Public/getCityList`,
    method: "get",
    params
  });
}

export function getDistrictList() {
  return request({
    url: vm.$SYS_URL + `/api/Public/getDistrictList`,
    method: "get",
  });
}
