<template>
  <div class="expertPage">
    <div class="bg_box" :style="{ backgroundImage: 'url(' + bgImg + ')' }">
      <div class="search_Box">
        <span>专家库</span>
        <div>
          <input v-model="name" type="text" placeholder="输入你想要的关键词" />
          <button @click="search">搜索专家</button>
        </div>
      </div>
    </div>
    <div class="content">
      <!-- <div class="searchBox">
        <span class="title">全部专家</span>
        <div class="inputBox">
          <img src="@/assets/img/public/search.png" alt="" class="input-icon" />
          <input v-model="name" type="text" placeholder="输入你想要的关键词" />
          <button @click="search">搜索</button>
        </div>
      </div> -->
      <div class="sort">
        <div>
          <span class="sort-label">研究领域：</span>
          <span
            :class="item === activeDomain ? 'activeIndex' : ''"
            v-for="(item, index) in domainOptions"
            :key="index"
            @click="handleChange(item, 'domain')"
            >{{ item }}</span
          >
        </div>
        <div>
          <span class="sort-label">所在城市：</span>
          <span
            :class="item === activeCity ? 'activeIndex' : ''"
            v-for="(item, index) in cityOptions"
            :key="index"
            @click="handleChange(item, 'city')"
            >{{ item }}</span
          >
        </div>
      </div>
      <div class="list">
        <ul>
          <li
            @click="toDetail(item)"
            v-for="(item, index) in expertList"
            :key="index"
          >
            <p class="item-name">{{ item.name }}</p>
            <p class="item-domain">研究领域：{{ item.domain }}</p>
            <div class="item-resume" v-html="item.resume"></div>
          </li>
        </ul>
        <div class="page">
          <el-pagination
            background
            :current-page="pageNum"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
            @current-change="pageChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { category, expert } from "@/api/service";
import { otherBanner } from "@/api/index";
export default {
  data() {
    return {
      total: 0,
      cityOptions: ["不限"],
      domainOptions: ["不限"],
      activeCity: "不限",
      activeDomain: "不限",
      expertList: [],
      total: 0,
      pageNum: 1,
      pageSize: 8,
      name: "",
      bgImg: "",
    };
  },
  methods: {
    getBanner() {
      otherBanner({ page_name: "expert_banner_url" }).then((res) => {
        if (res.state === 0) {
          this.bgImg = res.data;
        }
      });
    },
    getCategories() {
      category({ category_type: "expert" }).then((res) => {
        if (res.state === 0) {
          this.cityOptions = ["不限"].concat(res.data["所在城市"]);
          this.domainOptions = ["不限"].concat(res.data["研究领域"]);
        }
      });
    },
    getData() {
      const params = {
        page_num: this.pageNum,
        page_size: this.pageSize,
        name: this.name,
        city: this.activeCity === "不限" ? "" : this.activeCity,
        domain: this.activeDomain === "不限" ? "" : this.activeDomain,
      };
      expert(params).then((res) => {
        if (res.state === 0) {
          this.expertList = res.data.row_list;
          this.total = res.data.rows_count;
        }
      });
    },
    handleChange(val, type) {
      switch (type) {
        case "city":
          if (val === this.activeCity) return;
          this.activeCity = val;
          break;
        case "domain":
          if (val === this.activeDomain) return;
          this.activeDomain = val;
          break;
        default:
          return;
      }
      this.getData();
    },
    pageChange(val) {
      this.pageNum = val;
      this.getData();
    },
    search() {
      this.pageNum = 1;
      this.getData();
    },
    // 查看详情
    toDetail(item) {
      const data = JSON.stringify(item);
      this.$router.push({ path: "/expertDetail", query: { data } });
    },
    init() {
      this.getBanner();
      this.getCategories();
      this.getData();
    },
  },
  mounted() {
    this.name = this.$route.query.name || "";
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.expertPage {
  .bg_box {
    width: 100%;
    height: 400px;
    // background-image: url("./img/组\ 1183.png");
    position: relative;
    .search_Box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      span {
        display: inline-block;
        font-size: 48px;
        color: #ffffff;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        margin-bottom: 32px;
        font-weight: bold;
      }
      div {
        width: max-content;
        height: 56px;
        display: flex;
        input {
          width: 640px;
          height: 100%;
          background: #ffffff;
          border: 1px solid #f8f8f8;
          box-sizing: border-box;
          outline: none;
          padding-left: 24px;
        }
        button {
          width: 160px;
          height: 100%;
          background: linear-gradient(180deg, #00d6cb 0%, #00a2c0 100%);
          border: 1px solid #f8f8f8;
          font-size: 20px;
          color: #ffffff;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
    }
  }
  .content {
    width: 1280px;
    // height: 1020px;
    padding: 40px 320px 100px;
    .searchBox {
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 24px;
        color: #303133;
      }
      .inputBox {
        display: flex;
        height: 48px;
        position: relative;
        .input-icon {
          width: 14px;
          height: 14px;
          position: absolute;
          left: 10px;
          top: 17px;
        }
        input {
          width: 466px;
          height: 100%;
          box-sizing: border-box;
          padding: 0 40px;
          background: #f5f5f5;
          border: 1px solid #f8f8f8;
          outline: none;
          ::-webkit-input-placeholder {
            text-indent: 2em;
          }
        }

        button {
          cursor: pointer;
          width: 160px;
          height: calc(100% + 2px);
          background: #00d6cb;
          border: 1px solid #f8f8f8;
          font-size: 20px;
          color: #ffffff;
        }
      }
    }
    .sort {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 40px;
      border-bottom: 2px solid #ececec;
      div {
        margin-top: 27px;
        width: 100%;
        background-color: #ffffff;
        font-size: 18px;
        color: #909399;
        display: flex;
        align-items: center;
        .sort-label {
          width: 120px;
          margin: 0;
          padding: 0;
          text-align: left;
        }
        span {
          margin-right: 20px;
          padding: 3px 15px;
          font-size: 18px;
          color: #303133;
          cursor: pointer;
          text-indent: 0px;
          &:hover {
            color: #00d6cb;
          }
        }
        .activeIndex {
          background: linear-gradient(270deg, #00d6cb 0%, #00a2c0 100%);
          color: #ffffff;
        }
      }
    }

    .list {
      padding-top: 40px;
      ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 40px 28px;
        flex-wrap: wrap;
        li {
          cursor: pointer;
          width: 300px;
          height: 350px;
          box-sizing: border-box;
          padding: 25px 25px 20px;
          background: #fafbfd;
          box-shadow: 0px 10px 10px 1px rgba(0, 0, 0, 0.06);
          display: flex;
          flex-direction: column;
          .item-name {
            width: 100%;
            text-align: left;
            font-size: 16px;
            // font-weight: 650;
            margin-bottom: 10px;
          }
          .item-domain {
            width: 100%;
            text-align: left;
            font-size: 14px;
            // font-weight: 550;
            margin-bottom: 20px;
          }
          .item-resume {
            flex: 1;
            text-align: left;
            overflow: hidden;
          }
        }
      }
      .page {
        margin: 60px 0px;
      }
    }
  }
}
</style>
