import Vue from "vue";
import Vuex from "vuex";
import { setToken, getToken, removeToken } from "@/utils/auth";
import { resetRouter } from "vue-router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    count: 0,
    token: getToken() || "",
    logged: false,
    showLogin: false,
    userInfo: {},
  },
  getters: {
    count(state) {
      return state.count;
    },
    token(state) {
      return state.token;
    },
    logged(state) {
      return state.logged;
    },
  },
  mutations: {
    setToken(state, token) {
      // 存入vuex
      state.token = token;
      // 存入本地
      setToken(token);
    },
    getToken(state) {
      const token = getToken();
      if (token) {
        state.logged = true;
      } else {
        state.logged = false;
      }
    },
    addCount(state, num) {
      state.count += num;
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setShowLogin(state, showLogin) {
      state.showLogin = showLogin;
    },
    // 清除用户数据的方法
    removeUserInfo(state) {
      state.token = "";
      state.userInfo = {};
      state.logged = false;
      removeToken();
      // 重置路由
      // resetRouter()
    },
  },
  actions: {
    set_token({ commit }, token) {
      commit("setToken", token);
    },
  },
});
