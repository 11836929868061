/* ========== Personal Centre API ========== */
import request from "@/utils/request";
import Vue from "vue";
import { expert } from "./service";
var vm = new Vue();

// Get user personal information
export function profile() {
  return request({
    url: vm.$BASE_URL + "/My/profile",
    method: "get",
  });
}

// Follow the request
export function followReq(params) {
  return request({
    url: vm.$BASE_URL + "/My/followReq",
    method: "get",
    params,
  });
}

// Follow the expert
export function followExpert(params) {
  return request({
    url: vm.$BASE_URL + "/My/followExpert",
    method: "get",
    params,
  });
}

//   Follow the achievement
export function followTa(params) {
  return request({
    url: vm.$BASE_URL + "/My/followTa",
    method: "get",
    params,
  });
}

// Cancel to follow the request
export function cancelFollowReq(params) {
  return request({
    url: vm.$BASE_URL + "/My/cancelFollowReq",
    method: "get",
    params,
  });
}

// Cancel to follow expert
export function cancelFollowExpert(params) {
  return request({
    url: vm.$BASE_URL + "/My/cancelFollowExpert",
    method: "get",
    params,
  });
}

// Cancel to follow achievement
export function cancelFollowTa(params) {
  return request({
    url: vm.$BASE_URL + "/My/cancelFollowTa",
    method: "get",
    params,
  });
}

// Get request list been followed
export function myFollowReq(data) {
  return request({
    url: vm.$BASE_URL + "/My/myFollowReq",
    method: "post",
    data,
  });
}

// Get expert list been followed
export function myFollowExpert(data) {
  return request({
    url: vm.$BASE_URL + "/My/myFollowExpert",
    method: "post",
    data,
  });
}

// Get achievement list been followed
export function myFollowTa(data) {
  return request({
    url: vm.$BASE_URL + "/My/myFollowTa",
    method: "post",
    data,
  });
}

// Get request list been published
export function myReq(data) {
  return request({
    url: vm.$BASE_URL + "/My/myReq",
    method: "post",
    data,
  });
}

// Get achievement list been published
export function myTa(data) {
  return request({
    url: vm.$BASE_URL + "/My/myTa",
    method: "post",
    data,
  });
}

// Check if follow certain content(request, expert, achievement)
export function followStatus(params) {
  return request({
    url: vm.$BASE_URL + "/My/followStatus",
    method: "get",
    params,
  });
}

// Send sms code for verification
export function sms(params) {
  return request({
    url: vm.$BASE_URL + "/My/sms",
    method: "get",
    params,
  });
}

// Verify sms code
export function checkSms(data) {
  return request({
    url: vm.$BASE_URL + "/My/checkSms",
    method: "post",
    data,
  });
}

// Update user email
export function email(data) {
  return request({
    url: vm.$BASE_URL + "/My/email",
    method: "post",
    data,
  });
}

// Update user mobile
export function mobile(data) {
  return request({
    url: vm.$BASE_URL + "/My/mobile",
    method: "post",
    data,
  });
}

// Update user password
export function pwd(data) {
  return request({
    url: vm.$BASE_URL + "/My/pwd",
    method: "post",
    data,
  });
}

// Update user avatar
export function avatar(data) {
  return request({
    url: vm.$BASE_URL + "/My/avatar",
    method: "post",
    data,
  });
}
