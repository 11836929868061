<template>
  <div class="demand">
    <span class="title">链接创新成果与产业需求</span>
    <div class="content_Box">
      <div class="left_box">
        <div class="left_item">
          <div>
            <img src="../images/组 975.png" alt="" />
          </div>
          <div>资料完善</div>
        </div>
        <div class="left_item">
          <div><img src="../images/组 968.png" alt="" /></div>
          <div>技术评估</div>
        </div>
        <div class="left_item">
          <div><img src="../images/组 970.png" alt="" /></div>
          <div>转化指导</div>
        </div>
        <button @click="releaseResults">发布成果</button>
        <div class="left_title">加速创新成果转化</div>
      </div>
      <div class="mid_box">
        <img src="../images/组 1073.png" alt="" />
      </div>
      <div class="right_box">
        <div class="right_item">
          <div><img src="../images/组 972.png" alt="" /></div>
          <div>专人对接</div>
        </div>
        <div class="right_item">
          <div><img src="../images/组 973.png" alt="" /></div>
          <div>需求评估</div>
        </div>
        <div class="right_item">
          <div><img src="../images/组 974.png" alt="" /></div>
          <div>方案征集</div>
        </div>
        <button @click="releaseDemand">发布需求</button>
        <div class="right_title">助力企业创新升级</div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      iconList: [
        { img: require("../images/组 978.png"), text: "一键提交需求" },
        { img: require("../images/组 981.png"), text: "信息完善归类" },
        { img: require("../images/组 982.png"), text: "分发至技术团队" },
        { img: require("../images/组 984.png"), text: "技术团队响应接单" },
      ],
    };
  },
  methods:{
    releaseResults(){
      this.$router.push('/releaseResult')
    },
    releaseDemand(){
      this.$router.push('/releaseDemand')
    },
  }
};
</script>
  
  <style lang="scss" scoped>
.demand {
  width: 100%;
  min-width: 1600px;
  height: 920px;
  .title {
    display: inline-block;
    font-weight: bold;
    font-size: 48px;
    position: relative;
    margin-top: 120px;
    margin-bottom: 200px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -28px;
      bottom: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      background-image: url("../images/椭圆\ 13.png");
      background-size: 100% 100%;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: -28px;
      bottom: 0;
      margin: auto;
      width: 16px;
      height: 16px;
      background-image: url("../images/椭圆\ 13.png");
      background-size: 100% 100%;
    }
  }
  .content_Box {
    width: 100%;
    box-sizing: border-box;
    height: 480px;
    display: flex;
    justify-content: center;
    .left_box {
      width: 528px;
      height: 480px;
      background: #ffffff;

      border-radius: 50px 50px 50px 50px;
      border: 1px solid #eeeeee;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0px 10px 10px 1px #e9efef;
      }
      .left_item {
        box-sizing: border-box;
        width: 380px;
        height: 72px;
        background: #f9f9f9;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #ebebeb;
        display: flex;
        div:nth-of-type(1) {
          width: 30%;
          height: 72px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 32px;
            height: 32px;
            object-fit: contain;
          }
        }
        div:nth-of-type(2) {
          flex: 1;
          font-size: 18px;
          line-height: 72px;
          text-align: left;
          color: #303133;
        }
      }
      .left_item:nth-of-type(1) {
        margin-top: 33px;
      }
      button {
        width: 380px;
        height: 48px;
        background: linear-gradient(270deg, #00d6cb 0%, #00a2c0 100%);
        border: none;
        font-size: 24px;
        color: #ffffff;
        cursor: pointer;
        transition: all 0.2s;
        &:hover{
          box-shadow: 0px 10px 10px 1px #e6f5f4;
        }
      }
      .left_title {
        position: absolute;
        top: -32px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 420px;
        height: 64px;
        background: #00d6cb;
        border-radius: 10px 10px 10px 10px;
        font-size: 32px;
        color: #ffffff;
        line-height: 64px;
      }
    }
    .mid_box {
      margin-left: 43px;
      width: 531.43px;
      height: 503.82px;
      margin-right: 2px;
      img {
        width: 100%;
        height: 100%;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    .right_box {
      width: 528px;
      height: 480px;
      background: #ffffff;
      cursor: pointer;
      border-radius: 50px 50px 50px 50px;
      border: 1px solid #eeeeee;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      transition: all 0.3s;
      &:hover {
        box-shadow: 0px 10px 10px 1px #e9efef;
      }
      .right_item {
        width: 380px;
        height: 72px;
        background: #f9f9f9;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #ebebeb;
        box-sizing: border-box;
        display: flex;
        div:nth-of-type(1) {
          width: 30%;
          height: 72px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 32px;
            height: 32px;
            object-fit: contain;
          }
        }
        div:nth-of-type(2) {
          flex: 1;
          font-size: 18px;
          line-height: 72px;
          text-align: left;
          color: #303133;
        }
      }
      .right_item:nth-of-type(1) {
        margin-top: 33px;
      }
      button {
        width: 380px;
        height: 48px;
        background: linear-gradient(270deg, #2b71ee 0%, #01d1f6 100%);
        font-size: 24px;
        color: #ffffff;
        border: none;
        cursor: pointer;
        transition: all 0.2s;
        &:hover{
          box-shadow: 0px 10px 10px 1px #e6f2f5;
        }
      }
      .right_title {
        position: absolute;
        top: -32px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 420px;
        height: 64px;
        background: #2b71ee;
        border-radius: 10px 10px 10px 10px;
        font-size: 32px;
        color: #ffffff;
        line-height: 64px;
      }
    }
  }
}
</style>