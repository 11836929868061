<template>
  <!-- <div class="footer">
   v-if="hash!='/aboutUs'"  -->
  <div>
    <div class="line"></div>
    <div class="footer">
      <div class="footer_top">
        <ul>
          <li>
            <a href="#">解决方案</a>
          </li>
          <li><a href="#">科创企业</a></li>
          <li><a href="#">高校院所</a></li>
        </ul>
        <ul>
          <li>关于我们</li>
          <li>
            <!-- <a @click="this.$router.push('/aboutUs')">公司简介</a> -->
            <router-link :to="{ path: '/aboutUs' }">公司简介</router-link>
          </li>
          <li>
            <!-- <a @click="this.$router.push('/joinUs')">加入我们</a> -->
            <router-link :to="{ path: '/joinUs' }">加入我们</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/companyDynamics' }"
              >公司动态</router-link
            >
            <!-- <a @click="this.$router.push('/companyDynamics')">公司动态</a> -->
          </li>
        </ul>
        <ul>
          <li>联系我们</li>
          <li>客服电话：{{ footerData.phone }}</li>
          <li>邮箱：{{ footerData.email }}</li>
          <li>地址：{{ footerData.address }}</li>
        </ul>
        <div class="code">
          <div>
            <span>扫码关注公众号</span>
            <img :src="footerData.gzh_url" alt="公众号" />
          </div>
        </div>
        <div class="logo">
          <img src="./image/_1491815136.png" alt="" />
        </div>
      </div>
      <div class="footer_bottom">
        <ul>
          <li><a href="http://kjt.sc.gov.cn/">四川省科学技术厅</a></li>
          <li><a href="https://www.uestc.edu.cn/">电子科技大学</a></li>
        </ul>
        <ul>
          <li><a href="http://cdst.chengdu.gov.cn/">成都市科学技术局</a></li>
          <li><a href="https://www.nwpu.edu.cn/">西北工业大学</a></li>
        </ul>
        <ul>
          <li><a href="http://www.scsttc.com/">国家技术转移西南中心</a></li>
          <li><a href="https://www.cqu.edu.cn/">重庆大学</a></li>
        </ul>
        <ul>
          <li>
            <a href="https://www.qinchuangyuan.com/">秦创原创新驱动平台</a>
          </li>
          <li><a href="http://www.scjky.com.cn/">四川省建筑科学研究院</a></li>
        </ul>
        <ul>
          <li><a href="https://www.scu.edu.cn/">四川大学</a></li>
          <li>
            <a href="http://www.shantac.com/">山东汉诺威产业技术研究院</a>
          </li>
        </ul>
      </div>
      <div class="footer_copyright">
        Copyright © 蜂鸟智造（成都）科技有限公司 | 蜀ICP备2022030209号-1 <span class="filingLine"><img src="./image/filingIcon.png" alt="" srcset=""> 川公网安备51012402001054号</span>
      </div>
    </div>
  </div>
</template>

<script>
import { footer } from "@/api/index";
export default {
  name: "Footer",
  props: {
    hash: String,
  },
  data() {
    return {
      footerData: {},
    };
  },
  methods: {
    getData() {
      footer().then((res) => {
        if (res.state === 0) {
          this.footerData = res.data;
        }
      });
    },
    goContactPage() {
      this.$router.push({ path: "/aboutUs" });
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss">
.line {
  width: 100%;
  height: 6px;
  background: #00d6cb;
}
.footer {
  background-color: #ececec;
  color: #303133;
  width: 100%;
  padding: 0 320px;
  margin: 0 auto;
  // height: 430px;
  box-sizing: border-box;
  min-width: 1200px;
  font-size: 16px;
  a {
    text-decoration: none;
    color: #303133;
  }

  .footer_top {
    // width: 100%;
    // height: 230px;
    display: flex;
    border-bottom: 1px solid #e1e1e1;
    justify-content: space-between;
    padding: 50px 0px;

    ul {
      li {
        margin-top: 20px;
        text-align: left;
      }
      li:nth-of-type(1) {
        font-weight: 700;
        font-size: 18px;
        margin-top: 0px;
      }
    }
    .code {
      display: flex;
      div {
        display: flex;
        flex-direction: column;
        span {
          font-size: 16px;
          margin-top: 5px;
          margin-bottom: 10px;
        }
        img {
          width: 114px;
          height: 114px;
          margin: 0 auto;
        }
      }
    }
    .logo {
      width: 390px;
      height: 130px;
      margin-top: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .footer_bottom {
    // height: 200px;
    // width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 50px 0px;
    a:hover {
      color: #2d8cf0;
    }
    ul {
      li {
        text-align: left;
        margin-top: 20px;
      }
      li:nth-of-type(1) {
        margin-top: 0px;
      }
    }
  }
  .footer_copyright {
    width: 100%;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filingLine {
    margin: 0 0 0 12px;
    display: flex;
    align-items: center;
    img {
      width: 18px;
      margin: 0 6px 0 0;
    }
  }
}
</style>
